import {
    Box,
    Button,
    Divider,
    Grid,
    Link,
    Stack,
    styled,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import Visa from "../../../assets/visa.svg";
import Mastercard from "../../../assets/mastercard.svg";
import Amex from "../../../assets/amex.svg";
import Discover from "../../../assets/discover.svg";
import Diners from "../../../assets/diners.svg";
import JCB from "../../../assets/jcb.svg";
import UnionPay from "../../../assets/unionpay.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import AddIcon from "@mui/icons-material/Add";

import LightTooltip from "../../ui/LightTooltip";
import AddPaymentDialog from "../../payments/AddPaymentDialog";
import { useState, useEffect } from "react";
import payService from "../../../services/payment_methods";

/* Estilos */
import BaseSnackbar from "../../ui/BaseSnackbar";
import SimpleBackdrop from "../../ui/SimpleBackdrop";
import { useSelector } from "react-redux";
import ChangePayDialog from "./ChangePayDialog";
import SimpleDialog from "../../ui/SimpleDialog";
import { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import moment from "moment";
import subscriptionService from "../../../services/subscription"

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: 10,
        width: "50%",
    },
}));

const ShoppingSummaryReg = (props) => {
    const {
        setPaymentType,
        paymentType,
        pagesMonthly,
        priceMonthly,
        setPriceMonthly,
        priceMonthlyOG,
        currentPlan,
        payments,
        setReload,
        setActiveStep,
        userEmail,
        showStep3,
        origin,
        allCompanies,
        plansInfo,
        smbInfo,
        finalF
    } = props;

    const [t, i18n] = useTranslation("global");

    const defaultCard = payments.find((item) => item.default_method);

    const todos = useSelector((state) => state.value);
    const [showAddPay, setShowAddPay] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [isLoading, setIsLoading] = useState(false);
    const [showChangePayment, setShowChangePayment] = useState(false);
    const [payStatus, setPayStatus] = useState(false);
    const [termsLink, setTermsLink] = useState(
        sessionStorage.getItem("lng") === "es"
            ? "https://kiiper.app/es/terms-and-conditions"
            : "https://kiiper.app/terms-and-conditions/"
    );
    const [openModal, setOpenModal] = useState(false);
    const [isRetry, setIsRetry] = useState(false);
    const [monthDays, setMonthDays] = useState("month");

    useEffect(() => {
        if (i18n.language) {
            setTermsLink(
                i18n.language === "es"
                    ? "https://kiiper.app/es/terms-and-conditions"
                    : "https://kiiper.app/terms-and-conditions/"
            );
        }
    }, [i18n.language]);

    const handleCloseModalPay = () => {
        setShowChangePayment(false);
    };

    const handlePayment = async () => {
        if (defaultCard) {

            const currentLevel = plansInfo.flatMap((plan) => plan.levels).find((level) => level.pages === pagesMonthly)?.id;

            const params = {
                total_amount: getTotal().toFixed(2).toString().replace(".", "") ?? 0,
                franchise_id: todos.franchiseID,
                company_id: smbInfo._id,
                customer_id: defaultCard.customer_id,
                payment_id: defaultCard.payment_id,
                payment_method_id: defaultCard._id,
                origin: origin,
                planId: currentLevel
            };
      
            setIsLoading(true);

            await payService
                .chargeSavedCard(params)
                .then(() => {
                    setPayStatus(true);
                })
                .catch((err) => {
                    console.log(err.response.data.message);
                    setPayStatus(false);
                    if (openModal) {
                        setIsRetry(true);
                    }
                    // Se envía un correo de pago manual fallido
                    payService.sendEmailFailedPayment(params);
                });
            setOpenModal(true);
            setIsLoading(false);
        }
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
        setShowChangePayment(false);
    };

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            if (defaultCard) {
                setShowChangePayment(true);
            }
            setReload((prevState) => !prevState);
        }
    };

    /** Component functions */
    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const changePayment = () => {
        setShowChangePayment(true);
        setOpenModal(false);
        setIsRetry(false);
    };

    const handleChangeSwitch = async (event) => {
        let paymentType = "monthly";
        let newPrice = priceMonthlyOG;
        if (event.target.checked) {
            paymentType = "annual";
            //newPrice = priceMonthlyOG - priceMonthlyOG * 0.15;
        }
        setPaymentType(paymentType);
        setPriceMonthly(newPrice);
        setMonthDays("month");

        let subsPlan = plansInfo.flatMap((plan) => plan.levels).find((level) => level.pages === pagesMonthly).id
    
        const newVAl = {
            subscription_plan: subsPlan,
            line_type: 1,
            line_amount: paymentType === "annual" ? 12 * priceMonthly : priceMonthly,
            line_unit_amount: priceMonthly,
            line_qty: paymentType === "annual" ? 12 : 1,
            line_since_date: moment().format(),
            line_end_date: moment().add(1, "M").format(),
        };
    
        const params = {
            company_id: todos.companyID,
            cart_lines: [newVAl],
        };
    
        setIsLoading(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);
    };

    const handleCloseModalCredits = () => {
        setIsRetry(false);
        setOpenModal(false);

        if (payStatus) {
            finalF()
            //setActiveStep((prevActiveStep) => prevActiveStep + 1);
            //showStep3();
        }
    };

    const startFreeTrial = () => {
        setOpenModal(false);
        setIsRetry(false);
        showStep3();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const creditsContent = payStatus ? (
        <Stack direction="row" spacing={2} alignItems="center" px={2}>
            <CheckCircleIcon sx={{ fontSize: 70, color: "#00B147" }} />
            <Box>
                <Typography fontSize={20} fontWeight={700} gutterBottom>
                    {payStatus ? t("dialog.successPay") : t("dialog.errorPay")}
                </Typography>
                <Typography fontSize={16}>{t("plans.successText")}</Typography>
            </Box>
        </Stack>
    ) : (
        <Box px={2}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                <WarningIcon sx={{ color: "#ED1A1A" }} />
                <Typography fontSize={20} fontWeight={700}>
                    {t("dialog.errorPay")}
                </Typography>
            </Stack>
            <Typography fontSize={16} gutterBottom py={2}>
                {t("dialog.errorPayText")}
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-around">
                <Button onClick={isRetry ? startFreeTrial : handleCloseModalCredits}>
                    {isRetry ? t("register.startFreeTrial") : t("miscellaneous.goBack")}
                </Button>
                <Button variant="contained" disableElevation onClick={isRetry ? changePayment : handlePayment}>
                    {isRetry ? t("credits.editOrChangePay") : t("payment.retryPay")}
                </Button>
            </Stack>
        </Box>
    );

    const handleAlignment = (event, newValue) => {
        setMonthDays(newValue);
    };

    const getDiscountByPlan = () => {
        return plansInfo
            .find((item) => item.planId === currentPlan.value)
            .discounts.find((item) => item.min >= allCompanies.length && allCompanies.length <= item.max).discount;
    };

    const getTotal = () => {
        if (paymentType === "annual") {
            const totalWithoutDisc = priceMonthlyOG * 12;
            if (allCompanies.length >= 11) {
                return totalWithoutDisc - totalWithoutDisc * 0.15 - totalWithoutDisc * getDiscountByPlan();
            } else {
                return totalWithoutDisc - totalWithoutDisc * 0.15;
            }
        } else {
            const totalWithoutDisc =
                monthDays === "month"
                    ? priceMonthlyOG
                    : (priceMonthlyOG / 30) * moment().endOf("month").diff(moment(), "days");

            if (allCompanies.length >= 11) {
                return totalWithoutDisc - totalWithoutDisc * getDiscountByPlan();
            } else {
                return totalWithoutDisc;
            }
        }
    };

    const confirmCancel = () => {};

    return (
        <>
            {currentPlan.type !== "cancel" ? (
                <Grid component="label" container alignItems="center" spacing={1} px={2} pb={0.5}>
                    <Grid item>
                        <Typography variant="h2">Plan mensual</Typography>
                    </Grid>
                    <Grid item>
                        <Switch
                            id="isAnnual"
                            checked={paymentType === "annual"} // relevant state for your case
                            onChange={handleChangeSwitch}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h2">Plan anual</Typography>
                    </Grid>
                </Grid>
            ) : <Typography variant="h2">{t("subscription.cancelSubs")}</Typography>}
            {origin === "addCompany" && paymentType === "monthly" && (
                <Box sx={{ width: "100%", p: 0, m: 0 }}>
                    <StyledToggleButtonGroup
                        value={monthDays}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="monthDays"
                        sx={{ width: "100%" }}
                    >
                        <ToggleButton
                            value="month"
                            aria-label="month"
                            sx={{
                                width: "50%",
                                backgroundColor: monthDays === "month" ? "#c1b4f0 !important" : "#e5e0f9",
                                "&:hover": {
                                    backgroundColor: monthDays === "month" ? "#af9eef !important" : "lightgray",
                                },
                            }}
                        >
                            Mes completo
                        </ToggleButton>
                        <ToggleButton
                            value="days"
                            aria-label="days"
                            sx={{
                                width: "50%",
                                backgroundColor: monthDays === "days" ? "#c1b4f0 !important" : "#e5e0f9",
                                "&:hover": {
                                    backgroundColor: monthDays === "days" ? "#af9eef !important" : "lightgray",
                                },
                            }}
                        >
                            Días restantes
                        </ToggleButton>
                    </StyledToggleButtonGroup>
                </Box>
            )}
            <Box sx={{ p: 0 }}>
                <>
                    <Grid container justifyContent="space-between" spacing={1} sx={{ p: "0 !important", mt: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="h2">
                                {origin === "addCompany" ? t("subscription.activeSubs") : ""}
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                mb={0.5}
                                justifyContent="space-between"
                                width="100%"
                            >
                                <Typography fontWeight={600} fontSize={12}>
                                    Plan{" "}
                                    {currentPlan.value === 1
                                        ? "Small"
                                        : currentPlan.value === 2
                                        ? "Medium"
                                        : "Enterprise"}{" "}
                                    <Typography component="span" fontWeight={400} fontSize={12}>
                                        - {monthDays === "month" ? pagesMonthly : Math.trunc(pagesMonthly / 30)}{" "}
                                        {monthDays === "month" ? t("register.pagesMonthly") : t("register.pagesPerDay")}
                                    </Typography>
                                </Typography>
                                <Typography fontSize={14}>
                                    US$
                                    {" " +
                                        parseFloat(
                                            monthDays === "month" ? priceMonthlyOG : priceMonthlyOG / 30
                                        ).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                </Typography>
                            </Stack>
                            {paymentType === "annual" && (
                                <>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        mb={0.5}
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Typography fontWeight={600} fontSize={14}>
                                            Cantidad de meses
                                        </Typography>
                                        <Typography fontSize={14}>x12</Typography>
                                    </Stack>
                                </>
                            )}
                            {monthDays === "days" && (
                                <>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        mb={0.5}
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Typography fontWeight={600} fontSize={14}>
                                            Cantidad de días
                                        </Typography>
                                        <Typography fontSize={14}>
                                            x{moment().endOf("month").diff(moment(), "days") + 1}
                                        </Typography>
                                    </Stack>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {paymentType === "annual" && currentPlan.type !== "cancel" && (
                        <>
                            <Divider />
                            <Grid container justifyContent="space-between" spacing={1} sx={{ my: 0.5 }}>
                                <Grid item xs={12} mb={0.5}>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        mb={0.5}
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Typography fontWeight={600} variant="h2">
                                            Subtotal
                                        </Typography>
                                        <Typography fontSize={14}>
                                            US$
                                            {" " +
                                                parseFloat(priceMonthlyOG * 12).toLocaleString(todos.amountFormat, {
                                                    minimumFractionDigits: 2,
                                                })}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container justifyContent="space-between" spacing={1} sx={{ my: 0.5 }}>
                                <Grid item xs={12}>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        mb={0.5}
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Typography fontWeight={600} variant="h2">
                                            Descuento por subcripción anual
                                        </Typography>
                                        <Typography fontSize={14} color="#ff5e77">
                                            - US$
                                            {" " +
                                                parseFloat(priceMonthlyOG * 12 * 0.15).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {origin === "addCompany" && allCompanies.length >= 11 && (
                                    <Grid item xs={12} mb={1}>
                                        <Typography variant="h3" m={0}>
                                            Descuento por empresas con Plan
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="space-between"
                                            width="100%"
                                        >
                                            <Typography fontWeight={600} fontSize={12}>
                                                {allCompanies.length} empresas creadas ({getDiscountByPlan() * 100}
                                                %)
                                            </Typography>
                                            <Typography fontSize={14} color="#ff5e77">
                                                - US$
                                                {" " +
                                                    parseFloat(
                                                        priceMonthlyOG * 12 * getDiscountByPlan()
                                                    ).toLocaleString(todos.amountFormat, {
                                                        minimumFractionDigits: 2,
                                                    })}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                    {paymentType === "monthly" && currentPlan.type !== "cancel" && (
                        <>
                            <Divider />
                            <Grid container justifyContent="space-between" spacing={1} sx={{ my: 0.5 }}>
                                <Grid item xs={12} mb={0.5}>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        mb={0.5}
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Typography fontWeight={600} variant="h2">
                                            Subtotal
                                        </Typography>
                                        <Typography fontSize={14}>
                                            US$
                                            {" " +
                                                parseFloat(
                                                    monthDays === "month"
                                                        ? priceMonthlyOG
                                                        : (priceMonthlyOG / 30) *
                                                              moment().endOf("month").diff(moment(), "days")
                                                ).toLocaleString(todos.amountFormat, {
                                                    minimumFractionDigits: 2,
                                                })}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                            {origin === "addCompany" && allCompanies.length >= 11 && (
                                <>
                                    <Divider />
                                    <Grid container justifyContent="space-between" spacing={1} sx={{ my: 1 }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" m={0}>
                                                Descuento por empresas con Plan
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                alignItems="center"
                                                mb={0.5}
                                                justifyContent="space-between"
                                                width="100%"
                                            >
                                                <Typography fontWeight={600} fontSize={12}>
                                                    {allCompanies.length} empresas creadas ({getDiscountByPlan() * 100}
                                                    %)
                                                </Typography>
                                                <Typography fontSize={14} color="#ff5e77">
                                                    - US$
                                                    {" " +
                                                        parseFloat(
                                                            monthDays === "month"
                                                                ? priceMonthlyOG
                                                                : (priceMonthlyOG / 30) *
                                                                      moment().endOf("month").diff(moment(), "days") *
                                                                      getDiscountByPlan()
                                                        ).toLocaleString(todos.amountFormat, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    <Divider />
                    <Grid container justifyContent="space-between" spacing={1} sx={{ my: 0.5 }}>
                        <Grid item xs={12}>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                            >
                                <Typography variant="h3">Total</Typography>
                                <Typography fontSize={14}>
                                    US${" "}
                                    {parseFloat(getTotal()).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: "0 !important" }}>
                            {currentPlan.type !== "cancel" ? (
                                <Typography fontSize={12}>
                                    {t("subscription.purchaseRec")}{" "}
                                    <Typography color="primary" component="span" fontSize={12} fontWeight={600}>
                                        {paymentType === "annual"
                                            ? pagesMonthly * 12
                                            : monthDays === "month"
                                            ? pagesMonthly
                                            : Math.trunc(pagesMonthly / 30) *
                                                  moment().endOf("month").diff(moment(), "days") +
                                              1}{" "}
                                        {t("subscription.newCred")}
                                    </Typography>
                                </Typography>
                            ) : (
                                <Typography>
                                    A partir del <b>{moment.utc(currentPlan.endDate).format(todos.dateFormat)}</b> tu
                                    suscripción quedará cancelada.
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Divider sx={{ pt: 1 }} />
                    <Grid container justifyContent="center" spacing={2} sx={{ my: 0.1 }}>
                        {currentPlan.type !== "cancel" && (
                            <>
                                {" "}
                                <Grid item xs={12}>
                                    <Typography variant="h1">{t("payment.paymentMethod")}</Typography>
                                </Grid>
                                {priceMonthly > 0 && (
                                    <>
                                        {!defaultCard ? (
                                            <Grid item xs={12}>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    onClick={handleAddPaymentM}
                                                    endIcon={<AddIcon />}
                                                >
                                                    {t("subscription.addPayMethod")}
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid item xs={12}>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Box
                                                            component="img"
                                                            src={
                                                                defaultCard.brand === "visa"
                                                                    ? Visa
                                                                    : defaultCard.brand === "mastercard"
                                                                    ? Mastercard
                                                                    : defaultCard.brand === "amex"
                                                                    ? Amex
                                                                    : defaultCard.brand === "discover"
                                                                    ? Discover
                                                                    : defaultCard.brand === "diners"
                                                                    ? Diners
                                                                    : defaultCard.brand === "unionpay"
                                                                    ? UnionPay
                                                                    : JCB
                                                            }
                                                            sx={{ width: 45 }}
                                                        />
                                                        <Typography variant="h3">
                                                            {defaultCard.brand.charAt(0).toUpperCase() +
                                                                defaultCard.brand.slice(1)}{" "}
                                                            {t("credits.endingIn")} *
                                                            {defaultCard.card_number.split("-").pop()}
                                                        </Typography>
                                                        <Button color="primary" size="small" onClick={changePayment}>
                                                            {t("subscription.editOrAdd")}
                                                        </Button>
                                                    </Stack>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        <Grid item xs={12} sm={4}>
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={!defaultCard}
                                onClick={currentPlan.type === "cancel" ? confirmCancel : handlePayment}
                            >
                                {currentPlan.type === "cancel" ? t("subscription.confirm") : t("credits.pay")}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                <LightTooltip
                                    title={
                                        <>
                                            <Typography fontSize={12}>
                                                <span
                                                    style={{
                                                        color: "#0FF",
                                                    }}
                                                >
                                                    {t("credits.extraCred")}
                                                </span>{" "}
                                                {t("credits.extraCredText")}
                                            </Typography>
                                            <br />
                                            <Typography fontSize={12}>
                                                {t("credits.toolWant")}{" "}
                                                <span
                                                    style={{
                                                        color: "#0FF",
                                                    }}
                                                >
                                                    {t("credits.increasePlan")}
                                                </span>{" "}
                                                {t("credits.increasePlanText")}
                                            </Typography>
                                            <br />
                                            <Typography fontSize={12}>
                                                {t("credits.toolWant")}{" "}
                                                <span
                                                    style={{
                                                        color: "#0FF",
                                                    }}
                                                >
                                                    {t("credits.reducePlan")}
                                                </span>{" "}
                                                {t("credits.reducePlanText")}
                                            </Typography>
                                        </>
                                    }
                                >
                                    <InfoIcon fontSize="small" color="primary" />
                                </LightTooltip>
                                <Typography fontSize={12}>
                                    {t("subscription.termsAccept")}{" "}
                                    <Typography
                                        color="primary"
                                        fontSize={12}
                                        component={Link}
                                        sx={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                        }}
                                        href={termsLink}
                                        target="_blank"
                                    >
                                        {t("subscription.termsAndCon")}
                                    </Typography>
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            </Box>

            <SimpleDialog
                open={openModal}
                handleClose={handleCloseModalCredits}
                maxWidth="sm"
                content={creditsContent}
            />
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setMessage}
                    setAlertType={setAlertType}
                    setIsLoading={setIsLoading}
                    userEmail={userEmail}
                />
            )}
            <BaseSnackbar type={alertType} show={show} message={message} onClose={handleCloseSnack} />
            <SimpleBackdrop open={isLoading} />
            {showChangePayment && (
                <ChangePayDialog
                    open={showChangePayment}
                    handleClose={handleCloseModalPay}
                    payments={payments}
                    setIsLoading={setIsLoading}
                    defaultCard={defaultCard}
                    handleAddPaymentM={handleAddPaymentM}
                />
            )}
        </>
    );
};

export default ShoppingSummaryReg;

import { Grid, Typography, Stack, Box, Button, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardPlan from "./CardPlan";
import HeaderBar from "./HeaderBar";
import classes from "./MainSubscriptions.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AlertDialog from "../ui/AlertDialog";
import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import subscriptionService from "../../services/subscription";
import FixedCardCredit from "./FixedCardCredit";
import InfoIcon from "@mui/icons-material/Info";

import LightTooltip from "../ui/LightTooltip";
import SimpleDialog from "../ui/SimpleDialog";
import CustomPlan from "./CustomPlan";

const SubscriptionTab1 = (props) => {
    const [t] = useTranslation("global");

    const {
        creditsInfo,
        cartItems,
        setCartItems,
        setIsLoading,
        plansInfo,
        currentPlan,
        activePlan,
        setActivePlan,
        plansDraw,
        setPlansDraw,
        setPlanCredits,
        handleOpenCart,
        currentPlanInfo,
        setReload,
        freePlan,
        mainSkeleton,
    } = props;

    const [openAlert, setOpenAlert] = useState(false);
    const [reactivatePlan, setReactivatePlan] = useState({});
    const [openReactiveAlert, setOpenReactiveAlert] = useState(false);
    const [openFormPlan, setOpenFormPlan] = useState(false);
    const todos = useSelector((state) => state.value);

    const handleDrawPlans = async () => {
        if (plansDraw.map((item) => item.planId).includes(1)) {
            setPlansDraw(plansInfo.slice(3, 6));
        } else {
            setPlansDraw(plansInfo.slice(0, 3));
        }
    };

    const handleCloseDialog = () => {
        setOpenAlert(false);
        setOpenReactiveAlert(false);
    };

    const bodyMess = (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">
                    {t("subscription.reactivatePlanChanged")} {t("subscription.reactivatePlanChanged2")}
                </Typography>
            </Grid>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">
                    {t("dialog.warningConfirm2")}
                </Typography>
            </Grid>
        </Grid>
    );

    const handleClickReactivatePlan = async () => {
        setIsLoading(true);

        let lineType = 8;

        const newVAl = {
            planType: reactivatePlan.data.credits,
            planData: reactivatePlan.data,
            subscription_plan: reactivatePlan.planId,
            line_type: 8,
            line_amount: 0.0,
            line_unit_amount: 0.0,
            line_qty: 1,
            line_since_date: moment().format(),
            line_end_date: moment().add(1, "M").format(),
            image: reactivatePlan.image,
        };

        const newArr = cartItems.filter((item) => !item.subscription_plan && item.line_type !== lineType);

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [newVAl, ...newArr],
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setCartItems([newVAl, ...newArr]);
        handleOpenCart();
    };

    // const handleCustomPlan = () => {
    //     setOpenFormPlan(true);
    // };

    const handleCloseCust = () => {
        setOpenFormPlan(false);
    };

    const handleClickReactivateChange = async (data, isOnDemand) => {
        let invoice;
        setIsLoading(true);

        let lineType = 1;

        if (isOnDemand) {
            await subscriptionService
                .getInvoices({ franchise_id: todos.franchiseID })
                .then(async (response) => {
                    await subscriptionService
                        .getInvoiceDetails({ invoice_id: response[0]._id })
                        .then((data) => {
                            invoice = data.line_items[0];
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
            lineType = 5;
        }

        const newVAl = !isOnDemand
            ? {
                planType: data ? data.data.credits : reactivatePlan.data.credits,
                planData: data ? data.data : reactivatePlan.data,
                subscription_plan: data ? data.planId : reactivatePlan.planId,
                line_type: 1,
                line_amount: data ? data.cost : 0.0,
                line_unit_amount: data ? data.customCredits : 0.0,
                line_qty: 1,
                line_since_date: moment().format(),
                line_end_date: moment().add(1, "M").format(),
                image: data ? data.image : reactivatePlan.image,
            }
            : {
                line_type: 5,
                line_amount: invoice.line_amount,
                line_unit_amount: invoice.line_unit_amount,
                line_qty: invoice.line_qty,
                line_since_date: moment().format(),
                line_end_date: moment().add(1, "M").format(),
                image: reactivatePlan.image,
                fixed: false,
            };

        const newArr = cartItems.filter((item) => !item.subscription_plan && item.line_type !== lineType);

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [newVAl, ...newArr],
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setCartItems([newVAl, ...newArr]);
        handleOpenCart();
    };

    const bodyMessReactive = (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">
                    {t("plans.reactivePlan")}
                </Typography>
            </Grid>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">
                    {t("dialog.warningConfirm2")}
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Box paddingX={{ xs: 0, md: 2, lg: 8 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {!mainSkeleton && (
                            <HeaderBar
                                plansInfo={plansInfo}
                                currentPlan={currentPlan}
                                currentPlanInfo={currentPlanInfo}
                                setOpenAlert={setOpenAlert}
                                setReactivatePlan={setReactivatePlan}
                                setOpenReactiveAlert={setOpenReactiveAlert}
                                handleClickReactivateChange={handleClickReactivateChange}
                                freePlan={freePlan}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box sx={{ pb: 1 }}>
                                <Typography fontSize={24} fontWeight={600}>
                                    {t("plans.subsPlans")}
                                </Typography>
                                {/* <Typography fontSize={15} color="primary" sx={{ cursor: "pointer", fontWeight: 600 }} onClick={handleCustomPlan}>
                                    {t("miscellaneous.bestOption")}
                                </Typography> */}
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={handleDrawPlans}
                                    className={classes.whiteButton}
                                    sx={{ width: { xs: "100%", md: "185px" } }}
                                >
                                    {plansDraw.map((item) => item.planId).includes(1) ? (
                                        <>
                                            {t("plans.advancedPlans")} <ArrowForwardIcon fontSize="small" />
                                        </>
                                    ) : (
                                        <>
                                            <ArrowBackIcon fontSize="small" /> {t("plans.basicPlans")}
                                        </>
                                    )}
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                    {mainSkeleton && (
                        <>
                            <Grid item xs={12} sm={6} md={4}>
                                <Skeleton variant="rounded" animation="wave" height={500} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Skeleton variant="rounded" animation="wave" height={500} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Skeleton variant="rounded" animation="wave" height={500} />
                            </Grid>
                        </>
                    )}
                    {!mainSkeleton &&
                        plansDraw.map((item) => (
                            <Grid item xs={12} sm={6} md={4} key={item.planId}>
                                <CardPlan
                                    data={item}
                                    activePlan={activePlan}
                                    cartItems={cartItems}
                                    setCartItems={setCartItems}
                                    setIsLoading={setIsLoading}
                                    currentPlan={currentPlan}
                                    setActivePlan={setActivePlan}
                                    creditsInfo={creditsInfo}
                                    setPlanCredits={setPlanCredits}
                                    handleOpenCart={handleOpenCart}
                                    currentPlanInfo={currentPlanInfo}
                                    setReactivatePlan={setReactivatePlan}
                                    setOpenAlert={setOpenAlert}
                                    setOpenReactiveAlert={setOpenReactiveAlert}
                                    plansInfo={plansInfo}
                                />
                            </Grid>
                        ))}
                    {currentPlanInfo &&
                        (currentPlanInfo.custom_package_1 ||
                            currentPlanInfo.custom_package_2 ||
                            currentPlanInfo.extra_credits) ? (
                        <>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography fontSize={24} fontWeight={600}>
                                        {t("subscription.fixedPack")}
                                    </Typography>
                                    <LightTooltip title={t("subscription.fixedTool")}>
                                        <InfoIcon fontSize="small" color="primary" />
                                    </LightTooltip>
                                </Stack>
                            </Grid>
                            {currentPlanInfo.custom_package_1 && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <FixedCardCredit
                                        currentPlanInfo={currentPlanInfo}
                                        credits={currentPlanInfo.custom_package_1}
                                        creditsInfo={creditsInfo}
                                        setReload={setReload}
                                        setIsLoading={setIsLoading}
                                        plansInfo={plansInfo}
                                    />
                                </Grid>
                            )}
                            {currentPlanInfo.custom_package_2 && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <FixedCardCredit
                                        currentPlanInfo={currentPlanInfo}
                                        credits={currentPlanInfo.custom_package_2}
                                        creditsInfo={creditsInfo}
                                        setReload={setReload}
                                        setIsLoading={setIsLoading}
                                        plansInfo={plansInfo}
                                    />
                                </Grid>
                            )}
                            {currentPlanInfo.extra_credits !== 0 && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <FixedCardCredit
                                        currentPlanInfo={currentPlanInfo}
                                        credits={currentPlanInfo.extra_credits}
                                        creditsInfo={creditsInfo}
                                        setReload={setReload}
                                        setIsLoading={setIsLoading}
                                        plansInfo={plansInfo}
                                    />
                                </Grid>
                            )}
                        </>
                    ) : null}
                </Grid>
                {/* <ConversionPack
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    setIsLoading={setIsLoading}
                    activePlan={activePlan}
                    customCredits={customCredits}
                    setCustomCredits={setCustomCredits}
                    planCredits={planCredits}
                    handleOpenCart={handleOpenCart}
                    currentPlan={currentPlan}
                    plansInfo={plansInfo}
                    mainSkeleton={mainSkeleton}
                /> */}
            </Box>
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMess}
                agreeAction={handleClickReactivatePlan}
                maxWidth="sm"
            />
            <AlertDialog
                open={openReactiveAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"success"}
                title={t("subscription.reactivatePlan")}
                message={bodyMessReactive}
                agreeAction={handleClickReactivatePlan}
                maxWidth="sm"
            />
            <SimpleDialog
                open={openFormPlan}
                handleClose={handleCloseCust}
                maxWidth="md"
                content={
                    <CustomPlan
                        handleClose={handleCloseCust}
                        plansInfo={plansInfo}
                        currentPlan={currentPlan}
                        setCartItems={setCartItems}
                        handleOpenCart={handleOpenCart}
                        setActivePlan={setActivePlan}
                        setPlanCredits={setPlanCredits}
                        creditsInfo={creditsInfo}
                        activePlan={activePlan}
                    />
                }
            />
        </>
    );
};

export default SubscriptionTab1;

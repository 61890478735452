import {
    Badge,
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    ToggleButton,
    Typography,
} from "@mui/material";
import ToggleButtonGroup, {
    toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

import PlansCard from "./PlansCard";
import { useTranslation } from "react-i18next";

import Discount from "../../../assets/15off.svg";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: 10,
    },
    [`& .${toggleButtonGroupClasses.firstButton},& .${toggleButtonGroupClasses.lastButton}`]:
        {
            width: 120,
        },
}));

const PlanSubs = (props) => {
    const {
        setTitleModal,
        setOpenAlert,
        setAgreeBtnMsg,
        setDisagreeBtnMsg,
        setBodyMess,
        setCurrentPlan,
        paymentType,
        setPaymentType,
        setPagesMonthly,
        setPriceMonthly,
        setPriceMonthlyOG,
        origin,
        activePlan,
        currentPlan,
        plansInfo,
        setIsLoading,
        setShowCart,
        companyDetails
    } = props;

    const [t] = useTranslation("global");

    const handleAlignment = (event, newAlignment) => {
        setPaymentType(newAlignment);
    };

    const planFeatures = [
        t("plans.customFormat"),
        t("plans.userProfile"),
        t("plans.unlimitedStorage"),
    ];

    return (
        <>
            <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Typography variant="h1">
                        {origin === "company" || "editCompany" ? "" : t("plans.choosePlanFirst")}
                    </Typography>
                    <Typography>
                        {/* {t("plans.dontKnow")}{" "}
                        <span style={{ color: "#6544DB" }}>
                            {t("plans.answerQu")}
                        </span> */}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography variant="h2">
                            {t("plans.paymentMode")}
                        </Typography>
                        <Paper
                            elevation={0}
                            sx={{
                                display: "flex",
                                border: `1px solid #6544DB`,
                                flexWrap: "wrap",
                                borderRadius: 3,
                            }}
                        >
                            <StyledToggleButtonGroup
                                value={paymentType}
                                exclusive
                                onChange={handleAlignment}
                                aria-label="paymentType"
                            >
                                <ToggleButton
                                    value="monthly"
                                    aria-label="monthly"
                                    sx={{
                                        backgroundColor:
                                            paymentType === "monthly"
                                                ? "#E5E0F9 !important"
                                                : "white",
                                        "&:hover": {
                                            backgroundColor:
                                                paymentType === "monthly"
                                                    ? "#EAE6FA !important"
                                                    : "lightgray",
                                        },
                                    }}
                                >
                                    {t("navBar.monthly")}
                                </ToggleButton>
                                <Badge
                                    badgeContent={
                                        <img src={Discount} alt="Badge" />
                                    }
                                >
                                    <ToggleButton
                                        value="annual"
                                        aria-label="annual"
                                        sx={{
                                            backgroundColor:
                                                paymentType === "annual"
                                                    ? "#E5E0F9 !important"
                                                    : "white",
                                            "&:hover": {
                                                backgroundColor:
                                                    paymentType === "annual"
                                                        ? "#EAE6FA !important"
                                                        : "lightgray",
                                            },
                                        }}
                                    >
                                        {t("navBar.annual")}
                                    </ToggleButton>
                                </Badge>
                            </StyledToggleButtonGroup>
                        </Paper>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md>
                    <Typography variant="h1">
                        {t("plans.allPlansInc")}
                    </Typography>
                    <List>
                        {planFeatures.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemIcon sx={{ minWidth: 35 }}>
                                    <CheckIcon
                                        color="primary"
                                        fontSize="small"
                                    />
                                </ListItemIcon>
                                <ListItemText primary={item} />
                            </ListItem>
                        ))}
                    </List>
                    <Typography my={3} sx={{ textTransform: "capitalize" }}>{t("plans.pageMonth")}</Typography>
                    <Typography variant="h1" gutterBottom>
                        {t("plans.docConv")}
                    </Typography>
                    <Box pl={2}>
                        <Typography gutterBottom>
                            {t("converter.checks")}
                        </Typography>
                        <Typography gutterBottom>
                            {t("plans.factRec")}
                        </Typography>
                        <Typography>{t("converter.bankState")}</Typography>
                    </Box>
                    <Typography variant="h1" gutterBottom mt={1}>
                        {t("plans.customerSupp")}
                    </Typography>
                    <Box pl={2}>
                        <Typography gutterBottom>Onboarding</Typography>
                        <Typography gutterBottom>
                            {t("plans.training")}
                        </Typography>
                        <Typography gutterBottom>
                            {t("plans.channels")}
                        </Typography>
                        <Typography>{t("plans.people")}</Typography>
                    </Box>
                    <Typography variant="h1" gutterBottom mt={1}>
                        {t("plans.addFeatures")}
                    </Typography>
                    <Box pl={2}>
                        <Typography gutterBottom>
                            {t("plans.users1")}
                        </Typography>
                        <Typography gutterBottom>
                            {t("plans.panelMonit")}
                        </Typography>
                        <Typography gutterBottom>
                            {t("plans.productUpd")}
                        </Typography>
                        <Typography>{t("plans.exten")}</Typography>
                    </Box>
                </Grid>
                {plansInfo.map((item) => (
                    <Grid item xs={12} md={2.5} key={item.planId}>
                        <PlansCard
                            plan={item}
                            paymentType={paymentType}
                            setTitleModal={setTitleModal}
                            setOpenAlert={setOpenAlert}
                            setAgreeBtnMsg={setAgreeBtnMsg}
                            setDisagreeBtnMsg={setDisagreeBtnMsg}
                            setBodyMess={setBodyMess}
                            setCurrentPlan={setCurrentPlan}
                            setPagesMonthlyGlobal={setPagesMonthly}
                            setPriceMonthlyGlobal={setPriceMonthly}
                            setPriceMonthlyOG={setPriceMonthlyOG}
                            origin={origin}
                            activePlan={activePlan}
                            currentPlan={currentPlan}
                            setIsLoading={setIsLoading}
                            setShowCart={setShowCart}
                            companyDetails={companyDetails}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default PlanSubs;

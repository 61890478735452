/* Componentes */
import { useDispatch, useSelector } from "react-redux";
import BackGeneral from "../components/layout/BackGeneral";
import MainSubscription from "../components/subscription/MainSubscription";
import { useEffect, useState } from "react";

import Ellipse5 from "../assets/ellipse-card-5.svg";
import Ellipse15 from "../assets/ellipse-card-15.svg";
import Ellipse30 from "../assets/ellipse-card-30.svg";
import Ellipse50 from "../assets/ellipse-card-50.svg";
import Ellipse200 from "../assets/ellipse-card-200.svg";
import Ellipse500 from "../assets/ellipse-card-500.svg";

import subscriptionService from "../services/subscription";

import companyService from "../services/company";
import { useTranslation } from "react-i18next";

import { addCompanyID } from "../redux/userSlice";
import creditsInfo from "../components/Js/creditsInfo";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import { useLocation } from "react-router-dom";
import plansInfoNew from "../components/Js/plans";

const Subscription = () => {
    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");
    const location = useLocation();

    const [cartItems, setCartItems] = useState([]);
    const [currentPlan, setCurrentPlan] = useState(null); //Num. del plan actual
    const [currentPlanInfo, setCurrentPlanInfo] = useState(null); //Información completa del plan actual
    const [activePlan, setActivePlan] = useState(null); //Plan seleccionado
    const [plansDraw, setPlansDraw] = useState([]);
    const [customCredits, setCustomCredits] = useState(0); //Créditos personalizados
    const [planCredits, setPlanCredits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [shoppingCartOpen, setShoppingCartOpen] = useState(false);
    const [payments, setPayments] = useState([]);
    const [mainSkeleton, setMainSkeleton] = useState(true);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [freePlan, setFreePlan] = useState(false); //Información completa del plan actual
    const [franchiseDetails, setFranchiseDetails] = useState({});

    window.history.replaceState(null, "");

    const dispatch = useDispatch();

    const plansInfo = [
        {
            planId: 1,
            planTitle: t("plans.essential"),
            image: Ellipse5,
            cost: 7.75,
            colorTop: "#484949",
            data: {
                credits: 5,
                users: 1,
                roles: true,
                storage: t("plans.unlimited"),
                training: false,
                support: t("plans.standard"),
                integrations: [],
                xeroDashboard: false,
            },
            customCredits: 1.8,
        },
        {
            planId: 2,
            planTitle: t("plans.advanced"),
            image: Ellipse15,
            cost: 19.75,
            colorTop: "#a35eff",
            data: {
                credits: 15,
                users: 2,
                roles: true,
                storage: t("plans.unlimited"),
                training: false,
                support: t("plans.standard"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
            },
            customCredits: 1.7,
        },
        {
            planId: 3,
            planTitle: t("plans.premium"),
            image: Ellipse30,
            cost: 35.75,
            colorTop: "#ffab4c",
            data: {
                credits: 30,
                users: 3,
                roles: true,
                storage: t("plans.unlimited"),
                training: false,
                support: t("plans.standard"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
            },
            customCredits: 1.6,
        },
        {
            planId: 4,
            planTitle: t("plans.professional"),
            image: Ellipse50,
            cost: 55.75,
            colorTop: "#00B147",
            data: {
                credits: 50,
                users: 4,
                roles: true,
                storage: t("plans.unlimited"),
                training: t("plans.standard"),
                support: t("plans.standard"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
            },
            customCredits: 1.5,
        },
        {
            planId: 5,
            planTitle: t("plans.business"),
            image: Ellipse200,
            cost: 189.75,
            colorTop: "#00FFFF",
            data: {
                credits: 200,
                users: 5,
                roles: true,
                storage: t("plans.unlimited"),
                training: t("plans.custom"),
                support: t("plans.priority1"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
            },
            customCredits: 1.4,
        },
        {
            planId: 6,
            planTitle: t("plans.corporate"),
            image: Ellipse500,
            cost: 425.75,
            colorTop: "#FF007A",
            data: {
                credits: 500,
                users: t("plans.unlimited"),
                roles: true,
                storage: t("plans.unlimited"),
                training: t("plans.custom"),
                support: t("plans.priority1"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
            },
            customCredits: 1.3,
        },
    ];

    useEffect(() => {
        (async () => {
            if (
                !location.state ||
                location?.search?.includes("tab=0") ||
                location.state?.tab === 0 ||
                location?.search?.includes("tab=2") ||
                location.state?.tab === 2
            ) {
                if (location.state?.isCart) {
                    setShoppingCartOpen(true);
                }
                if (!todos.companyID && todos.franchiseID) {
                    const newComp = todos.allComData.filter((company) => !company.franchise_name)[0];
                    dispatch(addCompanyID(newComp));
                } else {
                    setIsLoading(true);

                    await companyService
                        .getFranchiseDetails(todos.franchiseID)
                        .then((data) => {
                            setFranchiseDetails(data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    if (todos.companyID && todos.franchiseID) {
                        const params = {
                            franchise_id: todos.franchiseID,
                            company_id: todos.companyID,
                        };
    
                        await subscriptionService
                            .getSubscription(params)
                            .then(async (suscription) => {
                                setCurrentPlan(suscription.subscription_plan);
                                setCurrentPlanInfo(suscription);
                                setActivePlan(suscription.subscription_plan);
                                setFreePlan(suscription.subscription_plan === 0)
                            })
                            .catch((err) => console.log(err));
                    }
                    
                    setIsLoading(false);
                }
            }
            // if (location.state?.reactivate && location.state?.subscription) {
            //     setIsLoading(true);

            //     await companyService
            //         .getFranchiseDetails(todos.franchiseID)
            //         .then((data) => {
            //             setFranchiseDetails(data);
            //         })
            //         .catch((error) => {
            //             console.log(error);
            //         });

            //     const params = {
            //         franchise_id: todos.franchiseID,
            //         company_id: todos.companyID,
            //     };

            //     await subscriptionService
            //         .getSubscription(params)
            //         .then(async (suscription) => {
            //             if (suscription.subscription_plan && suscription.subscription_plan !== 0) {
            //                 setCurrentPlan(suscription.subscription_plan);
            //                 setCurrentPlanInfo(suscription);
            //                 setActivePlan(suscription.subscription_plan);
                            

            //                 if (suscription.subscription_plan === 1) {
            //                     setPlanCredits(creditsInfo.slice(0, 2));
            //                 } else if (suscription.subscription_plan === 2) {
            //                     setPlanCredits(creditsInfo.slice(1, 3));
            //                 } else if (suscription.subscription_plan === 3) {
            //                     setPlanCredits(creditsInfo.slice(2, 4));
            //                 } else if (suscription.subscription_plan === 4) {
            //                     setPlanCredits(creditsInfo.slice(4, 6));
            //                 } else if (suscription.subscription_plan === 5) {
            //                     setPlanCredits(creditsInfo.slice(5, 7));
            //                 } else {
            //                     setPlanCredits(creditsInfo.slice(7, 9));
            //                 }

            //                 let reactivatePlan = plansInfo.find(
            //                     (item) => item.planId === suscription.subscription_plan
            //                 );

            //                 const newVAl =
            //                     location.state.subscription === 6
            //                         ? {
            //                               planType: reactivatePlan.data.credits,
            //                               planData: reactivatePlan.data,
            //                               subscription_plan: reactivatePlan.planId,
            //                               line_type: 1,
            //                               line_amount: reactivatePlan.cost,
            //                               line_unit_amount: reactivatePlan.customCredits,
            //                               line_qty: 1,
            //                               line_since_date: moment().format(),
            //                               line_end_date: moment().add(1, "M").format(),
            //                               image: reactivatePlan.image,
            //                           }
            //                         : {
            //                               planType: reactivatePlan.data.credits,
            //                               planData: reactivatePlan.data,
            //                               subscription_plan: reactivatePlan.planId,
            //                               line_type: 8,
            //                               line_amount: 0.0,
            //                               line_unit_amount: 0.0,
            //                               line_qty: 1,
            //                               line_since_date: moment().format(),
            //                               line_end_date: moment().add(1, "M").format(),
            //                               image: reactivatePlan.image,
            //                           };

            //                 const params = {
            //                     franchise_id: todos.franchiseID,
            //                     company_id: todos.companyID,
            //                     cart_lines: [newVAl],
            //                 };

            //                 await subscriptionService
            //                     .updateCart(params)
            //                     .then()
            //                     .catch((error) => {
            //                         console.log(error);
            //                     });

            //                 if (location.state.subscription === 6) {
            //                     await paymentMethodsService
            //                         .getPaymentMethods({
            //                             franchise_id: todos.franchiseID,
            //                         })
            //                         .then((response) => {
            //                             setPayments(response);
            //                         })
            //                         .catch((error) => {
            //                             console.log(error);
            //                         });
            //                 }

            //                 setCartItems([newVAl]);
            //                 setShoppingCartOpen(true);
            //             }
            //         })
            //         .catch((err) => console.log(err));
            //     setIsLoading(false);
            // }
            setMainSkeleton(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todos.companyID, todos.franchiseID, reload, location.state]);

    return (
        <BackGeneral color="#f8f8f9">
            <MainSubscription
                franchise_id={todos.franchiseID}
                company_id={todos.companyID}
                cartItems={cartItems}
                setCartItems={setCartItems}
                creditsInfo={creditsInfo}
                plansInfo={plansInfo}
                currentPlan={currentPlan}
                activePlan={activePlan}
                setActivePlan={setActivePlan}
                plansDraw={plansDraw}
                setPlansDraw={setPlansDraw}
                customCredits={customCredits}
                setCustomCredits={setCustomCredits}
                planCredits={planCredits}
                setPlanCredits={setPlanCredits}
                setIsLoading={setIsLoading}
                currentPlanInfo={currentPlanInfo}
                setCurrentPlanInfo={setCurrentPlanInfo}
                setReload={setReload}
                shoppingCartOpen={shoppingCartOpen}
                setShoppingCartOpen={setShoppingCartOpen}
                payments={payments}
                setPayments={setPayments}
                showSkeleton={showSkeleton}
                setShowSkeleton={setShowSkeleton}
                freePlan={freePlan}
                mainSkeleton={mainSkeleton}
                franchiseDetails={franchiseDetails}
                plansInfoNew={plansInfoNew}
            />
            <SimpleBackdrop open={isLoading} />
        </BackGeneral>
    );
};

export default Subscription;

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";

import subscriptionService from "../../../services/subscription";

import moment from "moment/moment";

const checkIconDown = (props) => {
    if (props.className.includes("MuiSelect-iconOpen")) {
        return (
            <PlayArrowIcon
                sx={{
                    position: "absolute",
                    transform: "rotate(270deg)",
                    color: "#131F3E",
                    right: ".5rem",
                    cursor: "pointer",
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            />
        );
    }
    return (
        <PlayArrowIcon
            sx={{
                position: "absolute",
                transform: "rotate(90deg)",
                color: "#131F3E",
                right: ".5rem",
                cursor: "pointer",
                zIndex: 0,
                pointerEvents: "none",
            }}
        />
    );
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const PlansCard = (props) => {
    const {
        plan,
        paymentType,
        setTitleModal,
        setOpenAlert,
        setAgreeBtnMsg,
        setDisagreeBtnMsg,
        setBodyMess,
        setCurrentPlan,
        setPagesMonthlyGlobal,
        setPriceMonthlyGlobal,
        setPriceMonthlyOG,
        origin,
        activePlan,
        setIsLoading,
        setShowCart,
        companyDetails
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const [pagesMonthly, setPagesMonthly] = useState(
        plan.planTitle === "Small" ? 200 : plan.planTitle === "Medium" ? 1000 : 5000
    );
    const [priceMonthly, setPriceMonthly] = useState(
        plan.planTitle === "Small" ? 49 : plan.planTitle === "Medium" ? 199 : 749
    );

    useEffect(() => {
        const monthPrice = plan.levels.find((item) => item.pages === pagesMonthly)?.price;
        if (paymentType === "annual") {
            setPriceMonthly(monthPrice - monthPrice * 0.15);
        } else {
            setPriceMonthly(monthPrice);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentType]);

    const handleChange = (event) => {
        const currentLevel = plan.levels.find((item) => item.pages === event.target.value);
        setPagesMonthly(currentLevel.pages);
        setPriceMonthly(paymentType === "annual" ? currentLevel.price - currentLevel.price * 0.15 : currentLevel.price);
    };

    const showModal = (selectedPlan) => {
        if (selectedPlan === 0) {
            setCurrentPlan({
                value: selectedPlan,
                custom: false,
            });
            setTitleModal(t("register.microCond"));
            setBodyMess(
                <>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ mt: 1, textAlign: "justify", mb: 2 }}
                    >
                        <Typography
                            fontStyle="normal"
                            fontSize={14}
                            fontWeight={300}
                            sx={{
                                color: "#131F3E",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: t("register.selectedMicro"),
                            }}
                        ></Typography>
                    </Stack>
                    <Typography
                        fontStyle="normal"
                        fontSize={14}
                        fontWeight={300}
                        sx={{
                            color: "#131F3E",
                            textAlign: "center",
                            mb: 2,
                            fontWeight: 600,
                        }}
                    >
                        {t("dialog.warningConfirm2")}
                    </Typography>
                </>
            );
            setAgreeBtnMsg(t("dialog.continue"));
            setDisagreeBtnMsg(t("register.back"));
            setOpenAlert(true);
        } else if (selectedPlan === 1 || selectedPlan === 2 || selectedPlan === 3) {
            if (selectedPlan === 3 && pagesMonthly === Infinity) {
                setCurrentPlan({
                    value: selectedPlan,
                    custom: true,
                });
                setTitleModal(t("register.customCond"));
                setAgreeBtnMsg(t("converter.send"));
                setDisagreeBtnMsg(t("register.back"));
                setOpenAlert(true);
            } else {
                const monthPrice = plan.levels.find((item) => item.pages === pagesMonthly)?.price;
                setPagesMonthlyGlobal(pagesMonthly);
                setPriceMonthlyGlobal(priceMonthly);
                setPriceMonthlyOG(monthPrice);
                setCurrentPlan({
                    value: selectedPlan,
                    custom: false,
                });
                setTitleModal(t("register.beforePlan"));
                setBodyMess(
                    <>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            justifyContent="space-between"
                            spacing={2}
                            sx={{ mt: 1, textAlign: "justify", mb: 2 }}
                        >
                            <Typography
                                fontStyle="normal"
                                fontSize={14}
                                fontWeight={300}
                                sx={{
                                    color: "#131F3E",
                                }}
                            >
                                {t("register.selectedMedium1")}{" "}
                                <Typography component="span" fontWeight={600}>
                                    Plan {plan.planTitle} - {pagesMonthly} {t("register.pagesMonthly")}
                                </Typography>{" "}
                                <Typography
                                    component="span"
                                    dangerouslySetInnerHTML={{
                                        __html: t("register.selectedMedium2"),
                                    }}
                                ></Typography>
                            </Typography>
                        </Stack>
                    </>
                );
                setAgreeBtnMsg(t("register.actNow"));
                setDisagreeBtnMsg(t("register.startFreeTrial"));
            }
        }
        setOpenAlert(true);
    };

    const cancelPlan = async (planID) => {
        const newVAl = {
            subscription_plan: planID,
            line_type: 7,
            line_amount: 0.0,
            line_unit_amount: 0.0,
            line_qty: 1,
            line_since_date: moment().format(),
            line_end_date: moment().add(1, "M").format(),
        };

        const params = {
            company_id: todos.companyID,
            cart_lines: [newVAl],
        };

        setCurrentPlan({
            value: planID,
            custom: planID === 12,
            type: "cancel",
            endDate: companyDetails.subscription.end_date,
        });

        const pages = plan.levels.find((level) => level.id === planID)?.pages;

        setPriceMonthlyOG(0);
        setPagesMonthlyGlobal(pages);

        setIsLoading(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setShowCart(true);
    };

    const checkCurrentPlan = () => origin === "editCompany" && activePlan === plan.levels.find((item) => item.pages === pagesMonthly)?.id;

    return (
        <Card sx={{ boxShadow: "4px 4px 15px 0px #4A22D41A", borderRadius: 4 }}>
            <CardContent>
                <Stack alignItems="center">
                    <Box
                        sx={{
                            backgroundColor: "#6544DB",
                            textAlign: "center",
                            p: 2,
                            borderRadius: 4,
                            width: 200,
                        }}
                    >
                        <Typography variant="h1" sx={{ color: "white" }}>
                            {plan.planTitle}
                        </Typography>
                    </Box>

                    <Typography color="primary" mt={1} gutterBottom>
                        {t("plans.bestBusiness")} {t(plan.subtitle)}
                    </Typography>
                    <Typography variant="h1">
                        {plan.levels[0].price === 0
                            ? t("plans.free")
                            : pagesMonthly !== Infinity
                            ? `US$ ${parseFloat(priceMonthly).toLocaleString(todos.amountFormat, {
                                  minimumFractionDigits: 2,
                              })}/${t("converter.month")}`
                            : t("plans.custom")}
                    </Typography>
                </Stack>
                <Box pl={{ lg: 4, xs: 0 }} pt={3} sx={{ textAlign: { xs: "center", md: "left" } }}>
                    {plan.planTitle === "Micro" ? (
                        <Typography mt={1} pb={2}>
                            20 {t("plans.pageMonth")}
                        </Typography>
                    ) : (
                        <FormControl variant="filled" sx={{ width: 200 }}>
                            <InputLabel
                                id="simple-select-label-all"
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    color: "#131F3E",
                                }}
                            >
                                {t("plans.chooseLevel")}
                            </InputLabel>
                            <Select
                                labelId="simple-select-label-all"
                                name="price"
                                value={pagesMonthly}
                                onChange={handleChange}
                                IconComponent={(props) => checkIconDown(props)}
                                size="small"
                                MenuProps={MenuProps}
                            >
                                {plan.levels.map((item, index) => (
                                    <MenuItem value={item.pages} key={index}>
                                        {item.id !== 12
                                            ? `${parseFloat(item.pages).toLocaleString(todos.amountFormat)} ${t(
                                                  "plans.pageMonth"
                                              )}`
                                            : t("plans.custom")}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <Typography mt={3.5} gutterBottom>
                        1 {t("plans.pageForEach")}
                    </Typography>
                    <Typography gutterBottom>{t("plans.pagesPerDoc")}</Typography>
                    <Typography mb={3}>{t("plans.pagesPerState")}</Typography>

                    <Typography mt={4.5} gutterBottom>
                        {t(plan.planType)}
                    </Typography>
                    <Typography gutterBottom>{t(plan.item1)}</Typography>
                    <Typography gutterBottom>{t(plan.commType)}</Typography>
                    <Typography mb={3}>{t(plan.item2)}</Typography>
                    <Typography mt={4.5} gutterBottom>
                        {plan.planId === 0 ? `2 ${t("plans.users")}` : t("plans.unlimitedUpp")}
                    </Typography>
                    <Typography gutterBottom>{t(plan.item3)}</Typography>
                    <Typography gutterBottom>{t(plan.item4)}</Typography>
                    <Typography>
                        {plan.extension} {t("miscellaneous.days")}
                    </Typography>
                </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
                <Stack direction="column">
                    <Button
                        variant="contained"
                        disableElevation
                        endIcon={checkCurrentPlan() ? <CheckIcon /> : null}
                        sx={{
                            width: 200,
                            mb: checkCurrentPlan() ? 1 : 5.5,
                            pointerEvents: checkCurrentPlan() ? "none" : "auto",
                            backgroundColor: checkCurrentPlan() ? "#FFF" : "#4A22D4",
                            color: checkCurrentPlan() ? "#4A22D4 !important" : "#FFF",
                            fontWeight: checkCurrentPlan() ? "600 !important" : "300",
                            border: "1px solid #4A22D4",
                            "&:hover": {
                                backgroundColor: checkCurrentPlan() ? "#FFF" : "#331794",
                                color: checkCurrentPlan() ? "#4A22D4 !important" : "#FFF",
                                fontWeight: checkCurrentPlan() ? "600 !important" : "300",
                                border: checkCurrentPlan() ? "1px solid #4A22D4" : "1px solid #331794",
                            },
                        }}
                        onClick={() => showModal(plan.planId)}
                    >
                        {checkCurrentPlan() ? t("team.active") : t("plans.startNow")}
                    </Button>
                    {checkCurrentPlan() && (
                        <Button color="error" onClick={() => cancelPlan(plan.planId)}>
                            {t("team.cancel")}
                        </Button>
                    )}
                </Stack>
            </CardActions>
        </Card>
    );
};

export default PlansCard;

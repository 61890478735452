/** React imports */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Box,
    Button,
    Checkbox,
    FilledInput,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    styled,
} from "@mui/material";

/** Js imports **/
import companyService from "../../../services/company";
import authService from "../../../services/auth";
import CustomFilledSelectCountries from "../../ui/CustomFilledSelectCountries";
import CustomFilledSelect from "../../ui/CustomFilledSelect";
import businessActivity from "../../Js/businessActivity";
import timezones from "../../Js/timezones";
import countriesProject from "../../Js/countries";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Flag from "react-world-flags";

import accountsService from "../../../services/accounts";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const UpdateCompany = (props) => {
    const { companyDetails, setTexto, setAlertType, setShow, setIsLoading } =
        props;

    const [isErrorName, setIsErrorName] = useState(false);
    const [isError, setIsError] = useState(false);
    const [existingEmail, setExistingEmail] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [currencyNew, setCurrencyNew] = useState([]);
    /** Countries */
    const allCountries = [...countriesProject];

    allCountries.sort((a, b) => {
        if (sessionStorage.getItem("lng") === "en") {
            return a.name_en.localeCompare(b.name_en);
        } else {
            return a.name_es.localeCompare(b.name_es);
        }
    });

    const [updatedCompanyDetails, setUpdatedCompanyDetails] = useState({
        companyName: companyDetails.company_name ?? "",
        country: companyDetails.country_name ?? "",
        ecActivity: companyDetails.business_activity ?? "",
        companySize: companyDetails.team_size ?? "",
        systems: companyDetails.file_formats ?? [],
        email: companyDetails.email ?? "",
        currency: companyDetails.currency ?? "",
        downloadFormat: companyDetails.download_format ?? "",
        website: companyDetails.website ?? "",
        tz: companyDetails.tz ?? "",
        monthlyPages: companyDetails.monthly_pages ?? "",
    });

    const [t] = useTranslation("global");

    const handleChange = (event) => {
        setUpdatedCompanyDetails({
            ...updatedCompanyDetails,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCompanyName = async (event) => {
        setUpdatedCompanyDetails({
            ...updatedCompanyDetails,
            [event.target.name]: event.target.value,
        });

        const params = {
            franchise_id: companyDetails.franchise_id,
            company_name: event.target.value,
            company_id: companyDetails._id,
        };

        await companyService
            .validateCompanyName(params)
            .then((data) => {
                if (data === true) {
                    setIsErrorName(true);
                } else {
                    setIsErrorName(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });
    };

    let bussinessActFinal = [];

    for (let i = 0; i < businessActivity.length; i++) {
        bussinessActFinal.push({
            id: businessActivity[i].id,
            name: t("dialog." + businessActivity[i].translation),
        });
    }

    const sizeList = [
        { id: 1, name: "1-10 " + t("dialog.collab") },
        { id: 2, name: "11-30 " + t("dialog.collab") },
        { id: 3, name: "31-50 " + t("dialog.collab") },
        { id: 4, name: "+50 " + t("dialog.collab") },
        { id: 5, name: t("dialog.iDontKnow") },
    ];

    const systemsList = [
        "Xero",
        "QuickBooks desktop",
        "QuickBooks online",
        "Otro",
    ];

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
            },
            sx: {
                borderRadius: 2,
                mt: 0.7,
            },
        },
        variant: "menu",
        getcontentanchorel: null,
    };

    const handleChangeCurrentEmail = async (event) => {
        setUpdatedCompanyDetails({
            ...updatedCompanyDetails,
            email: event.target.value,
        });
        verifyEmail(event.target.value);
    };

    const verifyEmail = async (email) => {
        if (email.trim() && /.+@.+\..+/.test(email)) {
            await authService
                .verifyExistingUser(email)
                .then((response) => {
                    if (
                        response.status === 200 &&
                        email !== companyDetails.email
                    ) {
                        setAlertType("error");
                        setExistingEmail(true);
                        setErrorEmail(true);
                        setShow(true);
                        setTexto(t("register.existingEmail"));
                    } else {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                });
        } else {
            setErrorEmail(true);
        }
    };

    const handleChangePhoneCode = (event) => {
        let codeFilter = allCountries.find(
            (country) => country.country_code === event.target.value
        );
        if (codeFilter) {
            setCountryCode(event.target.value);
            setPhoneCode(codeFilter.phone_code);
        }
    };

    const handleChangePhoneNumber = (event) => {
        if (!isNaN(event.target.value)) {
            setPhoneNumber(event.target.value);
        }
    };

    useEffect(() => {
        (async () => {
            await accountsService
                .getCurrencies()
                .then((data) => setCurrencyNew(data))
                .catch((error) => {
                    console.log(error);
                });
        })();
    }, []);

    const conversionFormats = [
        {
            id: 13,
            name: "Xero - DD/MM/YYYY",
        },
        {
            id: 14,
            name: "Xero - MM/DD/YYYY",
        },
        // {
        // 	id: 6,
        //     name: "Quickbooks Desktop"
        // },
        {
            id: 7,
            name: "Quickbooks",
        },
        {
            id: 3,
            name: "CSV",
        },
        {
            id: 4,
            name: "Excel",
        },
        {
            id: 1,
            name: "Accounts IQ",
        },
        {
            id: 2,
            name: "AccountsPrep",
        },
        {
            id: 5,
            name: "Iris Accounts Production",
        },
        {
            id: 8,
            name: "ReckonOne",
        },
        {
            id: 9,
            name: "Sage 50",
        },
        {
            id: 10,
            name: "Sage Accounting",
        },
        // {
        // 	id: 11,
        //     name: "Sage Accounting - US date"
        // },
        {
            id: 12,
            name: "Twinfield",
        },
    ];

    const conversionList = [
        { id: 1, name: "1-5 " + t("register.conversions") },
        { id: 2, name: "6-15 " + t("register.conversions") },
        { id: 3, name: "16-30 " + t("register.conversions") },
        { id: 4, name: "31-50 " + t("register.conversions") },
        { id: 5, name: "51-200 " + t("register.conversions") },
        { id: 6, name: "+200 " + t("register.conversions") },
    ];

    const updateCompany = async () => {
        setIsLoading(true);
        if (
            updatedCompanyDetails.companyName.trim() &&
            updatedCompanyDetails.country &&
            updatedCompanyDetails.systems.length > 0 &&
            updatedCompanyDetails.ecActivity
        ) {
            // Update
            const params = {
                company_id: companyDetails._id,
                company_name: updatedCompanyDetails.companyName,
                country_name: updatedCompanyDetails.country,
                business_activity: updatedCompanyDetails.ecActivity,
                currency:
                    updatedCompanyDetails.currency !== ""
                        ? updatedCompanyDetails.currency
                        : undefined,
                tz:
                    updatedCompanyDetails.tz !== ""
                        ? updatedCompanyDetails.tz
                        : undefined,
                companySize:
                    updatedCompanyDetails.tz !== ""
                        ? updatedCompanyDetails.companySize
                        : undefined,
                file_formats: updatedCompanyDetails.systems,
                email:
                    updatedCompanyDetails.email !== ""
                        ? updatedCompanyDetails.email
                        : undefined,
                download_format:
                    updatedCompanyDetails.downloadFormat !== ""
                        ? updatedCompanyDetails.downloadFormat
                        : undefined,
                website:
                    updatedCompanyDetails.website !== ""
                        ? updatedCompanyDetails.website
                        : undefined,
                monthly_pages:
                    updatedCompanyDetails.monthlyPages !== ""
                        ? updatedCompanyDetails.monthlyPages
                        : undefined,
            };

            await companyService
                .updateCompany(params)
                .then(() => {
                    setTexto(t("inbox.successEdit"));
                    setAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    console.log(err);
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                });
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    };

    return (
        <>
            <Box
                sx={{
                    borderRadius: "24px",
                    px: { xs: 3, md: 10 },
                    py: { xs: 3, md: 4 },
                    minWidth: "980px",
                    backgroundColor: "#FFF",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1">
                            {t("dialog.companyData")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={t("dialog.companyName")}
                            name="companyName"
                            required
                            onChange={handleChangeCompanyName}
                            value={updatedCompanyDetails.companyName}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            error={
                                (isError &&
                                    updatedCompanyDetails.companyName.trim() ===
                                        "") ||
                                isErrorName
                            }
                            helperText={
                                isErrorName && t("dialog.alreadyExists")
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelectCountries
                            required={true}
                            error={isError && !updatedCompanyDetails.country}
                            label={t("register.country")}
                            value={updatedCompanyDetails.country}
                            onChange={handleChange}
                            name="country"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelect
                            id="currency"
                            label={t("dashboard.currency")}
                            value={
                                currencyNew.length > 0
                                    ? updatedCompanyDetails.currency ?? ""
                                    : ""
                            }
                            values={currencyNew}
                            onChange={handleChange}
                            name="currency"
                            error={isError && !updatedCompanyDetails.currency}
                            idName="_id"
                            valueName={
                                sessionStorage.getItem("lng") === "en"
                                    ? "currency_name_enn"
                                    : "currency_name"
                            }
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelect
                            error={isError && !updatedCompanyDetails.tz}
                            required
                            label={t("dialog.tz")}
                            value={updatedCompanyDetails.tz}
                            onChange={handleChange}
                            name="tz"
                            values={timezones}
                            idName="value"
                            valueName="text"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelect
                            error={isError && !updatedCompanyDetails.ecActivity}
                            required
                            label={t("dialog.ecActivity")}
                            value={updatedCompanyDetails.ecActivity}
                            onChange={handleChange}
                            name="ecActivity"
                            values={bussinessActFinal}
                            idName="id"
                            valueName="name"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelect
                            error={
                                isError && !updatedCompanyDetails.downloadFormat
                            }
                            required
                            label={t("dialog.favDownloadFormat")}
                            value={updatedCompanyDetails.downloadFormat}
                            onChange={handleChange}
                            name="downloadFormat"
                            values={conversionFormats}
                            idName="id"
                            valueName="name"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelect
                            error={
                                isError && !updatedCompanyDetails.monthlyPages
                            }
                            required={true}
                            name="monthlyPages"
                            label={t("dialog.monthlyPages")}
                            values={conversionList}
                            idName="id"
                            valueName="name"
                            value={updatedCompanyDetails.monthlyPages}
                            onChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelect
                            error={
                                isError && !updatedCompanyDetails.companySize
                            }
                            required
                            label={t("dialog.companySize")}
                            value={updatedCompanyDetails.companySize}
                            onChange={handleChange}
                            name="companySize"
                            values={sizeList}
                            idName="id"
                            valueName="name"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={"Website"}
                            name="website"
                            required
                            onChange={handleChange}
                            value={updatedCompanyDetails.website}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            error={
                                isError &&
                                updatedCompanyDetails.website.trim() === ""
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={t("register.email")}
                            name="email"
                            onChange={(event) =>
                                handleChangeCurrentEmail(event)
                            }
                            error={
                                ((isError || errorEmail) &&
                                    !/.+@.+\..+/.test(
                                        updatedCompanyDetails.email
                                    )) ||
                                existingEmail
                            }
                            value={updatedCompanyDetails.email}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                    error={
                                        isError &&
                                        (phoneCode === "" || !phoneCode)
                                            ? true
                                            : false
                                    }
                                    required
                                >
                                    <InputLabel
                                        id="type-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("register.code")}
                                    </InputLabel>
                                    <Select
                                        IconComponent={(props) => {
                                            if (
                                                props.className.includes(
                                                    "MuiSelect-iconOpen"
                                                )
                                            ) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position:
                                                                "absolute",
                                                            transform:
                                                                "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents:
                                                                "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform:
                                                            "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        name="phoneCode"
                                        value={countryCode ?? ""}
                                        onChange={(event) =>
                                            handleChangePhoneCode(event)
                                        }
                                        fullWidth
                                        input={
                                            <FilledInput
                                                label={t("register.code")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow:
                                                        "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    maxHeight: "300px",
                                                },
                                            },
                                        }}
                                        renderValue={() => (
                                            <Stack
                                                direction="row"
                                                spacing={0.5}
                                                alignItems="center"
                                            >
                                                <Flag
                                                    code={countryCode}
                                                    height="12"
                                                    width="20"
                                                />
                                                <Typography>
                                                    {phoneCode}
                                                </Typography>
                                            </Stack>
                                        )}
                                    >
                                        {allCountries.map((option) => (
                                            <MenuItem
                                                value={option.country_code}
                                                key={option.country_code}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    <Flag
                                                        code={
                                                            option.country_code
                                                        }
                                                        height="14"
                                                        width="22"
                                                    />
                                                    <Typography>
                                                        {sessionStorage.getItem(
                                                            "lng"
                                                        ) === "en"
                                                            ? option.name_en
                                                            : option.name_es}
                                                        {" ("}
                                                        {option.phone_code +
                                                            ")"}
                                                    </Typography>
                                                </Stack>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <CustomTextField
                                    required
                                    fullWidth
                                    label={t("register.phone")}
                                    name="phoneNumber"
                                    error={
                                        isError &&
                                        (phoneNumber === "" || !phoneNumber)
                                            ? true
                                            : false
                                    }
                                    onChange={(event) =>
                                        handleChangePhoneNumber(event)
                                    }
                                    value={phoneNumber}
                                    variant="filled"
                                    inputProps={{
                                        fontStyle: "normal",
                                    }}
                                    type="text"
                                    autoComplete="one-time-code"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl
                            variant="filled"
                            fullWidth
                            required
                            error={
                                isError && !updatedCompanyDetails.systems.length
                            }
                        >
                            <InputLabel
                                id="demo-multiple-checkbox-label"
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    color: "#131F3E",
                                }}
                            >
                                {t("register.tools")}
                            </InputLabel>
                            <Select
                                IconComponent={(props) => {
                                    if (
                                        props.className.includes(
                                            "MuiSelect-iconOpen"
                                        )
                                    ) {
                                        return (
                                            <PlayArrowIcon
                                                sx={{
                                                    position: "absolute",
                                                    transform: "rotate(270deg)",
                                                    color: "#131F3E",
                                                    right: ".5rem",
                                                    cursor: "pointer",
                                                    zIndex: 0,
                                                    pointerEvents: "none",
                                                }}
                                            />
                                        );
                                    }
                                    return (
                                        <PlayArrowIcon
                                            sx={{
                                                position: "absolute",
                                                transform: "rotate(90deg)",
                                                color: "#131F3E",
                                                right: ".5rem",
                                                cursor: "pointer",
                                                zIndex: 0,
                                                pointerEvents: "none",
                                            }}
                                        />
                                    );
                                }}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                name="systems"
                                value={updatedCompanyDetails.systems}
                                onChange={handleChange}
                                input={
                                    <FilledInput label={t("register.tools")} />
                                }
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                            >
                                {systemsList.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        <Checkbox
                                            checked={
                                                updatedCompanyDetails.systems.indexOf(
                                                    item
                                                ) > -1
                                            }
                                        />
                                        <ListItemText primary={item} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "end" }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={updateCompany}
                            size="large"
                            sx={{
                                marginLeft: "auto !important",
                            }}
                        >
                            {t("team.save")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default UpdateCompany;

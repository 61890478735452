import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import introJs from "intro.js";

/* Componentes */
import BackGeneral from "../components/layout/BackGeneral";
import MainConverter from "../components/converter/bankstatements/MainConverter";
import ConverterTable from "../components/converter/bankstatements/ConverterTable";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import Loading from "../assets/loading.png";

import accountsService from "../services/accounts";
import conversionsService from "../services/conversion";
import userFormatService from "../services/user_format";
import BaseSnackbar from "../components/ui/BaseSnackbar";
import { Tabs, Tab, Typography, Stack, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ConversionPreview from "../components/converter/bankstatements/ConversionPreview";
import ChecksPreview from "../components/converter/checks/ChecksPreview";

import qboBanks from "../components/Js/quickbooksBIDs";
import conversionFormats from "../components/Js/conversionFormats";
import { addTutorial } from "../redux/userSlice";
import conversionService from "../services/conversion";
import checksService from "../services/checks";
import caseService from "../services/case";
import { initiateSocket, newUserResponse, subscribeToChat } from "../services/Socket";
import ChatModal from "../components/ui/Chat";
import FormDialog from "../components/ui/FormDialog";
import DownloadFormatContent from "../components/converter/DownloadFormatContent";
import DownloadFormatButtons from "../components/converter/DownloadFormatButtons";
import GroupedContent from "../components/converter/GroupedContent";
import GroupedButtons from "../components/converter/GroupedButtons";

import InfoIcon from "@mui/icons-material/Info";
import ChecksConverter from "../components/converter/checks/ChecksConverter";
import ChecksTable from "../components/converter/checks/ChecksTable";
import SimpleDialog from "../components/ui/SimpleDialog";
import BasePreviewFile from "../components/converter/BasePreviewFile";
import BaseActionsPreviewFile from "../components/converter/BaseActionsPreviewFile";

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
            since: "",
            until: "",
        },
    ],
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 300,
    mr: 3,
    backgroundColor: "#f6f4fd",
    borderRadius: 3,
    minHeight: 35,
    height: 35,
    color: "#131F3E !important",
    "&.Mui-selected": {
        backgroundColor: "#c9bdf2",
        fontWeight: 600,
        borderRadius: 3,
    },
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

const Converter = () => {
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const finalQBOBanks = qboBanks.concat({
        value: "03000",
        value2: "03000",
        value3: "03000",
        value4: "03000",
        name: `${t("converter.defaultBank")} (03000)`,
    });

    const [openPreview, setOpenPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [conversions, setConversions] = useState([]);
    const [checkConversions, setCheckConversions] = useState([]);
    const [groupConversions, setGroupConversions] = useState([]);
    const [groupCheckConversions, setGroupCheckConversions] = useState([]);
    const [usersFilters, setUsersFilters] = useState([]);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [account, setAccount] = useState("");
    const [firstAccount, setFirstAccount] = useState("");
    const [filters, setFilters] = useState(defaultFilter);
    const [userFormatData, setUserFormatData] = useState({});
    const [tutorial, setTutorial] = useState(false);
    const [openModalDownloadF, setOpenModalDownloadF] = useState(false);
    const [accountType, setAccountType] = useState("");
    const [accountNum, setAccountNum] = useState("");
    const [bankInst, setBankInst] = useState(null);
    const [convertDoneNew, setConvertDoneNew] = useState(false);
    const [isLargeConv, setIsLargeConv] = useState(false);
    const [correlative, setCorrelative] = useState(null);
    const [conversionId, setConversionId] = useState(null);
    const [fileNameSaved, setFileNameSaved] = useState("");
    const [fileNameFull, setFileNameFull] = useState("");
    const [showPrev, setShowPrev] = useState(false);
    const [showModalCredits, setShowModalCredits] = useState(false);
    const [resetConversionMain, setResetConversionMain] = useState(false);
    const [accountDetConv, setAccountDetConv] = useState({});
    const [caseInfo, setCaseInfo] = useState({});
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [fileData, setFileData] = useState(null);
    const [__html, setHTML] = useState("");
    const [conversionData, setConversionData] = useState({});
    const [checkedInvSigngs, setCheckedInvSigngs] = useState(false);
    const [checkedInvDay, setCheckedInvDay] = useState(false);
    const [checkedDesRef, setCheckedDesRef] = useState(false);
    const [endPoint, setEndPoint] = useState("");
    const [accountDet, setAccountDet] = useState({});
    const [download, setDownload] = useState(false);
    const [convertDone, setConvertDone] = useState(false);
    const [convertInvalid, setConvertInvalid] = useState(false);
    const [convertRev, setConvertRev] = useState(false);
    const [status, setStatus] = useState(0); //0: Sin iniciar, 1: Error, 2: Revision, 3: Exitoso
    const [statusCase, setStatusCase] = useState();
    const [convFormat, setConvFormat] = useState(conversionFormats);
    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const [disabledInput, setDisabledInput] = useState(false);
    const [formats, setFormats] = useState([]);
    const [groupedData, setGroupedData] = useState(null);
    const [groupedPeriods, setGroupedPeriods] = useState(null);
    const [openModalGrouped, setOpenModalGrouped] = useState(false);
    const [s3FileName, setS3FileName] = useState([]);
    const [originalFileName, setOriginalFileName] = useState([]);
    const [reloadConv, setReloadConv] = useState(false);
    const [progress, setProgress] = useState(0);
    const [endConversion, setEndConversion] = useState(false);
    const [openModalGroupedHist, setOpenModalGroupedHist] = useState(false);
    const [rows, setRows] = useState([]);
    const [value, setValue] = useState(0);
    const [valueMain, setValueMain] = useState(0);
    const [customCredits, setCustomCredits] = useState(0); // Créditos personalizados
    const [downloadFormat, setDownloadFormat] = useState("");
    const [qbFormat, setQbFormat] = useState(false);
    const [showPrevCheck, setShowPrevCheck] = useState(false);
    const [convInfo, setConvInfo] = useState({});
    const [modalType, setModalType] = useState(""); //bankStatement o check
    const [showSync, setShowSync] = useState(false);
    const [integration, setIntegration] = useState(null);
    const [showMatchBankModal, setShowMatchBankModal] = useState(false);
    const [matchBank, setMatchBank] = useState("");
    const [rowsModal, setRowsModal] = useState([]);
    const [basePreviewData, setBasePreviewData] = useState({
        conversion_id: "",
        type: "",
        file_s3: "",
        file: "",
    });
    const [repeatedConv, setRepeatedConv] = useState([]);

    const controllerRefAccount = useRef(null);
    const controllerRefCompany = useRef(null);
    const controllerRefConvUsers = useRef(null);
    const controllerRefFormatUser = useRef(null);
    const [showRepeatModal, setShowRepeatModal] = useState(false);
    const [showRepeatVal, setShowRepeatVal] = useState([]);
    const [showSyncDetails, setShowSyncDetails] = useState(false);
    const [openHeavy, setOpenHeavy] = useState(false);

    window.history.replaceState(null, "");

    const columns1 = [
        { name: "number", title: t("subscription.conversion") },
        { name: "account", title: t("converter.account") },
        { name: "uploadDate", title: t("converter.uploadDate") },
        { name: "check", title: t("converter.check") },
        { name: "contact", title: t("converter.contact") },
        { name: "concept", title: t("payment.concept") },
        { name: "startDate", title: t("payment.date") },
        { name: "balance", title: t("credits.amount") },
        { name: "user", title: t("converter.user") },
        { name: "status", title: t("converter.status") },
        { name: "chat", title: <></> },
    ];

    const columns2 = [
        { name: "number", title: t("subscription.conversion") },
        { name: "account", title: t("converter.account") },
        { name: "uploadDate", title: t("converter.uploadDate") },
        { name: "startDate", title: t("payment.period") },
        { name: "balance", title: t("converter.totalAmount") },
        { name: "user", title: t("converter.user") },
        { name: "status", title: t("converter.status") },
        { name: "chat", title: <></> },
    ];

    const tableColumnExtensions1 = [
        { columnName: "number", width: "10%", align: "center" },
        { columnName: "account", width: "20%", align: "left" },
        { columnName: "uploadDate", width: "10%", align: "center" },
        { columnName: "check", align: "left" },
        { columnName: "contact", width: "10%", align: "left" },
        { columnName: "concept", width: "10%", align: "left" },
        { columnName: "startDate", width: "7%", align: "center" },
        { columnName: "balance", width: "7%", align: "right" },
        { columnName: "user", width: "7%", align: "left" },
        { columnName: "status", align: "center" },
        { columnName: "chat", width: "5%" },
    ];

    const tableColumnExtensions2 = [
        { columnName: "number", width: "10%", align: "center" },
        { columnName: "account", width: "20%", align: "left" },
        { columnName: "uploadDate", width: "10%", align: "center" },
        { columnName: "startDate", width: "15%", align: "center" },
        { columnName: "balance", width: "10%", align: "right" },
        { columnName: "user", width: "10%", align: "left" },
        { columnName: "status", align: "center" },
        { columnName: "chat", width: "5%" },
    ];

    const [columns, setColumns] = useState(columns1);
    const [tableColumnExtensions, setTableColumnExtensions] = useState(tableColumnExtensions1);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const attributes = [
        { id: 1, name: t("dashboard.bank"), query: "account_id_filter" },
        { id: 2, name: t("converter.uploadDate"), query: "uploaded" },
        { id: 3, name: t("converter.format"), query: "format" },
        { id: 4, name: t("converter.bankStatementDate"), query: "period" },
        { id: 5, name: t("converter.user"), query: "user_name" },
        { id: 6, name: t("converter.status"), query: "id_status" },
    ];

    const handleGetConversions = async (valueConv, tutorial) => {
        const index = filters.filtersArray.findIndex((x) => x.type === 1);
        const aux = [...filters.filtersArray];
        const ID = valueConv ?? aux[index]?.value;

        let params = { company_id: todos.companyID, account_id: ID };

        if (filters.filtersArray.filter((e) => e.type !== "")?.length > 0) {
            let objectFinal = {};

            aux.forEach((item) => {
                if (item.value && item.type) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
                }
                if (item.type && item.since) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                        item.since.format("YYYY-MM-DD");
                }
                if (item.type && item.until) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                        item.until.format("YYYY-MM-DD");
                }
            });

            params = {
                ...params,
                ...objectFinal,
            };

            if (params?.account_id) {
                params.account_id_filter = params.account_id;
                params.account_id = "";
            }
        }

        if (value === 1) {
            await conversionService
                .getGroupedConversions(params)
                .then((data) => {
                    setGroupConversions(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await conversionService
                .getConversions(params)
                .then((response) => {
                    if (response.length > 0) {
                        setConversions(response);
                        if (tutorial) {
                            setTutorial(true);
                        }
                    } else {
                        setConversions([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleCloseDialog = () => {
        setOpenModalDownloadF(false);
        setDownloadFormat("");
        setAccountNum("");
        setAccountType("");
        setBankInst(null);
        setQbFormat(false);
    };

    const handleCloseGroupedDiag = async () => {
        setOpenModalGrouped(false);

        if (groupedData?.conversions?.length === 0 || groupedData?.checks?.length === 0) {
            setConvertDone(false);
        }

        if (openModalGroupedHist) {
            setRowsModal([]);
        }
    };

    const connectSocket = async (case_id) => {
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await caseService
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo);

                initiateSocket(case_id, todos.userInfo, "general", "");
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const downloadConversionFunc = async (params) => {
        await conversionService
            .downloadConversion(params)
            .then(async (response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);

                await handleGetConversions(null, false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const downloadCheckConversionFunc = async (params) => {
        await checksService
            .downloadCheckConversion(params)
            .then(async (response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleClickHelp = async (type, conversion_type, conversion) => {
        setIsLoading(true);
        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: type,
            conversion_id: conversion !== "" ? conversion : undefined,
        };

        if (type === "conversion") {
            params.conversion_type = conversion_type;
        }

        let caseInfo = null;

        if (type !== "generalConv") {
            await caseService
                .getCaseInfo(params)
                .then(async (response) => {
                    if (response) {
                        setCaseInfo(response.caseInfo);
                        caseInfo = response.caseInfo;
                        setStatusCase("");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (!caseInfo) {
            await caseService
                .createNewCase(params)
                .then(async (response) => {
                    setCaseInfo(response);
                    caseInfo = response;
                    setStatusCase(1);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        await connectSocket(caseInfo._id);

        setIsLoading(false);
    };

    const connectSocketConv = async () => {
        setIsLoading(true);

        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: "conversion",
            conversion_id: conversionId,
        };

        let caseInfo = null;

        await caseService
            .getCaseInfo(params)
            .then(async (response) => {
                if (response) {
                    setCaseInfo(response.caseInfo);
                    caseInfo = response.caseInfo;
                }
            })
            .catch((err) => {
                console.log(err);
            });

        if (!caseInfo) {
            await caseService
                .createNewCase(params)
                .then(async () => {
                    await caseService
                        .getCaseInfo(params)
                        .then(async (data) => {
                            setCaseInfo(data.caseInfo);
                            caseInfo = data.caseInfo;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setRoom(caseInfo._id);

        initiateSocket(caseInfo._id, todos.userInfo, "conversion", correlative);
        subscribeToChat((err, data) => {
            if (err) return;
            setChat((oldChats) => [...oldChats, data]);
        });
        newUserResponse((err, data) => {
            if (err) return;
            setUsers(data);
        });
        setOpen(true);

        setIsLoading(false);
    };

    const handleOpenDownloadModal = async (event) => {
        setOpenModalDownloadF(true);
    };

    const handleClickDownload = async (event) => {
        setIsLoading(true);

        if (event.target.value === 16 || event.target.value === 15) {
            if (conversionData.account_number_qb && conversionData.account_type_qb && conversionData.account_bid_qb) {
                setAccountNum(conversionData.account_number_qb);
                setAccountType(conversionData.account_type_qb);
                setBankInst(finalQBOBanks.find((item) => item.value === conversionData.account_bid_qb) ?? null);
            } else {
                await accountsService
                    .getAccountDetails(account)
                    .then((response) => {
                        setAccountNum(response.account_number_qb ?? "");
                        setAccountType(response.account_type_qb ?? "");
                        setBankInst(finalQBOBanks.find((item) => item.value === response.account_bid_qb) ?? null);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            if (todos.steps.step8) {
                let isDone = false;

                setTimeout(() => {
                    introJs()
                        .setOptions({
                            disableInteraction: true,
                            exitOnOverlayClick: false,
                            keyboardNavigation: false,
                            nextLabel: t("dialog.continue"),
                            prevLabel: t("miscellaneous.goBack"),
                            doneLabel: t("miscellaneous.accept"),
                            steps: [
                                {
                                    title: `${t("miscellaneous.step")} 8`,
                                    element: "#qboBox",
                                    intro: t("miscellaneous.tutorialConv6"),
                                },
                            ],
                        })
                        .oncomplete(() => {
                            isDone = true;
                            dispatch(addTutorial({ ...todos.steps, step8: false, step9: true }));
                        })
                        .onexit(() => {
                            if (!isDone) {
                                dispatch(addTutorial({ ...todos.steps, step8: false }));
                            }
                        })
                        .start();
                }, 1000);
            }
            setQbFormat(true);
        } else {
            setAccountNum("");
            setAccountType("");
            setBankInst(null);
            setQbFormat(false);
        }
        setDownloadFormat(event.target.value);
        setIsLoading(false);
    };

    // Funcion para descargar el estado de cuenta QB Desktop
    const downloadQBO = async () => {
        setIsLoading(true);

        const params1 = {
            conversion_id: conversionId,
        };

        await conversionService
            .getConversionDetails(params1)
            .then(async (data) => {
                const formatFile = ".qbo";

                const params = {
                    file_name: `Statement_${fileNameSaved}${formatFile}`,
                    type: downloadFormat.toString(),
                    account_type_qb: accountType,
                    account_number_qb: accountNum.trim(),
                    account_bid_qb: bankInst.value,
                    conversion_id: conversionId,
                    signs: checkedInvSigngs ? -1 : 1,
                    date: checkedInvDay ? "DD/MM/YYYY" : "MM/DD/YYYY",
                    reverse: checkedDesRef,
                };

                if (groupedData) {
                    params.grouped_conversion_id = groupedData._id;
                }

                await downloadConversionFunc(params);
            })
            .catch((err) => {
                console.log(err);
            });

        handleCloseDialog();
        setIsLoading(false);
        setShowPrev(false);
        setDisabledInput(false);
        setFileData(null);
        setConvFormat(conversionFormats);
        setDownload(true);
        setConvertDone(false);
        setConvertInvalid(false);
        setConvertRev(false);
        setStatus(0);
    };

    // Funcion para descargar el estado de cuenta
    const downloadFormats = async () => {
        setIsLoading(true);

        let formatFile = downloadFormat === 1 ? ".xml" : downloadFormat === 4 ? ".xlsx" : ".csv";
        const params = {
            conversion_id: conversionId,
            file_name: `Statement_${fileNameSaved}${formatFile}`,
            type: downloadFormat.toString(),
            signs: checkedInvSigngs ? -1 : 1,
            date: checkedInvDay ? "DD/MM/YYYY" : "MM/DD/YYYY",
            reverse: checkedDesRef,
        };

        if (groupedData) {
            params.grouped_conversion_id = groupedData._id;
        }

        await downloadConversionFunc(params);

        if (
            accountDetConv.system_integration === 1 &&
            accountDetConv.account_id_system &&
            [13, 14].includes(downloadFormat)
        ) {
            await conversionService
                .getImportUrl(params)
                .then((response) => {
                    window.open(response, "_blank");
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setDisabledInput(false);
        setFileData(null);
        setConvFormat(conversionFormats);
        setDownload(true);
        setConvertDone(false);
        setConvertDoneNew(false);
        setConvertInvalid(false);
        setConvertRev(false);
        setStatus(0);
        setIsLoading(false);
        setConvInfo({});
        setStatus(0);
        handleCloseDialog();
        setShowPrev(false);
    };

    const handleClose = () => {
        setOpenHeavy(false);
    };

    // Funcion para descargar el cheque
    const downloadFormatsChecks = async () => {
        setIsLoading(true);

        let formatFile = downloadFormat === 1 ? ".xml" : downloadFormat === 4 ? ".xlsx" : ".csv";

        let currentFormatDate = todos.dateFormat;
        const params = {
            file_name: `Check_${fileNameSaved}${formatFile}`,
            download_format: downloadFormat.toString(),
            signs: checkedInvSigngs ? -1 : 1,
            date: checkedInvDay
                ? currentFormatDate === "DD/MM/YYYY"
                    ? "MM/DD/YYYY"
                    : "DD/MM/YYYY"
                : currentFormatDate,
        };

        if (groupedData) {
            params.grouped_check_id = groupedData._id;
        } else {
            params.check_id = conversionData._id;
        }

        await downloadCheckConversionFunc(params);

        setDisabledInput(false);
        setFileData(null);
        setConvFormat(conversionFormats);
        setDownload(true);
        setConvertDone(false);
        setConvertDoneNew(false);
        setConvertInvalid(false);
        setConvertRev(false);
        setStatus(0);
        setIsLoading(false);
        handleCloseDialog();
        setShowPrevCheck(false);
        reloadChecks();
    };

    const reloadChecks = async (valueConv) => {
        const index = filters.filtersArray.findIndex((x) => x.type === 1);
        const aux = [...filters.filtersArray];
        const ID = valueConv ?? aux[index]?.value;

        let params = { company_id: todos.companyID, account_id: ID };

        if (filters.filtersArray.filter((e) => e.type !== "")?.length > 0) {
            let objectFinal = {};

            aux.forEach((item) => {
                if (item.value && item.type) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
                }
                if (item.type && item.since) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                        item.since.format("YYYY-MM-DD");
                }
                if (item.type && item.until) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                        item.until.format("YYYY-MM-DD");
                }
            });

            params = {
                ...params,
                ...objectFinal,
            };

            if (params?.account_id) {
                params.account_id_filter = params.account_id;
                params.account_id = "";
            }
        }

        if (value === 0) {
            setTableColumnExtensions(tableColumnExtensions1);
            setColumns(columns1);
            await conversionsService
                .getCheckConversions(params)
                .then((response) => setCheckConversions(response))
                .catch((err) => {
                    console.log(err);
                    if (err.code !== "ERR_CANCELED") {
                        setTexto("Error");
                        setAlertType("error");
                        setShow(true);
                    }
                });
        } else {
            setTableColumnExtensions(tableColumnExtensions2);
            setColumns(columns2);
            await conversionService
                .getGroupedCheckConversions(params)
                .then((data) => {
                    setGroupCheckConversions(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        if (!todos.companyID) {
            navigate("/dashboard");
        }

        (async () => {
            setShowSkeleton(true);

            if (controllerRefAccount.current) {
                controllerRefAccount.current.abort();
            }
            const controllerAcc = new AbortController();
            controllerRefAccount.current = controllerAcc;

            const optionsAcc = {
                signal: controllerRefAccount.current.signal,
            };

            await accountsService
                .getAccounts({ company_id: todos.companyID, language: sessionStorage.getItem("lng") }, optionsAcc)
                .then((response) => {
                    const newAcc = response.setup.map((item) => {
                        return {
                            id: item._id,
                            name: item.account_name,
                            bankName: item.bank_name,
                        };
                    });
                    setAccounts(newAcc);
                    setFirstAccount(newAcc.length === 1 ? newAcc[0].id : "");
                    setAccount(
                        location.state?.account_id
                            ? location.state?.account_id
                            : newAcc.length === 1
                            ? newAcc[0].id
                            : ""
                    );
                })
                .catch((err) => {
                    console.log(err);
                    if (err.code !== "ERR_CANCELED") {
                        setTexto("Error");
                        setAlertType("error");
                        setShow(true);
                    }
                });

            controllerRefAccount.current = null;

            if (valueMain === 0 && value === 1) {
                await conversionService
                    .getGroupedConversions({
                        company_id: todos.companyID,
                        account_id: location.state?.account_id && account ? account : "",
                    })
                    .then((data) => {
                        setConversions([]);
                        setGroupConversions(data);
                        setCheckConversions([]);
                        setGroupCheckConversions([]);
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.code !== "ERR_CANCELED") {
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        }
                    });
            } else if (valueMain === 0 && value === 0) {
                await conversionsService
                    .getConversions({
                        company_id: todos.companyID,
                        account_id: location.state?.account_id ?? "",
                    })
                    .then((response) => {
                        setConversions(response);
                        setGroupConversions([]);
                        setCheckConversions([]);
                        setGroupCheckConversions([]);
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.code !== "ERR_CANCELED") {
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        }
                    });
            } else if (valueMain === 1 && value === 0) {
                await conversionsService
                    .getCheckConversions({
                        company_id: todos.companyID,
                        account_id: location.state?.account_id && account ? account : "",
                    })
                    .then((response) => {
                        setConversions([]);
                        setGroupConversions([]);
                        setCheckConversions(response);
                        setGroupCheckConversions([]);
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.code !== "ERR_CANCELED") {
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        }
                    });
            } else if (valueMain === 1 && value === 1) {
                await conversionService
                    .getGroupedCheckConversions({
                        company_id: todos.companyID,
                        account_id: location.state?.account_id && account ? account : "",
                    })
                    .then((data) => {
                        setConversions([]);
                        setGroupConversions([]);
                        setCheckConversions([]);
                        setGroupCheckConversions(data);
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.code !== "ERR_CANCELED") {
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        }
                    });
            }

            if (controllerRefCompany.current) {
                controllerRefCompany.current.abort();
            }
            const controllerCompany = new AbortController();
            controllerRefCompany.current = controllerCompany;

            await accountsService
                .getAccountDetails(account)
                .then((data) => {
                    setIntegration(data.system_integration);
                })
                .catch((error) => {
                    console.log(error);
                });

            controllerRefCompany.current = null;

            if (controllerRefConvUsers.current) {
                controllerRefConvUsers.current.abort();
            }
            const controllerConvUsers = new AbortController();
            controllerRefConvUsers.current = controllerConvUsers;

            const optionsConvUsers = {
                signal: controllerRefConvUsers.current.signal,
            };

            await conversionsService
                .getConversionUsers({ company_id: todos.companyID }, optionsConvUsers)
                .then((response) => setUsersFilters(response))
                .catch((err) => {
                    console.log(err);
                    if (err.code !== "ERR_CANCELED") {
                        setTexto("Error");
                        setAlertType("error");
                        setShow(true);
                    }
                });

            controllerRefConvUsers.current = null;

            if (controllerRefFormatUser.current) {
                controllerRefFormatUser.current.abort();
            }
            const controllerFormatUser = new AbortController();
            controllerRefFormatUser.current = controllerFormatUser;

            const optionsFormatUser = {
                signal: controllerRefFormatUser.current.signal,
            };

            await userFormatService
                .getUserFormat(todos.companyID, optionsFormatUser)
                .then((data) => {
                    if (data) {
                        setUserFormatData(data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.code !== "ERR_CANCELED") {
                        setTexto("Error");
                        setAlertType("error");
                        setShow(true);
                    }
                });

            controllerRefFormatUser.current = null;

            setShowSkeleton(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state?.account_id, navigate, todos.companyID, valueMain]);

    const removeBanner = () => {
        setConvertDone(false);
        setConvertDoneNew(false);
        setConvertInvalid(false);
        setShowPrev(false);
        setShowPrevCheck(false);
        setFileData(null);
        setConvFormat(conversionFormats);
        setCorrelative(null);
        setConversionId(null);
        setProgress(0);
        setEndConversion(false);
        setStatus(0);
        setDisabledInput(false);
        setGroupedData(null);
        setAcceptedFiles([]);
        setConvInfo({});
        setShowSync(false);
    };

    const handleConvertNewFiles = () => {
        setOpenModalGrouped(false);
        setReloadConv(true);
    };

    const handleChangeTab = (event, newValue) => {
        let confirmChange = true;
        if (fileData) {
            confirmChange = window.confirm(t("converter.itsPosibleConfirm"));
        }

        if (confirmChange) {
            setShowSkeleton(true);
            setValueMain(newValue);
            setShowSkeleton(false);
            removeBanner();
            setValue(0); // Al cambiar de tab, siempre debe caer por defecto en "Individuales"
            setRows([]); // Se eliminan los registros que se muestran en las modales de "Ver detalles"
            setRowsModal([]);
            setShowRepeatVal([]);
            setShowRepeatModal(false);
            setRepeatedConv([]);
            setFilters(defaultFilter);
        }
    };

    const handleClosePreview = () => {
        setOpenPreview(false);
        setBasePreviewData({
            conversion_id: "",
            type: "",
            file_s3: "",
            file: "",
        });
    };

    const handleCloseMatchBankModal = () => setShowMatchBankModal(false);

    const actionsContent = (
        <Grid container spacing={2} alignItems="center" justifyContent="space-evenly">
            <Grid item>
                <img src={Loading} alt="loading" />
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography variant="h1">{`${t("converter.syncInProgress")}`}</Typography>
                <Typography pt={1}>{t("converter.syncInProgressText")}</Typography>
            </Grid>
        </Grid>
    );

    if (showPrev) {
        return (
            <BackGeneral colorBg="rgba(3, 24, 81, 0.03)">
                <ConversionPreview
                    handleClickDownload={handleOpenDownloadModal}
                    account={account}
                    accounts={accounts}
                    correlative={correlative}
                    handleClickHelp={handleClickHelp}
                    fileData={fileData}
                    __html={__html}
                    conversionData={conversionData}
                    checkedInvSigngs={checkedInvSigngs}
                    setCheckedInvSigngs={setCheckedInvSigngs}
                    checkedInvDay={checkedInvDay}
                    setCheckedInvDay={setCheckedInvDay}
                    fileNameFull={fileNameFull}
                    checkedDesRef={checkedDesRef}
                    setCheckedDesRef={setCheckedDesRef}
                    removeBanner={removeBanner}
                    groupedData={groupedData}
                    groupedPeriods={groupedPeriods}
                    setIsLoading={setIsLoading}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setRowsModal={setRowsModal}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    setModalType={setModalType}
                    setRows={setRows}
                    setBasePreviewData={setBasePreviewData}
                    basePreviewData={basePreviewData}
                    integration={integration}
                />
                <SimpleBackdrop open={isLoading} />
                <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
                <ChatModal
                    room={room}
                    setRoom={setRoom}
                    open={open}
                    setOpen={setOpen}
                    setChat={setChat}
                    chat={chat}
                    type={statusCase}
                    users={users}
                    franchiseName={
                        todos.allComData?.find(
                            (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                        )?.franchise_name
                    }
                    setCaseInfo={setCaseInfo}
                    caseInfo={caseInfo}
                />
                <FormDialog
                    open={openModalDownloadF}
                    handleClose={handleCloseDialog}
                    maxWidth="sm"
                    title={
                        <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center">
                            <InfoIcon fontSize="small" sx={{ color: "#4A22D4" }} />
                            <Typography fontSize={24} color="primary" fontWeight={600} textAlign="center">
                                {t("converter.selectDownloadFormat")}
                            </Typography>
                        </Stack>
                    }
                    content={
                        <DownloadFormatContent
                            setBankInst={setBankInst}
                            setAccountNum={setAccountNum}
                            setAccountType={setAccountType}
                            accountNum={accountNum}
                            accountType={accountType}
                            finalQBOBanks={finalQBOBanks}
                            bankInst={bankInst}
                            downloadFormat={downloadFormat}
                            handleChangeDownloadFormat={handleClickDownload}
                            qbFormat={qbFormat}
                            type="bankStatements"
                        />
                    }
                    actions={
                        <DownloadFormatButtons
                            handleCloseDialog={handleCloseDialog}
                            downloadQBO={
                                downloadFormat === 15 || downloadFormat === 16
                                    ? () => downloadQBO()
                                    : () => downloadFormats()
                            }
                            accountNum={accountNum}
                            accountType={accountType}
                            bankInst={bankInst}
                            downloadFormat={downloadFormat}
                        />
                    }
                    align="center"
                />
                <FormDialog
                    open={openModalGrouped}
                    handleClose={handleCloseGroupedDiag}
                    maxWidth="md"
                    title={
                        modalType === "bankStatement" ? t("converter.convDetails") : t("converter.convDetailsChecks")
                    }
                    content={
                        <GroupedContent
                            groupedData={groupedData}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setShow={setShow}
                            setConvFormat={setConvFormat}
                            acceptedFiles={acceptedFiles}
                            setAcceptedFiles={setAcceptedFiles}
                            setFileData={setFileData}
                            setGroupedData={setGroupedData}
                            setConvertDone={setConvertDone}
                            setS3FileName={setS3FileName}
                            setOriginalFileName={setOriginalFileName}
                            s3FileName={s3FileName}
                            originalFileName={originalFileName}
                            formats={formats}
                            setRows={setRows}
                            rows={rows}
                            setOpenPreview={setOpenPreview}
                            setHTML={setHTML}
                            setIsLoading={setIsLoading}
                            isHist={openModalGroupedHist}
                            setFileNameFull={setFileNameFull}
                            accountId={account}
                            setProgress={setProgress}
                            progress={progress}
                            setConvInfo={setConvInfo}
                            modalType={modalType}
                            setRowsModal={setRowsModal}
                            rowsModal={rowsModal}
                            setBasePreviewData={setBasePreviewData}
                            setShowRepeatModal={setShowRepeatModal}
                            setShowRepeatVal={setShowRepeatVal}
                            setResetConversionMain={setResetConversionMain}
                            setOpenModalGrouped={setOpenModalGrouped}
                            repeatedConv={repeatedConv}
                        />
                    }
                    actions={
                        <GroupedButtons
                            convertAll={handleConvertNewFiles}
                            handleCloseDialog={handleCloseGroupedDiag}
                            isHist={openModalGroupedHist}
                        />
                    }
                />
                {openPreview && (
                    <SimpleDialog
                        open={openPreview}
                        handleClose={handleClosePreview}
                        maxWidth="md"
                        preview={true}
                        content={
                            <BasePreviewFile
                                filename={fileNameFull ? fileNameFull?.toLowerCase() : fileData.path?.toLowerCase()}
                                filedata={fileData}
                                __html={__html}
                            />
                        }
                        actions={<BaseActionsPreviewFile basePreviewData={basePreviewData} />}
                    />
                )}
            </BackGeneral>
        );
    }

    if (showPrevCheck) {
        return (
            <BackGeneral colorBg={showSync ? "white" : "rgba(3, 24, 81, 0.03)"}>
                <ChecksPreview
                    handleClickDownload={handleOpenDownloadModal}
                    removeBanner={removeBanner}
                    account={account}
                    accounts={accounts}
                    correlative={correlative}
                    handleClickHelp={handleClickHelp}
                    fileData={fileData}
                    conversionData={conversionData}
                    checkedInvSigngs={checkedInvSigngs}
                    setCheckedInvSigngs={setCheckedInvSigngs}
                    checkedInvDay={checkedInvDay}
                    setCheckedInvDay={setCheckedInvDay}
                    groupedData={groupedData}
                    groupedPeriods={groupedPeriods}
                    setIsLoading={setIsLoading}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setRowsModal={setRowsModal}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    setModalType={setModalType}
                    setShowSync={setShowSync}
                    showSync={showSync}
                    fileNameSaved={fileNameSaved}
                    integration={integration}
                    setFileData={setFileData}
                    setFileNameFull={setFileNameFull}
                    setOpenPreview={setOpenPreview}
                    showMatchBankModal={showMatchBankModal}
                    handleCloseMatchBankModal={handleCloseMatchBankModal}
                    matchBank={matchBank}
                    setShowMatchBankModal={setShowMatchBankModal}
                    reloadChecks={reloadChecks}
                    setRows={setRows}
                    setBasePreviewData={setBasePreviewData}
                    basePreviewData={basePreviewData}
                    setShowSyncDetails={setShowSyncDetails}
                    showSyncDetails={showSyncDetails}
                    setOpenHeavy={setOpenHeavy}
                    repeatedConv={repeatedConv}
                    setRepeatedConv={setRepeatedConv}
                />
                <SimpleBackdrop open={isLoading} />
                <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
                <ChatModal
                    room={room}
                    setRoom={setRoom}
                    open={open}
                    setOpen={setOpen}
                    setChat={setChat}
                    chat={chat}
                    type={statusCase}
                    users={users}
                    franchiseName={
                        todos.allComData?.find(
                            (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                        )?.franchise_name
                    }
                    setCaseInfo={setCaseInfo}
                    caseInfo={caseInfo}
                />

                <FormDialog
                    open={openModalDownloadF}
                    handleClose={handleCloseDialog}
                    maxWidth="sm"
                    title={
                        <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center">
                            <InfoIcon fontSize="small" sx={{ color: "#4A22D4" }} />
                            <Typography fontSize={24} color="primary" fontWeight={600} textAlign="center">
                                {t("converter.selectDownloadFormat")}
                            </Typography>
                        </Stack>
                    }
                    content={
                        <DownloadFormatContent
                            setBankInst={setBankInst}
                            setAccountNum={setAccountNum}
                            setAccountType={setAccountType}
                            accountNum={accountNum}
                            accountType={accountType}
                            finalQBOBanks={finalQBOBanks}
                            bankInst={bankInst}
                            downloadFormat={downloadFormat}
                            handleChangeDownloadFormat={handleClickDownload}
                            qbFormat={qbFormat}
                            type="checks"
                        />
                    }
                    actions={
                        <DownloadFormatButtons
                            handleCloseDialog={handleCloseDialog}
                            downloadQBO={downloadFormatsChecks}
                            accountNum={accountNum}
                            accountType={accountType}
                            bankInst={bankInst}
                            downloadFormat={downloadFormat}
                        />
                    }
                    align="center"
                />
                <FormDialog
                    open={openModalGrouped}
                    handleClose={handleCloseGroupedDiag}
                    maxWidth="md"
                    title={
                        modalType === "bankStatement" ? t("converter.convDetails") : t("converter.convDetailsChecks")
                    }
                    content={
                        <GroupedContent
                            groupedData={groupedData}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setShow={setShow}
                            setConvFormat={setConvFormat}
                            acceptedFiles={acceptedFiles}
                            setAcceptedFiles={setAcceptedFiles}
                            setFileData={setFileData}
                            setGroupedData={setGroupedData}
                            setConvertDone={setConvertDone}
                            setS3FileName={setS3FileName}
                            setOriginalFileName={setOriginalFileName}
                            s3FileName={s3FileName}
                            originalFileName={originalFileName}
                            setRows={setRows}
                            rows={rows}
                            setOpenPreview={setOpenPreview}
                            setHTML={setHTML}
                            setIsLoading={setIsLoading}
                            isHist={openModalGroupedHist}
                            setFileNameFull={setFileNameFull}
                            accountId={account}
                            setProgress={setProgress}
                            progress={progress}
                            setConvInfo={setConvInfo}
                            convInfo={convInfo}
                            modalType={modalType}
                            setRowsModal={setRowsModal}
                            rowsModal={rowsModal}
                            setBasePreviewData={setBasePreviewData}
                            setShowRepeatModal={setShowRepeatModal}
                            setShowRepeatVal={setShowRepeatVal}
                            setOpenModalGrouped={setOpenModalGrouped}
                            repeatedConv={repeatedConv}
                        />
                    }
                    actions={
                        <GroupedButtons convertAll={handleConvertNewFiles} handleCloseDialog={handleCloseGroupedDiag} />
                    }
                />
                {openPreview && (
                    <SimpleDialog
                        open={openPreview}
                        handleClose={handleClosePreview}
                        maxWidth="md"
                        preview={true}
                        content={
                            <BasePreviewFile
                                filename={fileNameFull ? fileNameFull?.toLowerCase() : fileData.path?.toLowerCase()}
                                filedata={fileData}
                                __html={__html}
                            />
                        }
                        actions={<BaseActionsPreviewFile basePreviewData={basePreviewData} />}
                    />
                )}
            </BackGeneral>
        );
    }

    return (
        <BackGeneral colorBg="#fff">
            <Tabs
                orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                aria-label="Horizontal tabs"
                sx={{
                    p: 1,
                    ml: 1,
                    mt: 3,
                }}
                TabIndicatorProps={{
                    style: { display: "none" },
                }}
                value={valueMain}
                onChange={handleChangeTab}
            >
                <Tab label={t("converter.bankState")} {...a11yProps(0)} sx={TabProps} />
                <Tab label={t("converter.checks")} {...a11yProps(1)} sx={TabProps} />
            </Tabs>
            <TabPanel value={valueMain} index={0}>
                <MainConverter
                    setIntegration={setIntegration}
                    setIsLoading={setIsLoading}
                    accounts={accounts}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    setShow={setShow}
                    setShowSkeleton={setShowSkeleton}
                    setAccount={setAccount}
                    account={account}
                    filters={filters}
                    setFilters={setFilters}
                    userFormatData={userFormatData}
                    setUserFormatData={setUserFormatData}
                    accountType={accountType}
                    setAccountType={setAccountType}
                    accountNum={accountNum}
                    setAccountNum={setAccountNum}
                    setBankInst={setBankInst}
                    bankInst={bankInst}
                    convertDoneNew={convertDoneNew}
                    setCorrelative={setCorrelative}
                    isLargeConv={isLargeConv}
                    setConversionId={setConversionId}
                    conversionId={conversionId}
                    setFileNameSaved={setFileNameSaved}
                    fileNameSaved={fileNameSaved}
                    setConvertDoneNew={setConvertDoneNew}
                    setShowPrev={setShowPrev}
                    showModalCredits={showModalCredits}
                    setShowModalCredits={setShowModalCredits}
                    finalQBOBanks={finalQBOBanks}
                    resetConversionMain={resetConversionMain}
                    setResetConversionMain={setResetConversionMain}
                    handleGetConversions={handleGetConversions}
                    setAccountDetConv={setAccountDetConv}
                    accountDetConv={accountDetConv}
                    handleClickHelp={handleClickHelp}
                    setFileData={setFileData}
                    fileData={fileData}
                    setHTML={setHTML}
                    __html={__html}
                    setConversionData={setConversionData}
                    setDisabledInput={setDisabledInput}
                    disabledInput={disabledInput}
                    setFormats={setFormats}
                    formats={formats}
                    setEndPoint={setEndPoint}
                    endPoint={endPoint}
                    setAccountDet={setAccountDet}
                    accountDet={accountDet}
                    setConvertDone={setConvertDone}
                    convertDone={convertDone}
                    setConvertRev={setConvertRev}
                    convertRev={convertRev}
                    setConvertInvalid={setConvertInvalid}
                    convertInvalid={convertInvalid}
                    setStatus={setStatus}
                    status={status}
                    setConvFormat={setConvFormat}
                    convFormat={convFormat}
                    connectSocketConv={connectSocketConv}
                    setFileNameFull={setFileNameFull}
                    firstAccount={firstAccount}
                    groupedData={groupedData}
                    setGroupedData={setGroupedData}
                    setGroupedPeriods={setGroupedPeriods}
                    groupedPeriods={groupedPeriods}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setAcceptedFiles={setAcceptedFiles}
                    acceptedFiles={acceptedFiles}
                    setS3FileName={setS3FileName}
                    setOriginalFileName={setOriginalFileName}
                    s3FileName={s3FileName}
                    originalFileName={originalFileName}
                    reloadConv={reloadConv}
                    setReloadConv={setReloadConv}
                    progress={progress}
                    setProgress={setProgress}
                    endConversion={endConversion}
                    setEndConversion={setEndConversion}
                    setRows={setRows}
                    rows={rows}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    fileNameFull={fileNameFull}
                    setCustomCredits={setCustomCredits}
                    customCredits={customCredits}
                    setConvInfo={setConvInfo}
                    convInfo={convInfo}
                    setModalType={setModalType}
                    setRowsModal={setRowsModal}
                    rowsModal={rowsModal}
                    showRepeatModal={showRepeatModal}
                    setShowRepeatModal={setShowRepeatModal}
                    showRepeatVal={showRepeatVal}
                    setShowRepeatVal={setShowRepeatVal}
                    setBasePreviewData={setBasePreviewData}
                    setOpenPreview={setOpenPreview}
                />
                <ConverterTable
                    conversions={conversions}
                    accounts={accounts}
                    usersFilters={usersFilters}
                    company_id={todos?.companyID}
                    setConversions={setConversions}
                    setIsLoading={setIsLoading}
                    franchise_id={todos?.franchiseID}
                    grupal={todos?.isGrouped}
                    showSkeleton={showSkeleton}
                    setShowSkeleton={setShowSkeleton}
                    setFilters={setFilters}
                    filters={filters}
                    setTutorial={setTutorial}
                    tutorial={tutorial}
                    setCorrelative={setCorrelative}
                    setConversionId={setConversionId}
                    setFileNameSaved={setFileNameSaved}
                    setAccount={setAccount}
                    setShowPrev={setShowPrev}
                    setFileData={setFileData}
                    setFileNameFull={setFileNameFull}
                    setConversionData={setConversionData}
                    setHTML={setHTML}
                    setAccountNum={setAccountNum}
                    setAccountType={setAccountType}
                    handleGetConversions={handleGetConversions}
                    finalQBOBanks={finalQBOBanks}
                    setBankInst={setBankInst}
                    setEndPoint={setEndPoint}
                    setFormats={setFormats}
                    setAccountDet={setAccountDet}
                    download={download}
                    setDownload={setDownload}
                    setConvFormat={setConvFormat}
                    account={account}
                    setGroupConversions={setGroupConversions}
                    groupConversions={groupConversions}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    setGroupedData={setGroupedData}
                    setGroupedPeriods={setGroupedPeriods}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setValue={setValue}
                    value={value}
                    setModalType={setModalType}
                    setRowsModal={setRowsModal}
                    setBasePreviewData={setBasePreviewData}
                    setIntegration={setIntegration}
                />
            </TabPanel>
            <TabPanel value={valueMain} index={1}>
                <ChecksConverter
                    setIsLoading={setIsLoading}
                    accounts={accounts}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    setShow={setShow}
                    setShowSkeleton={setShowSkeleton}
                    setAccount={setAccount}
                    account={account}
                    filters={filters}
                    setFilters={setFilters}
                    userFormatData={userFormatData}
                    setUserFormatData={setUserFormatData}
                    accountType={accountType}
                    setAccountType={setAccountType}
                    accountNum={accountNum}
                    setAccountNum={setAccountNum}
                    setBankInst={setBankInst}
                    bankInst={bankInst}
                    convertDoneNew={convertDoneNew}
                    setCorrelative={setCorrelative}
                    correlative={correlative}
                    isLargeConv={isLargeConv}
                    setConversionId={setConversionId}
                    conversionId={conversionId}
                    setFileNameSaved={setFileNameSaved}
                    fileNameSaved={fileNameSaved}
                    setConvertDoneNew={setConvertDoneNew}
                    setShowPrev={setShowPrevCheck}
                    showModalCredits={showModalCredits}
                    setShowModalCredits={setShowModalCredits}
                    finalQBOBanks={finalQBOBanks}
                    resetConversionMain={resetConversionMain}
                    setResetConversionMain={setResetConversionMain}
                    reloadChecks={reloadChecks}
                    setAccountDetConv={setAccountDetConv}
                    accountDetConv={accountDetConv}
                    handleClickHelp={handleClickHelp}
                    setFileData={setFileData}
                    setConversionData={setConversionData}
                    setDisabledInput={setDisabledInput}
                    disabledInput={disabledInput}
                    setFormats={setFormats}
                    formats={formats}
                    setEndPoint={setEndPoint}
                    endPoint={endPoint}
                    setAccountDet={setAccountDet}
                    accountDet={accountDet}
                    setConvertDone={setConvertDone}
                    convertDone={convertDone}
                    setConvertRev={setConvertRev}
                    convertRev={convertRev}
                    setConvertInvalid={setConvertInvalid}
                    convertInvalid={convertInvalid}
                    setStatus={setStatus}
                    status={status}
                    setConvFormat={setConvFormat}
                    convFormat={convFormat}
                    connectSocketConv={connectSocketConv}
                    setFileNameFull={setFileNameFull}
                    firstAccount={firstAccount}
                    groupedData={groupedData}
                    setGroupedData={setGroupedData}
                    setGroupedPeriods={setGroupedPeriods}
                    groupedPeriods={groupedPeriods}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setAcceptedFiles={setAcceptedFiles}
                    acceptedFiles={acceptedFiles}
                    setS3FileName={setS3FileName}
                    s3FileName={s3FileName}
                    reloadConv={reloadConv}
                    setReloadConv={setReloadConv}
                    progress={progress}
                    setProgress={setProgress}
                    setEndConversion={setEndConversion}
                    setRows={setRows}
                    rows={rows}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    fileNameFull={fileNameFull}
                    setCustomCredits={setCustomCredits}
                    customCredits={customCredits}
                    setModalType={setModalType}
                    matchBank={matchBank}
                    setShowMatchBankModal={setShowMatchBankModal}
                    setMatchBank={setMatchBank}
                    setRowsModal={setRowsModal}
                    rowsModal={rowsModal}
                    showRepeatModal={showRepeatModal}
                    setShowRepeatModal={setShowRepeatModal}
                    showRepeatVal={showRepeatVal}
                    setShowRepeatVal={setShowRepeatVal}
                    setBasePreviewData={setBasePreviewData}
                    setRepeatedConv={setRepeatedConv}
                    setOpenPreview={setOpenPreview}
                />
                <ChecksTable
                    checkConversions={checkConversions}
                    accounts={accounts}
                    usersFilters={usersFilters}
                    company_id={todos?.companyID}
                    setCheckConversions={setCheckConversions}
                    setIsLoading={setIsLoading}
                    franchise_id={todos?.franchiseID}
                    grupal={todos?.isGrouped}
                    showSkeleton={showSkeleton}
                    setShowSkeleton={setShowSkeleton}
                    setFilters={setFilters}
                    filters={filters}
                    setTutorial={setTutorial}
                    tutorial={tutorial}
                    setConvertDoneNew={setConvertDoneNew}
                    setCorrelative={setCorrelative}
                    setIsLargeConv={setIsLargeConv}
                    setConversionId={setConversionId}
                    setFileNameSaved={setFileNameSaved}
                    setAccount={setAccount}
                    setShowPrev={setShowPrevCheck}
                    setFileData={setFileData}
                    setFileNameFull={setFileNameFull}
                    setConversionData={setConversionData}
                    setHTML={setHTML}
                    setAccountNum={setAccountNum}
                    setAccountType={setAccountType}
                    reloadChecks={reloadChecks}
                    finalQBOBanks={finalQBOBanks}
                    setBankInst={setBankInst}
                    setEndPoint={setEndPoint}
                    setAccountDet={setAccountDet}
                    download={download}
                    setDownload={setDownload}
                    setConvFormat={setConvFormat}
                    account={account}
                    setGroupConversions={setGroupCheckConversions}
                    groupConversions={groupCheckConversions}
                    setOpenModalGroupedHist={setOpenModalGroupedHist}
                    setGroupedData={setGroupedData}
                    setGroupedPeriods={setGroupedPeriods}
                    setOpenModalGrouped={setOpenModalGrouped}
                    setValue={setValue}
                    value={value}
                    setModalType={setModalType}
                    integration={integration}
                    setShowSync={setShowSync}
                    columns={columns}
                    setColumns={setColumns}
                    tableColumnExtensions={tableColumnExtensions}
                    setTableColumnExtensions={setTableColumnExtensions}
                    columns1={columns1}
                    columns2={columns2}
                    tableColumnExtensions1={tableColumnExtensions1}
                    tableColumnExtensions2={tableColumnExtensions2}
                    setRowsModal={setRowsModal}
                    setOpenPreview={setOpenPreview}
                    setBasePreviewData={setBasePreviewData}
                    setRepeatedConv={setRepeatedConv}
                />
            </TabPanel>
            <SimpleBackdrop open={isLoading} />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
            <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                type={1}
                users={users}
                franchiseName={
                    todos.allComData?.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    )?.franchise_name
                }
                setCaseInfo={setCaseInfo}
                caseInfo={caseInfo}
            />
            <FormDialog
                open={openModalGrouped}
                handleClose={handleCloseGroupedDiag}
                maxWidth="md"
                title={modalType === "bankStatement" ? t("converter.convDetails") : t("converter.convDetailsChecks")}
                content={
                    <GroupedContent
                        groupedData={groupedData}
                        setTexto={setTexto}
                        setAlertType={setAlertType}
                        setShow={setShow}
                        setConvFormat={setConvFormat}
                        acceptedFiles={acceptedFiles}
                        setAcceptedFiles={setAcceptedFiles}
                        setFileData={setFileData}
                        setGroupedData={setGroupedData}
                        setConvertDone={setConvertDone}
                        setS3FileName={setS3FileName}
                        s3FileName={s3FileName}
                        formats={formats}
                        setRows={setRows}
                        rows={rows}
                        setOpenPreview={setOpenPreview}
                        setHTML={setHTML}
                        setIsLoading={setIsLoading}
                        isHist={openModalGroupedHist}
                        setFileNameFull={setFileNameFull}
                        accountId={account}
                        setProgress={setProgress}
                        progress={progress}
                        setConvInfo={setConvInfo}
                        modalType={modalType}
                        setRowsModal={setRowsModal}
                        rowsModal={rowsModal}
                        setBasePreviewData={setBasePreviewData}
                        setShowRepeatModal={setShowRepeatModal}
                        setShowRepeatVal={setShowRepeatVal}
                        convInfo={convInfo}
                        setResetConversionMain={setResetConversionMain}
                        setOpenModalGrouped={setOpenModalGrouped}
                    />
                }
                actions={
                    <GroupedButtons
                        convertAll={handleConvertNewFiles}
                        handleCloseDialog={handleCloseGroupedDiag}
                        isHist={openModalGroupedHist}
                    />
                }
            />
            {openPreview && (
                <SimpleDialog
                    open={openPreview}
                    handleClose={handleClosePreview}
                    maxWidth="md"
                    preview={true}
                    content={
                        <BasePreviewFile
                            filename={fileNameFull ? fileNameFull?.toLowerCase() : fileData.path?.toLowerCase()}
                            filedata={fileData}
                            __html={__html}
                        />
                    }
                    actions={<BaseActionsPreviewFile basePreviewData={basePreviewData} />}
                />
            )}
            <SimpleDialog open={openHeavy} handleClose={handleClose} maxWidth="sm" content={actionsContent} />
        </BackGeneral>
    );
};

export default Converter;

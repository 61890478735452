import Slider from "react-slick";

import { Box, Card, CardActionArea, CardContent, Stack, Typography } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import LightTooltip from "../../ui/LightTooltip";

function Arrows(props) {
    const { className, style, onClick, isDisabled } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", pointerEvents: isDisabled ? "none" : "all" }}
            onClick={onClick}
        />
    );
}

const InvoiceSlider = (props) => {
    const { invoices, invoiceData, handleInvoice, icon } = props;

    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");

    const settings = {
        speed: 500,
        slidesToShow: 4,
        nextArrow: <Arrows isDisabled={invoices.length <= 4} />,
        prevArrow: <Arrows isDisabled={invoices.length <= 4} />,
        draggable: invoices.length > 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const sliderClassName = invoices.length < 4 ? "single-slide" : "";

    return (
        <Slider {...settings} style={{ margin: 20 }} className={sliderClassName}>
            {invoices.map((item) => (
                <div key={item._id}>
                    <Card
                        sx={{
                            margin: "0 10px",
                            backgroundColor: invoiceData._id === item._id ? "#4a22d4" : "#F9F8FD",
                            boxShadow: "4px 4px 5px 0px rgba(74, 34, 212, 0.1)",
                            borderRadius: 3,
                        }}
                    >
                        <CardActionArea
                            onClick={() => handleInvoice(item)}
                            sx={{
                                minHeight: 120,
                            }}
                        >
                            <CardContent>
                                <Stack direction="row" spacing={2} justifyContent="space-between">
                                    <Typography
                                        variant="h1"
                                        color={invoiceData._id === item._id ? "white" : "primary"}
                                        gutterBottom
                                    >
                                        {item.invoice_id}
                                    </Typography>
                                    <Typography color={invoiceData._id === item._id ? "white" : "#131f3e"} gutterBottom>
                                        {moment.utc(item.formatted_invoice_date).format(todos.dateFormat)}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                    <Box sx={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                                        <LightTooltip title={item.vendor_name}>
                                            <Typography
                                                color={invoiceData._id === item._id ? "white" : "#131f3e"}
                                                sx={{ textOverflow: "ellipsis" }}
                                            >
                                                {item.vendor_name}
                                            </Typography>
                                        </LightTooltip>
                                        <Typography color={invoiceData._id === item._id ? "white" : "#131f3e"}>
                                            <b
                                                style={{
                                                    color: invoiceData._id === item._id ? "white" : "#4a22d4",
                                                }}
                                            >
                                                {item.line_items?.length}
                                            </b>{" "}
                                            {item.line_items?.length === 1
                                                ? t("dashboard.transaccOne")
                                                : t("dashboard.transacc")}
                                        </Typography>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography
                                                variant="h3"
                                                color={invoiceData._id === item._id ? "white" : "#131F3E"}
                                            >
                                                {t("converter.totalAmount")}
                                            </Typography>
                                            <LightTooltip
                                                title={
                                                    icon !== "check"
                                                        ? icon === "alert"
                                                            ? t("converter.pendingLines")
                                                            : t("converter.amountLines")
                                                        : t("converter.readySyncLines")
                                                }
                                            >
                                                <InfoIcon
                                                    sx={{
                                                        color: invoiceData._id === item._id ? "#fff" : "#4A22D4",
                                                        fontSize: 16,
                                                    }}
                                                />
                                            </LightTooltip>
                                            <Typography color={invoiceData._id === item._id ? "white" : "primary"}>
                                                $
                                                {parseFloat(item.formatted_invoice_total).toLocaleString(
                                                    todos.amountFormat,
                                                    {
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    {icon !== "check" ? (
                                        <ErrorIcon color={icon === "alert" ? "error" : "warning"} />
                                    ) : (
                                        <CheckIcon color="success" />
                                    )}
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            ))}
        </Slider>
    );
};

export default InvoiceSlider;

import {
    Box,
    Chip,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

/* Estilos */
import classes from "./ShoppingItems.module.css";

/* Iconos */
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import plansInfo from "../../Js/plans";

const ShoppingItemsReg = (props) => {
    const { deleteItem, currentPlan, pagesMonthly, origin, activePlan } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const planFeatures = [
        <>
            <b style={{ color: "#4A22D4" }}>{parseFloat(pagesMonthly).toLocaleString(todos.amountFormat)}</b>{" "}
            {t("plans.pageMonth")}
        </>,
        <>
            {t("plans.users1")} <b style={{ color: "#4A22D4" }}>{t("plans.unlimited1")}</b>
        </>,
        <>
            {t("plans.training")}{" "}
            <b style={{ color: "#4A22D4" }}>{currentPlan.value === 1 ? t("plans.knowBase") : t("plans.custom")}</b>
        </>,
        <>{t("plans.integSys")}</>,
        <>
            {t("plans.productUpda")}{" "}
            <b style={{ color: "#4A22D4" }}>{currentPlan.value === 1 ? t("plans.standard") : t("plans.prioritys")}</b>
        </>,
        <>
            {t("plans.extend")}{" "}
            <b style={{ color: "#4A22D4" }}>
                {currentPlan.value === 1 ? 30 : currentPlan.value === 2 ? 60 : 90} {t("miscellaneous.days")}
            </b>
        </>,
    ];

    const checkCurrentPlanId = () => plansInfo.flatMap((item) => item.levels).find((item) => item.pages === pagesMonthly)?.id;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Paper variant="outlined" className={classes.cardItemsPlan}>
                    <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Box className={classes.description}>
                                <Typography color="primary" variant="h1" fontSize={28}>
                                    Plan
                                </Typography>
                                <Typography color="primary" variant="h1" fontSize={28}>
                                    {currentPlan.value === 1
                                        ? "Small"
                                        : currentPlan.value === 2
                                        ? "Medium"
                                        : "Enterprise"}
                                </Typography>
                                {origin === "addCompany" && (
                                    <Chip
                                        label={t("subscription.activation")}
                                        size="small"
                                        className={classes.chipCust}
                                        variant="filled"
                                    />
                                )}
                                {origin === "editCompany" && activePlan > checkCurrentPlanId() && (
                                    <Chip
                                        label={t("subscription.downgrade")}
                                        size="small"
                                        className={classes.chipCustCancel}
                                        variant="filled"
                                    />
                                )}
                                {origin === "editCompany" && activePlan < checkCurrentPlanId() && (
                                    <Chip
                                        label={t("subscription.upgrade")}
                                        size="small"
                                        className={classes.chipCust}
                                        variant="filled"
                                    />
                                )}
                                {currentPlan.type === "cancel" && (
                                    <Chip
                                        label={t("subscription.cancel")}
                                        size="small"
                                        className={classes.chipCustCancel}
                                        variant="filled"
                                    />
                                )}
                            </Box>
                            <Box className={classes.description2}>
                                <List>
                                    {planFeatures.map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                            <ListItemIcon sx={{ minWidth: 35 }}>
                                                {currentPlan.type === "cancel" ? (
                                                    <CloseIcon sx={{ color: "#FF5E77" }} fontSize="small" />
                                                ) : (
                                                    <CheckIcon sx={{ color: "#00B147" }} fontSize="small" />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={item} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Stack>
                        <IconButton aria-label="delete" onClick={deleteItem} sx={{ p: 0 }}>
                            <CancelIcon sx={{ color: "#d0d2d8" }} fontSize="large" />
                        </IconButton>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ShoppingItemsReg;

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Box,
    Divider,
    Button,
    Paper,
    Grid,
    Stack,
    IconButton,
} from "@mui/material";

/** Components */
import LightTooltip from "../ui/LightTooltip";

/** Icons */
import Visa from "../../assets/visa.svg";
import Mastercard from "../../assets/mastercard.svg";
import Amex from "../../assets/amex.svg";
import Discover from "../../assets/discover.svg";
import Diners from "../../assets/diners.svg";
import JCB from "../../assets/jcb.svg";
import UnionPay from "../../assets/unionpay.svg";

/** MUI Icons */
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

/** Styles */
import classes from "./AddCreditPacksDialog.module.css";
import { styled } from "@mui/material/styles";

/** Services */
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import payService from "../../services/payment_methods";
import companyService from "../../services/company";
import subscriptionService from "../../services/subscription";
import SimpleDialog from "../ui/SimpleDialog";

import moment from "moment";
import { addCredits, addSubscription } from "../../redux/userSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 50,
    },
    "& .MuiDialogTitle-root": {
        fontSize: 22,
        fontWeight: 600,
        color: "#4a22d4",
        padding: "30px 45px 5px 45px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(0),
    },
}));

const AddCreditPacksDialog = (props) => {
    const {
        open,
        onClose,
        getPaymentMethods,
        setShowAddPay,
        setShowChangePay,
        activePlan,
        paymentMethod,
        setIsLoading,
        currentPlan
    } = props;

    const todos = useSelector((state) => state.value);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component States */
    const [cartItems, setCartItems] = useState([]);
    const [payStatus, setPayStatus] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const sumTotal = cartItems.reduce((acumulador, elemento) => acumulador + elemento.line_amount, 0);

    const sumCredits = cartItems.reduce((acumulador, elemento) => acumulador + elemento.line_qty, 0);

    const sumPages = cartItems.reduce((acumulador, elemento) => acumulador + elemento.line_pages, 0);

    const plansInfo = [
        {
            planId: 1,
            planTitle: "Small",
            cost: 7.75,
            colorTop: "#484949",
            data: {
                credits: 5,
                users: 1,
                roles: true,
                storage: t("plans.unlimited"),
                training: false,
                support: t("plans.standard"),
                integrations: [],
                xeroDashboard: false,
            },
            customCredits: 1.8,
        },
        {
            planId: 2,
            planTitle: "Medium",
            cost: 19.75,
            colorTop: "#a35eff",
            data: {
                credits: 15,
                users: 2,
                roles: true,
                storage: t("plans.unlimited"),
                training: false,
                support: t("plans.standard"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
            },
            customCredits: 1.7,
        },
        {
            planId: 3,
            planTitle: "Enterprise",
            cost: 35.75,
            colorTop: "#ffab4c",
            data: {
                credits: 30,
                users: 3,
                roles: true,
                storage: t("plans.unlimited"),
                training: false,
                support: t("plans.standard"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
            },
            customCredits: 1.6,
        },
    ];

    const levels = [
        { planId: 1, value: 200, type: "small", price: 49 },
        { planId: 1, value: 300, type: "small", price: 79 },
        { planId: 1, value: 400, type: "small", price: 99 },
        { planId: 1, value: 500, type: "small", price: 129 },
        { planId: 2, value: 1000, type: "medium", price: 199 },
        { planId: 2, value: 1500, type: "medium", price: 299 },
        { planId: 2, value: 2000, type: "medium", price: 399 },
        { planId: 2, value: 2500, type: "medium", price: 499 },
        { planId: 3, value: 5000, type: "enterprise", price: 749 },
        { planId: 3, value: 10000, type: "enterprise", price: 1499 },
        { planId: 3, value: 15000, type: "enterprise", price: 2249 },
        { planId: 3, value: "custom", type: "enterprise" },
    ];

    const goToPlans = () => {
        navigate("/subscription");
    };

    const handleCloseModalCredits = () => {
        setOpenModal(false);
        resetCart();
        onClose();
    };

    const changePaymentMethod = (hasCard) => {
        onClose();
        if (hasCard) {
            setShowChangePay(true);
        } else {
            setShowAddPay(true);
        }
    };

    const deleteItem = async (item) => {
        let packsFilter = cartItems.filter(
            (pack) => pack.line_type !== item.line_type || pack.line_amount !== item.line_amount
        );

        const params = {
            franchise_id: todos.franchiseID,
            cart_lines: packsFilter,
        };

        setIsLoading(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setCartItems(packsFilter);
    };

    const handleAdd = async () => {
        let nextPlan
        if (currentPlan) {
            let currentPlanIndex = plansInfo.findIndex(p => p.planId === currentPlan);
            nextPlan = plansInfo[currentPlanIndex + 1] ? plansInfo[currentPlanIndex + 1] : plansInfo[plansInfo.length - 1];
        } else {
            nextPlan = plansInfo[0]; // Small plan
        }
        
        console.log(currentPlan)

        let currentLevel = levels.find((item) => item.planId === nextPlan.planId)

        const newVAl = {
            planType: nextPlan.planId,
            planData: nextPlan.data,
            subscription_plan: nextPlan.planId,
            planTitle: nextPlan.planTitle,
            line_type: 4,
            line_amount: currentLevel.price,
            line_unit_amount: currentLevel.price.toFixed(2),
            line_pages: currentLevel.value,
            line_qty: 1,
            line_since_date: moment().format(),
            line_end_date: moment().add(1, "M").format(),
        };

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [newVAl],
        };

        setIsLoading(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setCartItems([newVAl]);
    };

    const resetCart = async () => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [],
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        await subscriptionService
            .getFranchiseCredits({ franchise_id: todos.franchiseID })
            .then((response) => dispatch(addCredits(response)))
            .catch((err) => console.log(err));

        await companyService
            .getFranchiseDetails(todos.franchiseID)
            .then((data) => {
                dispatch(
                    addSubscription({
                        value: data.id_status,
                        last_value: data.id_last_status,
                        plan: data.subscription_plan,
                    })
                );
            })
            .catch((error) => {
                console.log(error);
            });

        setCartItems([]);

        setIsLoading(false);
    };

    const creditsContent = (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                {payStatus ? (
                    <CheckCircleIcon sx={{ fontSize: 70, color: "#00B147" }} />
                ) : (
                    <CancelIcon sx={{ fontSize: 70, color: "#ED1A1A" }} />
                )}
            </Grid>
            <Grid item>
                <Typography fontSize={20} fontWeight={700}>
                    {payStatus ? t("dialog.successPay") : t("dialog.errorPay")}
                </Typography>
                {payStatus ? (
                    <Typography fontSize={16}>
                        {`${sumCredits === 1 ? t("dialog.creditsNew1") : t("dialog.creditsNew")} `}
                        <b style={{ color: "#4a22d4" }}>{`${sumCredits} ${
                            sumCredits === 1 ? t("dialog.creditsText") : t("dialog.creditsText")
                        }`}</b>
                    </Typography>
                ) : (
                    <Typography fontSize={16}>{t("dialog.errorPayText")}</Typography>
                )}
            </Grid>
        </Grid>
    );

    const handlePayment = async () => {
        if (paymentMethod) {
            const params = {
                total_amount: parseInt(sumTotal.toFixed(2).toString().replace(".", "") ?? 0),
                franchise_id: todos.franchiseID,
                company_id: todos.companyID,
                customer_id: paymentMethod.customer_id,
                payment_id: paymentMethod.payment_id,
                payment_method_id: paymentMethod._id,
            };
            setIsLoading(true);

            await payService
                .chargeSavedCard(params)
                .then(async () => {
                    setPayStatus(true);
                })
                .catch((err) => {
                    console.log(err.response.data);
                    setPayStatus(false);
                    // Se envía un correo de pago manual fallido
                    payService.sendEmailFailedPayment(params);
                });
            setOpenModal(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getPaymentMethods();
        handleAdd()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todos.subscription]);

    return (
        <BootstrapDialog
            open={open}
            onClose={handleCloseModalCredits}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title" sx={{mx: "45px", mb: 2}}>
                <Typography fontSize={20} fontWeight={600}>
                    {activePlan ? t("credits.noCreditsPlan") : t("navBar.noCredits3")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box className={classes.spacing}>
                    <Paper
                        square={false}
                        sx={{ backgroundColor: activePlan ? "#FCE3063D" : "#00FFFF3D", mb: 4, mt: 1,  p: "20px 30px", borderRadius: "20px !important" }}
                    >
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12} md={8}>
                                <Typography color="primary" fontSize={18} fontWeight={600}>
                                    {activePlan ? t("credits.upPosibilities") : t("credits.subsNow")}
                                </Typography>
                                <Typography fontSize={15} mt={1}>
                                    {activePlan ? t("credits.subsNowMsg2") : t("credits.subsNowMsg")}
                                </Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "center", display: "flex", alignItems: "center" }}>
                                <Button variant="contained" color="primary" onClick={goToPlans} sx={{ px: 7, fontWeight: 500 }}>
                                    {t("credits.subsNowBtn")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
                <Box className={classes.resumeCard} sx={{ mb: 0, mt: 3 }}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={4} sx={{ p: "20px 0 20px 40px" }}>
                            <Typography fontStyle="normal" fontWeight={600} fontSize={24} color="#131F3E">
                                {t("credits.paymentMethods")}
                            </Typography>
                            {!paymentMethod?.card_number && <Button onClick={() => changePaymentMethod(paymentMethod?.card_number)}>
                                {"+ " + t("credits.add")}
                            </Button>}
                            {paymentMethod?.card_number && (
                                <Stack direction="row" alignItems="center" sx={{ mt: 2 }} spacing={2}>
                                    <Box
                                        component="img"
                                        src={
                                            paymentMethod.brand === "visa"
                                                ? Visa
                                                : paymentMethod.brand === "mastercard"
                                                ? Mastercard
                                                : paymentMethod.brand === "amex"
                                                ? Amex
                                                : paymentMethod.brand === "discover"
                                                ? Discover
                                                : paymentMethod.brand === "diners"
                                                ? Diners
                                                : paymentMethod.brand === "unionpay"
                                                ? UnionPay
                                                : JCB
                                        }
                                        sx={{ width: 45 }}
                                    />
                                    <Typography fontWeight={600}>
                                        {`${
                                            paymentMethod?.brand.charAt(0).toUpperCase() + paymentMethod?.brand.slice(1)
                                        } ${t("credits.endingIn")} *${paymentMethod?.card_number.split("-").pop()}`}
                                    </Typography>
                                    <Button onClick={() => changePaymentMethod(paymentMethod?.card_number)}>
                                        {t("credits.editOrChange")}
                                    </Button>
                                </Stack>
                            )}
                        </Grid>
                        <Grid item>
                            <Divider orientation="vertical" className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} md={7} sx={{ p: { xs: "20px 40px 20px 30px", md: "20px 40px 20px 0px" } }}>
                            <Typography
                                fontStyle="normal"
                                fontWeight={600}
                                fontSize={24}
                                sx={{ pb: 2 }}
                                color="#131F3E"
                            >
                                {t("subscription.orderSummary")}
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Box minWidth={500}>
                                    {cartItems.length > 0 && cartItems.map((item) => (
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            key={item.line_qty}
                                            spacing={2}
                                            sx={{ mb: 1 }}
                                            alignItems="end"
                                        >
                                            <Grid item>
                                                <Typography variant="h3">
                                                    Plan {item.planTitle}{" "} <Typography component="span" fontWeight={500}>- {item.line_pages} {t("register.pagesMonthly")}</Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Stack direction="row" spacing={1} alignItems="end">
                                                    <Typography variant="h3">
                                                        $
                                                        {parseFloat(item.line_amount).toLocaleString(
                                                            todos.amountFormat,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                        {item.subscription_plan && `/${t("plans.month")}`}
                                                    </Typography>
                                                    <IconButton onClick={() => deleteItem(item)} sx={{p: 0}}>
                                                        <DeleteIcon sx={{ fontSize: "18px", color: "#4A22D4" }} />
                                                    </IconButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Divider sx={{ pr: 2 }} />
                                    <Grid container justifyContent="space-between" spacing={1} sx={{ my: 1 }}>
                                        <Grid item>
                                            <Typography variant="h3">Total</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3" sx={{ pr: 2 }}>
                                                $
                                                {parseFloat(sumTotal).toLocaleString(todos.amountFormat, {
                                                    minimumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                        {cartItems.length > 0 && <Grid item xs={12}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography>{t("subscription.purchaseRec")}</Typography>
                                                <Typography color="primary">
                                                    <b>{sumPages}</b>{" "}{t("credits.newPages")}
                                                </Typography>
                                            </Stack>
                                        </Grid>}
                                    </Grid>
                                </Box>
                                <Box>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={!paymentMethod?.card_number || cartItems.length === 0}
                                        className={classes.buttonPurple}
                                        onClick={handlePayment}
                                    >
                                        {t("credits.pay")}
                                    </Button>
                                    <LightTooltip title={t("credits.creditValidity")}>
                                        <Stack direction="row" spacing={1} sx={{ mt: 1 }} alignItems="center">
                                            <InfoIcon color="primary" sx={{ fontSize: 15 }} />
                                            <Typography
                                                fontStyle="normal"
                                                fontWeight={500}
                                                fontSize={12}
                                                color={"#4A22D4"}
                                            >
                                                {t("credits.terms")}
                                            </Typography>
                                        </Stack>
                                    </LightTooltip>
                                    <Typography sx={{ maxWidth: "200px", fontSize: 12, textAlign: "justify", mt: 1, lineHeight: "normal", color: "#131F3E" }}>
                                        {t("credits.conditionWarning")}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <SimpleDialog
                open={openModal}
                handleClose={handleCloseModalCredits}
                maxWidth="sm"
                content={creditsContent}
            />
        </BootstrapDialog>
    );
};

export default AddCreditPacksDialog;

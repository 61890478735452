/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    ButtonGroup,
    Chip,
    ClickAwayListener,
    FormControl,
    Grow,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Popper,
    Select,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { CopyToClipboard } from "react-copy-to-clipboard";

/** MUI icons */
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckIcon from "@mui/icons-material/Check";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BiotechIcon from "@mui/icons-material/Biotech";
import FlagIcon from "@mui/icons-material/Flag";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from "@mui/icons-material/Cancel";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import LightTooltip from "../ui/LightTooltip";
import { useTranslation } from "react-i18next";
import casesServices from "../../services/case";
import commServices from "../../services/communication";
import { useDispatch, useSelector } from "react-redux";
import { addCases } from "../../redux/userSlice";
import { useRef, useState } from "react";

import TablePaginationActions from "../ui/TablePagActions";
import { initiateSocket, subscribeToChat, newUserResponse } from "../../services/Socket";
import ChatModal from "../ui/Chat";
import ChatIconCustom from "../../assets/chat-icon-inbox.svg";
import ChatIconCustomNotif from "../../assets/chat-icon-inbox-notif.svg";
import AlertDialog from "../ui/AlertDialog";
import SimpleDialog from "../ui/SimpleDialog";
import { useEffect } from "react";
import moment from "moment";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const BootstrapButton = styled(Button)({
    backgroundColor: "#01AC45",
    "&:hover": {
        backgroundColor: "#009A3E",
    },
});

const CustomPopover = styled(Popover)({
    ".MuiPopover-paper": {
        borderRadius: 20,
    },
});

const CustomIcon = styled(CancelIcon)({
    "&:hover": {
        color: "#FF007A",
    },
});

const OpenTableAdmin = (props) => {
    const {
        columns,
        mainUsers,
        devUsers,
        priorities,
        setIsLoading,
        user_id,
        company_id,
        franchise_id,
        setCases,
        setShow,
        setTexto,
        setAlertType,
        reloadCases,
        cases,
    } = props;

    const [t] = useTranslation("global");
    /* React redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const newColumns = [{ name: "flag", title: <></>, width: "2%" }, ...columns];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editGlobal, setEditGlobal] = useState(false);
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCase, setSelectedCase] = useState({});
    const [deletedID, setDeletedID] = useState("");
    const [showModalDel, setShowModalDel] = useState(false);
    const [copied, setCopied] = useState(false);
    const [caseInfo, setCaseInfo] = useState({});
    const [chatInfo, setChatInfo] = useState({});
    const [rowsOpen, setRowsOpen] = useState([]);
    const [casesOpenTable, setCasesOpenTable] = useState([]);
    const [openSelect, setOpenSelect] = useState(false);
    const [showDialogMultiCase, setShowDialogMultiCase] = useState(false);
    const [paramsTest, setParamsTest] = useState({});
    const [showModalFinishTest, setShowModalFinishTest] = useState(false);
    const [successConv, setSuccessConv] = useState(0);
    const [totalCases, setTotalCases] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedIndexTable, setSelectedIndexTable] = useState();

    const anchorRef = useRef(null);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsOpen.length) : 0;

    const openPop = Boolean(anchorEl);
    const id = openPop ? "simple-popover" : undefined;

    const convertTZ = (date, tzString) => {
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })
        );
    };

    const deleteCase = async () => {
        setIsLoading(true);
        await casesServices
            .deleteCase({ case_id: deletedID })
            .then(() => {
                reloadCases();
                setEditGlobal(false);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
        handleCloseModal();
    };

    const handleCloseDialog = () => {
        setShowDialogMultiCase(false);
    };

    const handleCloseModal = () => {
        setShowModalDel(false);
        setDeletedID("");
    };

    const handleClick = (event, val) => {
        setAnchorEl(event.currentTarget);
        setSelectedCase(val);
    };

    const handleClosePop = () => {
        setAnchorEl(null);
        setSelectedCase({});
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const status = [
        {
            name: t("inbox.analysis"),
            value: 1,
            color: "#FFB23F",
        },
        {
            name: t("inbox.progress"),
            value: 2,
            color: "#3FA7D6",
        },
        {
            name: t("inbox.test"),
            value: 3,
            color: "#005792",
        },
        {
            name: t("inbox.solvedKiiper"),
            value: 4,
            color: "#00C8C8",
        },
    ];

    const handleChangeSelect = (event, index) => {
        setCasesOpenTable(
            casesOpenTable.map((aux, index2) => {
                if (index === index2) {
                    return { ...aux, [event.target.name]: event.target.value };
                } else {
                    return aux;
                }
            })
        );
    };

    useEffect(() => {
        if (cases) {
            const newArrOpen = [];

            const openData = cases.open.map((item, index) => {
                const defaultUsers = {
                    index: index,
                    id: item.case_id,
                    mainUser: item.case_responsible ?? "",
                    devUser: item.case_developer_manager ?? "",
                    testUser: item.case_test_manager ?? "",
                    priority: item.priority ?? "",
                    edit: false,
                    status: item.case_status,
                    conversion_id: item?.conversion_id ?? ""
                };

                newArrOpen.push(defaultUsers);

                return {
                    index: index,
                    id: item.case_id,
                    global_case: item.global_case_number,
                    case: item.case_number,
                    conversion_id: item.conversion_id ? (
                        <LightTooltip title={item.conversion_id}>{item.conversion_id.slice(-6)}</LightTooltip>
                    ) : (
                        ""
                    ),
                    conversion_status: item.conversion_status,
                    creation_date: moment(convertTZ(item.creation_date, "America/New_York")).format(
                        `${todos.dateFormat} HH:mm`
                    ),
                    franchise: item.franchise_name,
                    company: item.company_name,
                    case_user: item.case_user,
                    case_user_email: item.case_user_email,
                    case_reason:
                        item.case_reason === 1
                            ? t("inbox.conversions")
                            : item.case_reason === 2
                                ? t("inbox.newBank")
                                : t("inbox.directRequest"),
                    reopen: item.reopen,
                    unread: item.unread,
                    origin: item.case_reason,
                    phone_code: item.phone_code,
                    phone_number: item.phone_number,
                    bank_name: item.bank_name ? item.bank_name : "",
                    bank_currency: item.bank_currency ? item.bank_currency : "",
                    bank_country: item.bank_country ? item.bank_country : "",
                };
            });

            setCasesOpenTable(newArrOpen);
            setRowsOpen(openData);
        }
    }, [cases, t, todos.dateFormat]);

    const handleCancelAction = async () => {
        setShowDialogMultiCase(false);
        setIsLoading(true);

        await casesServices
            .assignAdminCase(paramsTest)
            .then(async () => {
                let params2 = {
                    user_id: user_id,
                    company_id: company_id,
                    franchise_id: franchise_id,
                };

                setAlertType("success");
                setTexto(t("inbox.successEdit"));
                setShow(true);

                await casesServices
                    .getAdminCases(params2)
                    .then((data) => {
                        setCases(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    }

    const handleCloseModalTest = () => {
        setShowModalFinishTest(false);
        setTotalCases(0);
        setSuccessConv(0);
    }

    const handleOpenModalTest = () => {
        setShowModalFinishTest(true);
    }

    const handleConfirmMultiTest = async () => {
        paramsTest.multi = true;

        setShowDialogMultiCase(false);
        setIsLoading(true);

        await casesServices
            .assignAdminCase(paramsTest)
            .then(async (response) => {
                let params2 = {
                    user_id: user_id,
                    company_id: company_id,
                    franchise_id: franchise_id,
                };

                // console.log("response", response);

                setTotalCases(response?.data?.total_cases);
                setSuccessConv(response?.data?.successful_cases);

                await casesServices
                    .getAdminCases(params2)
                    .then((data) => {
                        setCases(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                handleOpenModalTest();
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    }

    const handleEdit = async (index, action) => {
        if (action === "edit" && editGlobal === false) {
            setEditGlobal(true);
            setCasesOpenTable(
                casesOpenTable.map((aux, index2) => {
                    if (index === index2) {
                        return { ...aux, edit: !aux.edit };
                    } else {
                        return aux;
                    }
                })
            );
        } else if (action === "edit" && editGlobal === true) {
            setAlertType("warning");
            setTexto(t("inbox.activeEdition"));
            setShow(true);
        } else if (action === "save") {
            setIsLoading(true);

            const params = {
                case_id: casesOpenTable[index].id,
                case_responsible: casesOpenTable[index].mainUser,
                dev_manager: casesOpenTable[index].devUser,
                test_manager: casesOpenTable[index].testUser,
                priority: casesOpenTable[index].priority,
                case_status: casesOpenTable[index].status,
                lang: t("language.locale"),
            };

            setParamsTest(params);

            if (casesOpenTable[index].status === 3 && casesOpenTable[index].conversion_id) {
                await casesServices
                    .verifyCases({
                        case_id: casesOpenTable[index].id
                    })
                    .then(async (response) => {
                        // console.log("response", response);

                        if (response.length > 0) {
                            // Si hay más de un caso del mismo banco se pregunta al usuario si quiere probarlos todos al mismo tiempo.
                            setShowDialogMultiCase(true);
                        } else {
                            await casesServices
                                .assignAdminCase(params)
                                .then(async () => {
                                    let params2 = {
                                        user_id: user_id,
                                        company_id: company_id,
                                        franchise_id: franchise_id,
                                    };

                                    setAlertType("success");
                                    setTexto(t("inbox.successEdit"));
                                    setShow(true);

                                    await casesServices
                                        .getAdminCases(params2)
                                        .then((data) => {
                                            setCases(data);
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                    })
            } else {
                await casesServices
                    .assignAdminCase(params)
                    .then(async () => {
                        let params2 = {
                            user_id: user_id,
                            company_id: company_id,
                            franchise_id: franchise_id,
                        };

                        setAlertType("success");
                        setTexto(t("inbox.successEdit"));
                        setShow(true);

                        await casesServices
                            .getAdminCases(params2)
                            .then((data) => {
                                setCases(data);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            setIsLoading(false);
            setEditGlobal(false);
        }
    };

    const handleToggle = (index) => {
        setOpenSelect((prevOpen) => !prevOpen);
        setSelectedIndexTable(index);
    };

    const handleArrowClick = (value, index) => {
        let statusFin = 1;
        if (value < status.length) {
            statusFin = value + 1;
        }
        setSelectedIndexTable(index);
        setSelectedIndex(statusFin - 1);

        setCasesOpenTable(
            casesOpenTable.map((aux, index2) => {
                if (index === index2) {
                    return { ...aux, status: statusFin };
                } else {
                    return aux;
                }
            })
        );
    };

    const handleMenuItemClick = (value, index) => {
        setSelectedIndex(index);
        setOpenSelect(false);

        setCasesOpenTable(
            casesOpenTable.map((aux, index2) => {
                if (selectedIndexTable === index2) {
                    return { ...aux, status: value };
                } else {
                    return aux;
                }
            })
        );
    };

    const handleClosePopOver = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenSelect(false);
    };

    const handleClose = async (id, index) => {
        setIsLoading(true);

        const params = {
            case_id: id,
            case_responsible: casesOpenTable[index].mainUser,
            dev_manager: casesOpenTable[index].devUser,
            test_manager: casesOpenTable[index].testUser,
            priority: casesOpenTable[index].priority,
            case_status: casesOpenTable[index].status,
            admin: true,
        };

        await casesServices
            .markCaseAsResolved(params)
            .then(async () => {
                let params2 = {
                    user_id: user_id,
                    company_id: company_id,
                    franchise_id: franchise_id,
                };

                await casesServices
                    .getAdminCases(params2)
                    .then((data) => {
                        setCases(data);
                        dispatch(addCases(data.finalCount));
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
        setEditGlobal(false);
    };

    const checkIconDown = (props) => {
        if (props.className.includes("MuiSelect-iconOpen")) {
            return (
                <PlayArrowIcon
                    sx={{
                        position: "absolute",
                        transform: "rotate(270deg)",
                        color: "#131F3E",
                        right: ".5rem",
                        cursor: "pointer",
                        zIndex: 0,
                        pointerEvents: "none",
                    }}
                />
            );
        }
        return (
            <PlayArrowIcon
                sx={{
                    position: "absolute",
                    transform: "rotate(90deg)",
                    color: "#131F3E",
                    right: ".5rem",
                    cursor: "pointer",
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            />
        );
    };

    const connectSocket = async (case_id, reason, convID) => {
        setIsLoading(true);
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await casesServices
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo);
                setChatInfo(data.chatCase);

                initiateSocket(
                    case_id,
                    todos.userInfo,
                    reason === 1 && convID ? "conversion" : "general",
                    ""
                );
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
                await commServices
                    .updateStatusMess({
                        room: case_id,
                        user: todos.userInfo._id,
                    })
                    .then()
                    .catch((err) => console.log(err));
                reloadCases();
                setEditGlobal(false);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const showModal = (id) => {
        setDeletedID(id);
        setShowModalDel(true);
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };

    return (
        <>
            <TableContainer>
                <Table
                    sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1400 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <StyledTableRow>
                            {newColumns.map((item) => (
                                <StyledTableCell
                                    key={item.name}
                                    align={item.align ?? "left"}
                                    style={{ width: item.width }}
                                >
                                    {item.title}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? rowsOpen.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rowsOpen
                        ).map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell sx={{ paddingRight: "0px !important" }}>
                                    {row.reopen && <FlagIcon fontSize="small" sx={{ color: "#FF007A" }} />}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Button onClick={(event) => handleClick(event, row)} size="small">
                                        {row.global_case}
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>{row.franchise}</StyledTableCell>
                                <StyledTableCell align="center">{row.creation_date}</StyledTableCell>
                                <StyledTableCell>
                                    {casesOpenTable[row.index].edit ? (
                                        <ButtonGroup ref={anchorRef} aria-label="Button group with a nested menu">
                                            <Button
                                                aria-controls={openSelect ? "split-button-menu" : undefined}
                                                aria-expanded={openSelect ? "true" : undefined}
                                                aria-haspopup="menu"
                                                onClick={() => handleToggle(row.index)}
                                                size="small"
                                                sx={{
                                                    width: 146,
                                                    height: 42,
                                                    borderRadius: 0,
                                                    border: "none",
                                                    color:
                                                        casesOpenTable[row.index]?.status === 1
                                                            ? "#FFB23F"
                                                            : casesOpenTable[row.index]?.status === 2
                                                                ? "#3FA7D6"
                                                                : casesOpenTable[row.index]?.status === 3
                                                                    ? "#005792"
                                                                    : casesOpenTable[row.index]?.status === 4
                                                                        ? "#00C8C8"
                                                                        : "#00B147",
                                                    backgroundColor: "#F8F8F9",
                                                    fontSize: 14,
                                                    "&:hover": {
                                                        border: "none",
                                                    },
                                                }}
                                            >
                                                {status[casesOpenTable[row.index]?.status - 1].name}
                                            </Button>
                                            <Button
                                                size="small"
                                                onClick={() =>
                                                    handleArrowClick(casesOpenTable[row.index].status, row.index)
                                                }
                                                sx={{
                                                    backgroundColor: "#F8F8F9",
                                                    height: 42,
                                                    borderRadius: 0,
                                                    border: "none",
                                                    padding: 0,
                                                    minWidth: "24px !important",
                                                    marginLeft: "4px !important",
                                                    "&:hover": {
                                                        border: "none",
                                                    },
                                                }}
                                            >
                                                <PlayArrowIcon fontSize="small" sx={{ color: "#9399A7" }} />
                                            </Button>
                                        </ButtonGroup>
                                    ) : casesOpenTable[row.index]?.status === 1 ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#FFB23F">{t("inbox.analysis")}</Typography>
                                            <BarChartIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                        </Stack>
                                    ) : casesOpenTable[row.index]?.status === 2 ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#3FA7D6">{t("inbox.progress")}</Typography>
                                            <EngineeringIcon sx={{ color: "#3FA7D6" }} fontSize="inherit" />
                                        </Stack>
                                    ) : casesOpenTable[row.index]?.status === 3 ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#005792">{t("inbox.test")}</Typography>
                                            <BiotechIcon sx={{ color: "#005792" }} fontSize="inherit" />
                                        </Stack>
                                    ) : casesOpenTable[row.index]?.status === 4 ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#00C8C8">{t("inbox.solvedKiiper")}</Typography>
                                            <CheckIcon sx={{ color: "#00C8C8" }} fontSize="inherit" />
                                        </Stack>
                                    ) : (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#00B147">{t("inbox.solvedUser")}</Typography>
                                            <CheckIcon sx={{ color: "#00B147" }} fontSize="inherit" />
                                        </Stack>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {casesOpenTable[row.index].edit ? (
                                        <FormControl variant="standard" fullWidth>
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="mainUser"
                                                value={casesOpenTable[row.index]?.mainUser ?? ""}
                                                onChange={(event) => handleChangeSelect(event, row.index)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                IconComponent={(props) => checkIconDown(props)}
                                            >
                                                <MenuItem value="">{t("inbox.assign")}</MenuItem>
                                                {mainUsers.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        mainUsers.find((item) => item.value === casesOpenTable[row.index]?.mainUser)
                                            ?.name ?? ""
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {casesOpenTable[row.index].edit ? (
                                        <FormControl variant="standard" fullWidth>
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="devUser"
                                                value={casesOpenTable[row.index]?.devUser ?? ""}
                                                onChange={(event) => handleChangeSelect(event, row.index)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                IconComponent={(props) => checkIconDown(props)}
                                            >
                                                <MenuItem value="">{t("inbox.assign")}</MenuItem>
                                                {devUsers.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        devUsers.find((item) => item.value === casesOpenTable[row.index]?.devUser)
                                            ?.name ?? ""
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {casesOpenTable[row.index].edit ? (
                                        <FormControl variant="standard" fullWidth>
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="testUser"
                                                value={casesOpenTable[row.index]?.testUser ?? ""}
                                                onChange={(event) => handleChangeSelect(event, row.index)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                IconComponent={(props) => checkIconDown(props)}
                                            >
                                                <MenuItem value="">{t("inbox.assign")}</MenuItem>
                                                {mainUsers.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        mainUsers.find((item) => item.value === casesOpenTable[row.index]?.testUser)
                                            ?.name ?? ""
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {casesOpenTable[row.index].edit ? (
                                        <FormControl variant="standard" fullWidth>
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="priority"
                                                value={casesOpenTable[row.index]?.priority ?? ""}
                                                onChange={(event) => handleChangeSelect(event, row.index)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                IconComponent={(props) => checkIconDown(props)}
                                            >
                                                <MenuItem value="">{t("inbox.assign")}</MenuItem>
                                                {priorities.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        <Typography color={item.color}>{item.name}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography
                                            color={
                                                priorities.find(
                                                    (item) => item.value === casesOpenTable[row.index]?.priority
                                                )?.color
                                            }
                                        >
                                            {priorities.find(
                                                (item) => item.value === casesOpenTable[row.index]?.priority
                                            )?.name ?? ""}
                                        </Typography>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.actions ?? (
                                        <Stack direction="row" spacing={1}>
                                            {!casesOpenTable[row.index].edit ? (
                                                <LightTooltip title={t("team.edit")}>
                                                    <IconButton
                                                        disabled={editGlobal && !casesOpenTable[row.index].edit}
                                                        onClick={() => handleEdit(row.index, "edit")}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            ) : (
                                                <LightTooltip title={t("team.save")}>
                                                    <IconButton onClick={() => handleEdit(row.index, "save")}>
                                                        <SaveAsIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            )}
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                endIcon={
                                                    <Box
                                                        component="img"
                                                        src={row.unread > 0 ? ChatIconCustomNotif : ChatIconCustom}
                                                        sx={{ width: "15px", height: "15px" }}
                                                    />
                                                }
                                                onClick={() => connectSocket(row.id, row.origin, row.conversion_id)}
                                                sx={{ whiteSpace: "nowrap", width: { lg: 132 } }}
                                            >
                                                Chat
                                            </Button>
                                            <BootstrapButton
                                                variant="contained"
                                                disableElevation
                                                onClick={() => handleClose(row.id, row.index)}
                                                size="small"
                                                disabled={casesOpenTable[row.index]?.status !== 4}
                                                sx={{ width: { lg: 132 } }}
                                            >
                                                {t("inbox.close")}
                                            </BootstrapButton>
                                            <IconButton onClick={() => showModal(row.id)}>
                                                <CustomIcon />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, { label: t("miscellaneous.all"), value: -1 }]}
                                colSpan={12}
                                count={rowsOpen.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rowsPerPage",
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage={t("dashboard.rowsPerPage")}
                                labelDisplayedRows={(page) =>
                                    `${page.from}-${page.to === -1 ? page.count : page.to} ${t("team.unlinkText2")} ${page.count
                                    }`
                                }
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={openSelect}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopOver}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {status.map((option, index) => (
                                        <MenuItem
                                            key={option.value}
                                            selected={index === selectedIndex}
                                            onClick={() => handleMenuItemClick(option.value, index)}
                                        >
                                            <ListItemText
                                                sx={{
                                                    color:
                                                        option.value === 1
                                                            ? "#FFB23F"
                                                            : option.value === 2
                                                                ? "#3FA7D6"
                                                                : option.value === 3
                                                                    ? "#005792"
                                                                    : option.value === 4
                                                                        ? "#00C8C8"
                                                                        : "#00B147",
                                                }}
                                            >
                                                {option.name}
                                            </ListItemText>
                                            <ListItemIcon>
                                                {option.value === 1 ? (
                                                    <BarChartIcon sx={{ color: "#FFB23F" }} fontSize="small" />
                                                ) : option.value === 2 ? (
                                                    <EngineeringIcon sx={{ color: "#3FA7D6" }} fontSize="small" />
                                                ) : option.value === 3 ? (
                                                    <BiotechIcon sx={{ color: "#005792" }} fontSize="small" />
                                                ) : option.value === 4 ? (
                                                    <CheckIcon sx={{ color: "#00C8C8" }} fontSize="small" />
                                                ) : (
                                                    <CheckIcon sx={{ color: "#00B147" }} fontSize="small" />
                                                )}
                                            </ListItemIcon>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <CustomPopover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClosePop}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{ borderRadius: 20 }}
            >
                <Box sx={{ padding: "10px 30px", backgroundColor: "#6544DB" }}>
                    <Typography variant="h2" sx={{ color: "white", pb: 1 }}>
                        {selectedCase.company}
                    </Typography>
                    <Typography sx={{ color: "white", fontSize: 12 }}>
                        {t("inbox.origin")}: {selectedCase.case_reason}
                    </Typography>
                    <Typography sx={{ color: "white", fontSize: 12 }}>
                        {t("inbox.interID")}: {selectedCase.case}
                    </Typography>
                    {selectedCase.origin === 1 && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography sx={{ color: "white", fontSize: 12 }}>
                                {t("inbox.convID")}: {selectedCase.conversion_id}
                            </Typography>
                            <Chip
                                label={
                                    selectedCase.conversion_status === 1
                                        ? t("converter.inProc")
                                        : selectedCase.conversion_status === 2
                                            ? t("converter.convertible")
                                            : selectedCase.conversion_status === 3
                                                ? t("converter.successful")
                                                : selectedCase.conversion_status === 4
                                                    ? t("converter.inReview")
                                                    : t("converter.invalid")
                                }
                                color={
                                    selectedCase.conversion_status === 3 || selectedCase.conversion_status === 2
                                        ? "success"
                                        : selectedCase.conversion_status === 4 || selectedCase.conversion_status === 1
                                            ? "warning"
                                            : "error"
                                }
                                size="small"
                                sx={{ color: "white", fontSize: 12 }}
                            />
                        </Stack>
                    )}
                    {selectedCase.origin === 1 && (
                        <Typography sx={{ color: "white", fontSize: 12 }}>
                            {selectedCase.bank_name} - {selectedCase.bank_country} - {selectedCase.bank_currency}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ padding: "10px 30px" }}>
                    <Typography variant="h2" color="primary" sx={{ pb: 1 }}>
                        {selectedCase.case_user}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={0.5} display="flex" sx={{ pb: 1 }}>
                        <Typography sx={{ textDecoration: "underline" }}>{selectedCase.case_user_email}</Typography>
                        <CopyToClipboard text={selectedCase.case_user_email} onCopy={() => handleCopy()}>
                            <div>
                                <LightTooltip title={copied ? t("inbox.copied") : t("inbox.copy")} position="bottom">
                                    <FileCopyIcon fontSize="inherit" sx={{ cursor: "pointer" }} />
                                </LightTooltip>
                            </div>
                        </CopyToClipboard>
                    </Stack>
                    <Typography sx={{ pb: 1 }}>
                        {selectedCase.phone_code} {selectedCase.phone_number}
                    </Typography>
                </Box>
            </CustomPopover>
            <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                users={users}
                caseInfo={caseInfo}
                setCaseInfo={setCaseInfo}
                reloadCases={reloadCases}
                chatInfo={chatInfo}
                setChatInfo={setChatInfo}
                connectSocket={connectSocket}
                params={3}
            />
            <AlertDialog
                open={showModalDel}
                onClose={handleCloseModal}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("team.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={
                    <Box sx={{ textAlign: "center" }}>
                        <Typography>{t("inbox.sureDel1")}</Typography>
                        <Typography>{t("inbox.sureDel2")}</Typography>
                    </Box>
                }
                agreeAction={deleteCase}
                maxWidth="xs"
            />
            <AlertDialog
                open={showDialogMultiCase}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        handleCloseDialog();
                    } else {
                        handleCancelAction();
                    }
                }}
                agreeBtnLabel={t("dialog.yes")}
                disagreeBtnLabel={t("dialog.no")}
                type={"warning"}
                title={t("dialog.youWishTest")}
                message={
                    <Box sx={{ textAlign: "center" }}>
                        <Typography>{t("dialog.youWishTestMsg")}</Typography>
                        <Typography>{t("dialog.youWishTestMsg1")}</Typography>
                    </Box>
                }
                agreeAction={handleConfirmMultiTest}
                maxWidth="xs"
            />
            <SimpleDialog
                open={showModalFinishTest}
                handleClose={handleCloseModalTest}
                maxWidth="sm"
                content={
                    <Box sx={{ px: 4 }}>
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ pb: 2 }} spacing={2}>
                            <Typography variant="h2" color="primary">
                                {t("dialog.finalTest")}
                            </Typography>
                        </Stack>
                        <Typography sx={{ pb: 2 }}>
                            {t("dialog.finalTestMsg")}{" "}
                            <Typography component="span" sx={{ fontWeight: "bold" }}>{totalCases}</Typography>{" "}
                            {t("dialog.finalTestMsg1")}{" "}
                            <Typography component="span" sx={{ fontWeight: "bold" }}>{successConv}</Typography>{" "}
                            {t("dialog.finalTestMsg2")}
                        </Typography>
                        <Typography sx={{ pb: 2 }}>{t("dialog.finalTestMsg3")}</Typography>
                        <Button onClick={handleCloseModalTest}>
                            {t("register.back")}
                        </Button>
                    </Box>
                }
            />
        </>
    );
};

export default OpenTableAdmin;

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { addCompanyID } from "../../../redux/userSlice"

/** MUI Imports */
import { Box, Stack, Typography } from "@mui/material";
import LightTooltip from "../../ui/LightTooltip";

/** MUI Icons */
import KiiperCoin from "../../../assets/kiiper-coin.svg";

/** Styles */
import classes from "../NavBar.module.css";

const CreditsComponent = (props) => {
    const { credits, mobile, handleDrawerToggle, rol, setSelectedCompany } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [creditsColor, setCreditsColor] = useState("rgba(151, 71, 255, 0.15)");

    var userEmail = "";
    if (todos) {
        if (todos.userInfo) {
            if (todos.userInfo.email) {
                userEmail = todos.userInfo.email;
            }
        }
    }

    useEffect(() => {
        if (credits >= 1) {
            setCreditsColor("rgba(151, 71, 255, 0.15)");
        }

        if (credits === 0) {
            setCreditsColor("rgba(255, 0, 122, 0.15)");
        }
    }, [credits]);

    const handleClick = () => {
        if (rol !== 3) {
            if (mobile) {
                handleDrawerToggle();
            }

            if (!todos.companyID) {
                const newComp = todos.allComData.filter((company) => !company.franchise_name)[0];
                dispatch(addCompanyID(newComp._id));
                setSelectedCompany(newComp);
            }

            navigate("/subscription", {
                state: {
                    tab: userEmail !== "support@kiiper.app" ? 2 : 1
                }
            });
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: creditsColor,
            }}
            className={mobile ? classes.mobileCredits : classes.credits}
            onClick={handleClick}
        >
            <LightTooltip title={`${t("navBar.have")} ${credits} ${t("navBar.availableCredits")}`}>
                <Stack direction="row" pr={4} pl={1} justifyContent="center" alignItems="center" spacing={4}>
                    <Box
                        component="img"
                        sx={{
                            height: 18,
                            width: 18,
                            maxHeight: { xs: 18, md: 18 },
                            maxWidth: { xs: 18, md: 18 },
                        }}
                        src={KiiperCoin}
                    />
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            color: "#131F3E",
                            fontSize: "19px",
                            textAlign: "center",
                            //ml: 3,
                        }}
                    >
                        {credits}
                    </Typography>
                </Stack>
            </LightTooltip>
        </Box>
    );
};

export default CreditsComponent;

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    List,
    ListItem,
    Popover,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

/** MUI icons */
import { useTranslation } from "react-i18next";
import { useState } from "react";

import TablePaginationActions from "../ui/TablePagActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import companyService from "../../services/user_company";
import { useNavigate } from "react-router-dom";
import FiltersButtonCompany from "./FiltersButtonCompany";
import classes from "../team/TeamList.module.css";

// const defaultFilter = {
//     filtersArray: [
//         {
//             type: "",
//             value: "",
//         },
//     ],
// };

const StyledTableRowB = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "#f8f8f9",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        company: <Skeleton variant="text" animation="wave" />,
        integ: <Skeleton variant="text" animation="wave" />,
        users: <Skeleton variant="text" animation="wave" />,
        plans: <Skeleton variant="text" animation="wave" />,
    });
}

const CompaniesTable = (props) => {
    const { setOpenModalNewCompany, setAddNew, reload, setCompanyDetails, setOpenCompanyDet, setIsLoading, setAllCompanies, allCompanies } = props;

    const [t] = useTranslation("global");
    /* React redux */
    const todos = useSelector((state) => state.value);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [rows, setRows] = useState(rowsSkeleton);
    const [rowsOG, setRowsOG] = useState([]);
    const [filters, setFilters] = useState({
        filtersArray: [
            {
                type: "",
                value: "",
            },
        ],
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [usersData, setUsersData] = useState([]);

    const allPlans = [
        "Micro",
        `Small - 200 ${t("plans.pageMonth")}`,
        `Small - 300 ${t("plans.pageMonth")}`,
        `Small - 400 ${t("plans.pageMonth")}`,
        `Small - 500 ${t("plans.pageMonth")}`,
        `Medium - 1000 ${t("plans.pageMonth")}`,
        `Medium - 1500 ${t("plans.pageMonth")}`,
        `Medium - 2000 ${t("plans.pageMonth")}`,
        `Medium - 2500 ${t("plans.pageMonth")}`,
        `Enterprise - 5000 ${t("plans.pageMonth")}`,
        `Enterprise - 10000 ${t("plans.pageMonth")}`,
        `Enterprise - 15000 ${t("plans.pageMonth")}`,
        t("plans.customize"),
    ];

    const rolNames = [
        { id: 1, name: t("team.owner") },
        { id: 2, name: t("team.admin") },
        { id: 3, name: t("team.colab") },
    ];

    const columns = [
        { name: "company", title: t("dashboard.company"), minWidth: 160 },
        { name: "integ", title: t("subscription.integ") },
        { name: "users", title: t("management.assignUsers") },
        { name: "plans", title: t("management.activePlan") },
    ];

    const handleNewCompany = () => {
        setOpenModalNewCompany(true);
        setAddNew(false);
    };

    useEffect(() => {
        (async () => {
            const handleOpenCompany = async (companyID) => {
                setIsLoading(true);
                await companyService
                    .getBusinessDirectoryDetails({ company_id: companyID })
                    .then((data) => {
                        setCompanyDetails(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                setOpenCompanyDet(true);
                setIsLoading(false);
            };

            const params = {
                franchise_id: todos.franchiseID,
            };

            await companyService
                .getBusinessDirectory(params)
                .then((data) => {
                    const companies = [];

                    const notAssingData = data.map((item) => {
                        let itemPlan = item.subscription_plan === 14 // Sin plan.
                            ? t("management.noPlan")
                            : allPlans[item.subscription_plan] ?? t("plans.customize");
                        const planName = item.subscription_plan === 14 ? itemPlan : `Plan ${itemPlan}`;

                        companies.push({
                            value: item._id,
                            name: item.company_name,
                        });

                        return {
                            id: item._id,
                            company_name: item.company_name,
                            company: (
                                <Button size="small" onClick={() => handleOpenCompany(item._id)}>
                                    {item.company_name}
                                </Button>
                            ),
                            integ:
                                item.system_integration === 1
                                    ? "Xero"
                                    : item.system_integration === 2
                                        ? "Quickbooks"
                                        : t("miscellaneous.withoutInt"),
                            users: item.total_users,
                            usersData: item.users,
                            plans: planName,
                        };
                    });

                    setAllCompanies(companies);
                    setRows(notAssingData);
                    setRowsOG(notAssingData);
                })
                .catch((err) => {
                    console.log(err);
                });

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, todos.dateFormat, reload, todos.franchiseID, navigate, dispatch, setCompanyDetails]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);

        const filterCases = rowsOG.filter(
            (item) =>
                item.company_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.plans.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.integ.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setRows(filterCases);
    };

    const handleShowUsers = (event, usersData) => {
        setUsersData(usersData);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h1" sx={{ pb: 1, pt: 2, px: { xs: 1, md: 5 } }}>
                        {t("miscellaneous.companyDir")}
                    </Typography>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ pb: 2, pt: 2, px: { xs: 1, md: 5 } }}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                disableElevation
                                endIcon={<AddIcon />}
                                onClick={handleNewCompany}
                            >
                                {t("navBar.newCompany")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <FiltersButtonCompany
                                        franchiseID={todos.franchiseID}
                                        filters={filters}
                                        setFilters={setFilters}
                                        setRows={setRows}
                                        setRowsOG={setRowsOG}
                                        rowsSkeleton={rowsSkeleton}
                                        allCompanies={allCompanies}
                                        allPlans={allPlans}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="filled"
                                        hiddenLabel
                                        placeholder="Buscar..."
                                        value={searchValue}
                                        onChange={handleChangeSearchValue}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box
                        component="main"
                        sx={{
                            px: { xs: 2, md: 5 },
                            pb: 4,
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table
                                        size="small"
                                        sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1400 }}
                                    >
                                        <TableHead>
                                            <StyledTableRowB>
                                                {columns.map((item) => (
                                                    <StyledTableCell
                                                        key={item.name}
                                                        align={item.align ?? "left"}
                                                        style={{ minWidth: item.minWidth }}
                                                    >
                                                        {item.title}
                                                    </StyledTableCell>
                                                ))}
                                            </StyledTableRowB>
                                        </TableHead>
                                        <TableBody>
                                            {(rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                            ).map((row) => (
                                                <StyledTableRow
                                                    key={row.id}
                                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                >
                                                    <StyledTableCell>{row.company}</StyledTableCell>
                                                    <StyledTableCell>{row.integ}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <Button
                                                            aria-describedby={id}
                                                            disabled={row.users === 0}
                                                            onClick={(e) => handleShowUsers(e, row.usersData)}
                                                        >
                                                            {row.users}
                                                        </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <b>{row.plans}</b>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <StyledTableRowB>
                                                <TablePagination
                                                    rowsPerPageOptions={[
                                                        10,
                                                        20,
                                                        30,
                                                        { label: t("miscellaneous.all"), value: -1 },
                                                    ]}
                                                    colSpan={12}
                                                    count={rows.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rowsPerPage",
                                                        },
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                    labelRowsPerPage={t("dashboard.rowsPerPage")}
                                                    labelDisplayedRows={(page) =>
                                                        `${page.from}-${page.to === -1 ? page.count : page.to} ${t(
                                                            "team.unlinkText2"
                                                        )} ${page.count}`
                                                    }
                                                />
                                            </StyledTableRowB>
                                        </TableFooter>
                                    </Table>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        sx={{
                                            "& .MuiPopover-paper": {
                                                borderRadius: 3,
                                            },
                                        }}
                                    >
                                        <Box p={4}>
                                            <Typography color="primary" variant="h1" gutterBottom>
                                                {t("management.assignUsers")}
                                            </Typography>
                                            <List>
                                                {usersData.map((item) => (
                                                    <ListItem
                                                        key={item.user_id._id}
                                                        sx={{ backgroundColor: "#f8f8f9", borderRadius: 3, py: 1.5 }}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            spacing={6}
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            width="100%"
                                                        >
                                                            <Typography>{item.user_id.user_name}</Typography>
                                                            <Box
                                                                className={
                                                                    item.user_permission === 1
                                                                        ? classes.owner
                                                                        : item.user_permission === 2
                                                                            ? classes.admin
                                                                            : classes.colab
                                                                }
                                                                sx={{ width: "130px", textAlign: "center" }}
                                                            >
                                                                <Typography variant="h3">
                                                                    {
                                                                        rolNames.find(
                                                                            (x) => x.id === item.user_permission
                                                                        )?.name
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Box>
                                    </Popover>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default CompaniesTable;

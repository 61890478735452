import { Box, Button, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import EastIcon from "@mui/icons-material/East";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Visa from "../../../assets/visa.svg";
import Mastercard from "../../../assets/mastercard.svg";
import Amex from "../../../assets/amex.svg";
import Discover from "../../../assets/discover.svg";
import Diners from "../../../assets/diners.svg";
import JCB from "../../../assets/jcb.svg";
import UnionPay from "../../../assets/unionpay.svg";

import LightTooltip from "../../ui/LightTooltip";
import AlertDialog from "../../ui/AlertDialog";
import AddPaymentDialog from "../../payments/AddPaymentDialog";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import payService from "../../../services/payment_methods";
import companyService from "../../../services/company";
import moment from "moment";

/* Estilos */
import classes from "./ShoppingItems.module.css";
import BaseSnackbar from "../../ui/BaseSnackbar";
import SimpleBackdrop from "../../ui/SimpleBackdrop";
import FormDialog from "../../ui/FormDialog";
import CancelPlanForm from "../CancelPlanForm";
import { useDispatch, useSelector } from "react-redux";
import subscriptionService from "../../../services/subscription";
import { addCredits, addSubscription } from "../../../redux/userSlice";
import DowngradePlanForm from "../DowngradePlanForm";
import userCompanyService from "../../../services/user_company";
import SimpleDialog from "../../ui/SimpleDialog";
import ChangePayDialog from "./ChangePayDialog";

const ShoppingSummary = (props) => {
    const {
        cartItems,
        currentPlanInfo,
        setCartItems,
        setShoppingCartOpen,
        setCurrentPlanInfo,
        setTextoPlan,
        setAlertTypePlan,
        setShowPlan,
        plansInfo,
        payments,
        company_id,
        franchise_id,
        setActivePlan,
        setReload,
        reloadData,
        franchiseDetails,
    } = props;

    const [t, i18n] = useTranslation("global");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const selectedPlan = currentPlanInfo
        ? plansInfo.find((item) => item.planId === currentPlanInfo.subscription_plan)
        : null;

    const defaultCard = payments.find((item) => item.default_method);

    const todos = useSelector((state) => state.value);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertCancel, setOpenAlertCancel] = useState(false);
    const [showAddPay, setShowAddPay] = useState(false);
    const [reasons, setReasons] = useState(null);
    const [details, setDetails] = useState("");
    const [isError, setIsError] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openAlertDown, setOpenAlertDown] = useState(false);
    const [openFormDown, setOpenFormDown] = useState(false);
    const [usersFranq, setUsersFranq] = useState([]);
    const [invFranq, setInvFranq] = useState([]);
    const [checked, setChecked] = useState([]);
    const [checked2, setChecked2] = useState([]);
    const [showChangePayment, setShowChangePayment] = useState(false);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));
    const [payStatus, setPayStatus] = useState(false);
    const [openOndemand, setOpenOndemand] = useState(false);
    const [termsLink, setTermsLink] = useState(
        sessionStorage.getItem("lng") === "es"
            ? "https://kiiper.app/es/terms-and-conditions"
            : "https://kiiper.app/terms-and-conditions/"
    );
    // const [payStatusMessage, setPayStatusMessage] = useState("");

    const sumTotal = cartItems.reduce(
        (acumulador, elemento) =>
            acumulador + (elemento.line_type === 3 && elemento.subscription_plan ? 0 : elemento.line_amount),
        0
    );

    const sumCredits = cartItems.reduce(
        (acumulador, elemento) =>
            acumulador +
            (elemento.line_type === 7 ||
            elemento.line_type === 8 ||
            (elemento.subscription_plan && elemento.line_type === 3)
                ? 0
                : elemento.subscription_plan
                ? elemento.planData.credits
                : elemento.line_qty),
        0
    );

    // Verificamos si es un Upgrade para restar la diferencia
    const isUpgrade = cartItems.find((element) => element.line_type === 4 && element.subscription_plan);
    let amountToRest = 0;
    let creditsToRest = 0;

    // Obtenemos la fecha de vencimiento de las conversiones
    const getDueDate = moment.utc(currentPlanInfo?.since_date).format(todos.dateFormat);

    if (isUpgrade && selectedPlan) {
        amountToRest = selectedPlan.cost;
        creditsToRest = selectedPlan.data.credits;
    }

    // Verificamos si es un Downgrade
    const isDowngrade = cartItems.find((element) => element.line_type === 3 && element.subscription_plan);

    // Verificamos si es un Cancelación
    const isCancel = cartItems.find((element) => element.line_type === 7 && element.subscription_plan);

    const handleCloseModalPay = () => {
        setShowChangePayment(false);
    };

    const handleCloseModalCredits = () => {
        // Create a new location object without the state
        const newLocation = { ...location, state: null };

        // Navigate to the new location without triggering a full-page refresh
        navigate(newLocation, { replace: true });
        
        setOpenModal(false);
        if (payStatus) {
            resetCart();
        }
    };

    const handlePayment = async () => {
        const val = cartItems.find((element) => element.fixed === true);

        if (val) {
            setOpenAlert(true);
        } else {
            if (defaultCard) {
                const params = {
                    total_amount: parseInt((sumTotal - amountToRest).toFixed(2).toString().replace(".", "") ?? 0),
                    franchise_id: todos.franchiseID,
                    company_id: todos.companyID,
                    customer_id: defaultCard.customer_id,
                    payment_id: defaultCard.payment_id,
                    payment_method_id: defaultCard._id,
                };
                setIsLoading(true);

                await payService
                    .chargeSavedCard(params)
                    .then(async () => {
                        if (isUpgrade) {
                            await finalUpgrade();
                        }
                        setPayStatus(true);
                    })
                    .catch((err) => {
                        console.log(err.response.data.message);
                        // setPayStatusMessage(err.response.data.message);
                        setPayStatus(false);
                        // Se envía un correo de pago manual fallido
                        payService.sendEmailFailedPayment(params);
                    });
                setOpenModal(true);
                setIsLoading(false);
            } else {
                navigate("/payments", {
                    state: {
                        price: sumTotal - amountToRest,
                        credits: sumCredits - creditsToRest,
                    },
                });
            }
        }
    };

    const handleCloseDialog = () => {
        setOpenAlert(false);
        setOpenAlertCancel(false);
        setReasons(null);
        setDetails("");
        setIsError(false);
    };

    const cancelPlan = () => {
        setOpenAlertCancel(true);
    };

    const resetCart = async () => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [],
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        await subscriptionService
            .getFranchiseCredits({ franchise_id: todos.franchiseID })
            .then((response) => dispatch(addCredits(response)))
            .catch((err) => console.log(err));

        await companyService
            .getFranchiseDetails(todos.franchiseID)
            .then((data) => {
                dispatch(
                    addSubscription({
                        value: data.id_status,
                        last_value: data.id_last_status,
                        plan: data.subscription_plan,
                    })
                );
            })
            .catch((error) => {
                console.log(error);
            });

        setReload((prevState) => !prevState);
        setShoppingCartOpen(false);
        setCartItems([]);
        handleCloseModalPay();
        setIsLoading(false);
    };

    const reactivatePlan = async () => {
        //Existe un plan activo
        if (currentPlanInfo && Object.keys(currentPlanInfo).length > 0) {
            if (currentPlanInfo?.last_cancel !== undefined) {
                setIsLoading(true);

                let params = {
                    subscription_id: currentPlanInfo._id,
                };

                await subscriptionService
                    .reactivateSubscription(params)
                    .then(async () => {
                        const params = {
                            franchise_id: todos.franchiseID,
                            company_id: todos.companyID,
                            cart_lines: [],
                        };

                        await subscriptionService
                            .updateCart(params)
                            .then()
                            .catch((error) => {
                                console.log(error);
                            });

                        await subscriptionService
                            .getSubscription(params)
                            .then((response) => {
                                if (response) {
                                    setCurrentPlanInfo(response);
                                    setActivePlan(response.subscription_plan);
                                } else {
                                    setCurrentPlanInfo(null);
                                }
                            })
                            .catch((err) => console.log(err));
                        setCartItems([]);
                        setOpenAlertCancel(false);
                        setAlertTypePlan("success");
                        setTextoPlan(t("subscription.reactivateSuccess"));
                        setShowPlan(true);
                        setShoppingCartOpen(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setMessage("Error");
                        setAlertType("error");
                        setShow(true);
                    });
                setIsLoading(false);
            } else if (currentPlanInfo.last_change !== undefined) {
                setIsLoading(true);

                let params = {
                    subscription_id: currentPlanInfo._id,
                };

                await subscriptionService
                    .reactivateSubscriptionChanged(params)
                    .then(async () => {
                        const params = {
                            franchise_id: todos.franchiseID,
                            company_id: todos.companyID,
                            cart_lines: [],
                        };

                        await subscriptionService
                            .updateCart(params)
                            .then()
                            .catch((error) => {
                                console.log(error);
                            });

                        await subscriptionService
                            .getSubscription(params)
                            .then((response) => {
                                if (response) {
                                    setCurrentPlanInfo(response);
                                    setActivePlan(response.subscription_plan);
                                } else {
                                    setCurrentPlanInfo(null);
                                }
                            })
                            .catch((err) => console.log(err));
                        setCartItems([]);
                        setOpenAlertCancel(false);
                        setAlertTypePlan("success");
                        setTextoPlan(t("subscription.reactivateSuccess"));
                        setShowPlan(true);
                        setShoppingCartOpen(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setMessage("Error");
                        setAlertType("error");
                        setShow(true);
                    });
                setIsLoading(false);
            }
        } else {
            //Mostrar mensaje que no tiene plan activo
            console.log("sin plan");
        }
    };

    const finalCancel = async () => {
        if (!reasons) {
            setIsError(true);
        } else {
            if (reasons === "6" && details.trim() === "") {
                setIsError(true);
            } else {
                //Existe un plan activo
                if (currentPlanInfo && Object.keys(currentPlanInfo).length > 0) {
                    setIsLoading(true);

                    let params = {
                        subscription_id: currentPlanInfo._id,
                        reason: reasons,
                        description: details,
                    };

                    await subscriptionService
                        .cancelSubscription(params)
                        .then(async () => {
                            setOpenAlertCancel(false);
                            const params = {
                                franchise_id: todos.franchiseID,
                                company_id: todos.companyID,
                                cart_lines: [],
                            };

                            await subscriptionService
                                .updateCart(params)
                                .then()
                                .catch((error) => {
                                    console.log(error);
                                });

                            await subscriptionService
                                .getSubscription(params)
                                .then((response) => {
                                    if (response) {
                                        setCurrentPlanInfo(response);
                                    } else {
                                        setCurrentPlanInfo(null);
                                    }
                                })
                                .catch((err) => console.log(err));
                            setCartItems([]);
                            setAlertTypePlan("success");
                            setTextoPlan(t("subscription.cancelSuccess"));
                            setShowPlan(true);
                            setShoppingCartOpen(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setMessage("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                    setIsLoading(false);
                } else {
                    //Mostrar mensaje que no tiene plan activo
                    console.log("sin plan");
                }
            }
        }
    };

    const finalPay = async () => {
        if (defaultCard) {
            const params = {
                total_amount: parseInt((sumTotal - amountToRest).toFixed(2).toString().replace(".", "") ?? 0),
                franchise_id: todos.franchiseID,
                company_id: todos.companyID,
                customer_id: defaultCard.customer_id,
                payment_id: defaultCard.payment_id,
                payment_method_id: defaultCard._id,
            };

            setIsLoading(true);

            await payService
                .chargeSavedCard(params)
                .then(async () => {
                    if (isUpgrade) {
                        await finalUpgrade();
                    }
                    setPayStatus(true);
                })
                .catch((err) => {
                    setPayStatus(true);
                    console.log(err.response.data.error);
                });
            setOpenModal(true);
            setIsLoading(false);
        } else {
            navigate("/payments", {
                state: {
                    price: sumTotal - amountToRest,
                    credits: sumCredits - creditsToRest,
                },
            });
        }
        handleCloseDialog();
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
        setShowChangePayment(false);
    };

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            if (defaultCard) {
                setShowChangePayment(true);
            }
            reloadData();
        }
    };

    /** Component functions */
    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const bodyMess = (
        <Grid container justifyContent="center" spacing={2} className={classes.textCenter}>
            <Grid item>
                <Typography fontSize={14}>{t("dialog.pinnedPack")}</Typography>
            </Grid>
            <Grid item>
                <Typography fontSize={14}>
                    {t("dialog.pinnedPack2")}
                    <b>{getDueDate}</b>
                    {t("dialog.pinnedPack3")}
                </Typography>
            </Grid>
            <Grid item>
                <Typography fontSize={14}>{t("dialog.warningConfirm2")}</Typography>
            </Grid>
        </Grid>
    );

    const cancelPlanButtons = (
        <Grid container justifyContent="space-between" sx={{ width: 600 }}>
            <Grid item>
                <Button onClick={handleCloseDialog}>{t("team.cancel")}</Button>
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={finalCancel}>
                    {t("subscription.confirm")}
                </Button>
            </Grid>
        </Grid>
    );

    const bodyMessDown = (
        <Grid container justifyContent="center" spacing={2} className={classes.textCenter}>
            <Grid item>
                <Typography fontSize={14}>
                    {t("subscription.downgradeMsg")}{" "}
                    <Typography fontWeight={600} component="span" fontSize={14}>
                        "{t("subscription.mySubs")}"
                    </Typography>
                </Typography>
            </Grid>
            <Grid item mb={3}>
                <Typography fontSize={14}>{t("dialog.warningConfirm2")}</Typography>
            </Grid>
        </Grid>
    );

    const bodyMessOndemand = (
        <Grid container justifyContent="center" spacing={2} className={classes.textCenter}>
            <Grid item>
                <Typography fontSize={14}>{t("subscription.ondemandMsg")}</Typography>
            </Grid>
            <Grid item mb={3}>
                <Typography fontSize={14}>{t("dialog.warningConfirm2")}</Typography>
            </Grid>
        </Grid>
    );

    const handleCloseDialogDown = () => {
        setOpenAlertDown(false);
    };

    const handleDowngrade = () => {
        setOpenAlertDown(true);
    };

    const handleOndemand = () => {
        setOpenOndemand(true);
    };

    const handleCloseDialogOndemand = () => {
        setOpenOndemand(false);
    };

    const handleAgreeOndemand = () => {
        handleCloseDialogOndemand();
        handlePayment();
    };

    const confirmDowngrade = async () => {
        setIsLoading(true);
        //Llamar a peticion para obtener los usuarios de la franquicia y pintarlos en radio buttons
        let params = {
            franchise_id: franchise_id,
            company_id: company_id,
            all: true,
        };
        await userCompanyService
            .getAllUsersFranchise(params)
            .then(async (data) => {
                if (
                    data.users.length + data.inv.length >
                        cartItems.find((item) => item.line_type === 3).planData.users &&
                    cartItems.find((item) => item.line_type === 3).subscription_plan !== 1
                ) {
                    setUsersFranq(data.users);
                    setInvFranq(data.inv);
                    setOpenAlertDown(false);
                    setOpenFormDown(true);
                } else {
                    setOpenAlertDown(false);
                    //finalDowngrade();

                    let params = {};
                    if (
                        data.users.length + data.inv.length >
                            cartItems.find((item) => item.line_type === 3).planData.users &&
                        cartItems.find((item) => item.line_type === 3).subscription_plan === 1
                    ) {
                        //El plan a hacer downgrade es el 5, todos los usuarios e invitaciones se vuelven excedentes
                        params = {
                            subscription_id: currentPlanInfo._id,
                            type: 2, //downgrade
                            excess_user:
                                data.users.filter((item) => item.permission !== 1).length > 0
                                    ? data.users.filter((item) => item.permission !== 1).map((item) => item._id)
                                    : undefined,
                            excess_inv:
                                data.inv.filter((item) => item.permission !== 1).length > 0
                                    ? data.inv.filter((item) => item.permission !== 1).map((item) => item._id)
                                    : undefined,
                            changed_plan: cartItems.find((item) => item.line_type === 3).subscription_plan,
                            franchise_id: franchise_id,
                        };
                    } else {
                        params = {
                            subscription_id: currentPlanInfo._id,
                            type: 2, //downgrade
                            excess_user: checked.length > 0 ? checked : undefined,
                            excess_inv: checked2.length > 0 ? checked2 : undefined,
                            changed_plan: cartItems.find((item) => item.line_type === 3).subscription_plan,
                            franchise_id: franchise_id,
                        };
                    }

                    await subscriptionService
                        .changeSubscription(params)
                        .then(async () => {
                            setOpenFormDown(false);
                            setCartItems([]);
                            setAlertTypePlan("success");
                            setTextoPlan(t("subscription.downgradePlanSucess"));
                            setShowPlan(true);
                            setShoppingCartOpen(false);
                            resetCart();
                        })
                        .catch((error) => {
                            console.log(error);
                            setMessage("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
                setMessage("Error");
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    const handleCloseFormDown = () => {
        setOpenFormDown(false);
        setChecked([]);
    };

    const finalDowngrade = async () => {
        setIsLoading(true);

        let params = {
            subscription_id: currentPlanInfo._id,
            type: 2, //downgrade
            excess_user: checked.length > 0 ? checked : undefined,
            excess_inv: checked2.length > 0 ? checked2 : undefined,
            changed_plan: cartItems.find((item) => item.line_type === 3).subscription_plan,
            franchise_id: franchise_id,
        };

        await subscriptionService
            .changeSubscription(params)
            .then(async () => {
                setOpenFormDown(false);
                setCartItems([]);
                setAlertTypePlan("success");
                setTextoPlan(t("subscription.downgradePlanSucess"));
                setShowPlan(true);
                setShoppingCartOpen(false);
                resetCart();
            })
            .catch((error) => {
                console.log(error);
                setMessage("Error");
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    const changePayment = () => {
        setShowChangePayment(true);
    };

    const finalUpgrade = async () => {
        setIsLoading(true);

        let params = {
            subscription_id: currentPlanInfo._id,
            type: 3, //upgrade
            excess_user: undefined,
            excess_inv: undefined,
            changed_plan: cartItems.find((item) => item.line_type === 4).subscription_plan,
        };

        await subscriptionService
            .changeSubscription(params)
            .then(async () => {
                setOpenFormDown(false);
                setCartItems([]);
                setAlertTypePlan("success");
                setTextoPlan(t("subscription.upgradePlanSucess"));
                setShowPlan(true);
                setShoppingCartOpen(false);
                resetCart();
            })
            .catch((error) => {
                console.log(error);
                setMessage("Error");
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
            setTermsLink(
                i18n.language === "es"
                    ? "https://kiiper.app/es/terms-and-conditions"
                    : "https://kiiper.app/terms-and-conditions/"
            );
        }
    }, [i18n.language]);

    const downgradePlanButtons = (
        <Grid container justifyContent="space-between" sx={{ width: 600 }}>
            <Grid item>
                <Button onClick={handleCloseFormDown}>{t("team.cancel")}</Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    onClick={finalDowngrade}
                    disabled={
                        checked.length + checked2.length <
                            usersFranq.length +
                                invFranq.length -
                                cartItems.find((item) => item.line_type === 3)?.planData.users ?? false
                    }
                >
                    {t("subscription.remove")}
                </Button>
            </Grid>
        </Grid>
    );

    const creditsContent = (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                {payStatus ? (
                    <CheckCircleIcon sx={{ fontSize: 70, color: "#00B147" }} />
                ) : (
                    <CancelIcon sx={{ fontSize: 70, color: "#ED1A1A" }} />
                )}
            </Grid>
            <Grid item>
                <Typography fontSize={20} fontWeight={700}>
                    {payStatus ? t("dialog.successPay") : t("dialog.errorPay")}
                </Typography>
                {payStatus ? (
                    <Typography fontSize={16}>
                        {language === "es" ? (
                            <span>
                                {`${
                                    sumCredits - creditsToRest === 1 ? t("dialog.creditsNew1") : t("dialog.creditsNew")
                                } `}
                                <b style={{ color: "#4a22d4" }}>{`${sumCredits - creditsToRest} ${
                                    sumCredits - creditsToRest === 1
                                        ? t("dialog.creditsText1")
                                        : t("dialog.creditsText")
                                }`}</b>
                            </span>
                        ) : (
                            <span>
                                <b style={{ color: "#4a22d4" }}>{`${sumCredits - creditsToRest} ${
                                    sumCredits - creditsToRest === 1
                                        ? t("dialog.newConversions")
                                        : t("dialog.newConversions1")
                                }`}</b>
                            </span>
                        )}
                    </Typography>
                ) : (
                    <Typography fontSize={16}>{t("dialog.errorPayText")}</Typography>
                )}
            </Grid>
        </Grid>
    );

    return (
        <>
            {cartItems.map((item) => (
                <Box key={item.line_qty}>
                    <Grid container justifyContent="space-between" spacing={2} sx={{ mb: 1 }}>
                        <Grid item>
                            {item.subscription_plan ? (
                                <>
                                    <Typography variant="h3">
                                        {item.line_type === 7
                                            ? t("subscription.cancelSubs")
                                            : item.line_type === 8
                                            ? t("subscription.reactivateSubs")
                                            : item.line_type === 4 || item.line_type === 3
                                            ? t("subscription.subsChange")
                                            : t("subscription.activeSubs")}
                                    </Typography>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        {selectedPlan && item.line_type !== 7 && item.line_type !== 8 && (
                                            <>
                                                <Typography>Plan {selectedPlan.data.credits}</Typography>
                                                <EastIcon fontSize="small" color="primary" />
                                            </>
                                        )}
                                        <Typography>Plan {item.planType}</Typography>
                                    </Stack>
                                </>
                            ) : (
                                <>
                                    <Typography variant="h3">
                                        {t("subscription.packageOf")} {item.line_qty}{" "}
                                        {item.line_qty === 1 ? t("credits.conversion") : t("credits.credits")}
                                    </Typography>
                                    <Typography color="primary">
                                        +{item.line_qty}{" "}
                                        {item.line_qty === 1 ? t("subscription.newCred1") : t("subscription.newCred")}
                                    </Typography>
                                </>
                            )}
                        </Grid>
                        <Grid item>
                            <Typography fontSize={15}>
                                $
                                {item.line_type === 3
                                    ? parseFloat(0).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      })
                                    : parseFloat(item.line_amount).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      })}
                                {(item.subscription_plan || item.fixed) && `/${t("plans.month")}`}
                            </Typography>
                        </Grid>
                    </Grid>
                    {item.subscription_plan && item.line_type === 4 && (
                        <Grid container justifyContent="space-between" key={item.line_qty} spacing={2} sx={{ mb: 1 }}>
                            <Grid item>
                                <Typography variant="h3">{t("subscription.billAmount")}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h3" color="error">
                                    $-
                                    {parseFloat(selectedPlan?.cost).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                    {(item.subscription_plan || item.fixed) && `/${t("plans.month")}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            ))}
            {isDowngrade && (
                <Stack direction="row" spacing={1} alignItems="center" sx={{ pb: 1 }}>
                    <InfoIcon fontSize="small" color="primary" />
                    <Typography fontSize={12}>
                        {`${t("subscription.sinceOf")} `}
                        <b>{getDueDate}</b>
                        {` ${t("subscription.startBill")} $${parseFloat(isDowngrade.line_amount).toLocaleString(
                            todos.amountFormat,
                            {
                                minimumFractionDigits: 2,
                            }
                        )} ${t("subscription.monthPlan")} ${isDowngrade.planType}`}
                    </Typography>
                </Stack>
            )}
            {isCancel && (
                <Stack direction="row" spacing={1} alignItems="center" sx={{ pb: 1 }}>
                    <InfoIcon fontSize="small" color="primary" />
                    <Typography fontSize={12}>
                        {t("plans.leftCreditsCancel")} <b>{getDueDate}</b>
                    </Typography>
                </Stack>
            )}
            <Divider />
            <Grid container justifyContent="space-between" spacing={1} sx={{ my: 1 }}>
                <Grid item>
                    <Typography variant="h3">Total</Typography>
                </Grid>
                <Grid item>
                    <Typography fontSize={15}>
                        $
                        {parseFloat(sumTotal - amountToRest).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })}
                    </Typography>
                </Grid>
                {sumCredits > 0 && (
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>{t("subscription.purchaseRec")}</Typography>
                            <Typography color="primary">
                                <b>{sumCredits - creditsToRest}</b>{" "}
                                {sumCredits - creditsToRest === 1
                                    ? t("subscription.newCred1")
                                    : t("subscription.newCred")}
                            </Typography>
                        </Stack>
                    </Grid>
                )}
            </Grid>
            <Divider sx={{ pt: 1 }} />
            <Grid container justifyContent="center" spacing={2} sx={{ my: 0.1 }}>
                {sumCredits > 0 && (
                    <>
                        {!defaultCard ? (
                            <Grid item xs={12}>
                                <Button color="primary" size="small" onClick={handleAddPaymentM}>
                                    {t("subscription.addPayMethod")}
                                </Button>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Typography variant="h1">{t("subscription.payMethod")}</Typography>
                                        <Button color="primary" size="small" onClick={changePayment}>
                                            {t("subscription.editOrAdd")}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Box
                                            component="img"
                                            src={
                                                defaultCard.brand === "visa"
                                                    ? Visa
                                                    : defaultCard.brand === "mastercard"
                                                    ? Mastercard
                                                    : defaultCard.brand === "amex"
                                                    ? Amex
                                                    : defaultCard.brand === "discover"
                                                    ? Discover
                                                    : defaultCard.brand === "diners"
                                                    ? Diners
                                                    : defaultCard.brand === "unionpay"
                                                    ? UnionPay
                                                    : JCB
                                            }
                                            sx={{ width: 45 }}
                                        />
                                        <Typography variant="h3">
                                            {defaultCard.brand.charAt(0).toUpperCase() + defaultCard.brand.slice(1)}{" "}
                                            {t("credits.endingIn")} *{defaultCard.card_number.split("-").pop()}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </>
                        )}
                    </>
                )}
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={cartItems.length === 0 || (!defaultCard && sumCredits > 0)}
                        onClick={
                            sumCredits > 0
                                ? franchiseDetails.id_status === 2 &&
                                  cartItems.filter((item) => item.line_type === 1).length > 0
                                    ? handleOndemand
                                    : handlePayment
                                : cartItems.filter((item) => item.line_type === 3).length > 0
                                ? handleDowngrade
                                : cartItems.filter((item) => item.line_type === 7).length > 0
                                ? cancelPlan
                                : reactivatePlan
                        }
                    >
                        {sumCredits > 0 ? t("credits.pay") : t("subscription.confirm")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                        <LightTooltip
                            title={
                                <>
                                    <Typography fontSize={12}>
                                        <span style={{ color: "#0FF" }}>{t("credits.extraCred")}</span>{" "}
                                        {t("credits.extraCredText")}
                                    </Typography>
                                    <br />
                                    <Typography fontSize={12}>
                                        {t("credits.toolWant")}{" "}
                                        <span style={{ color: "#0FF" }}>{t("credits.increasePlan")}</span>{" "}
                                        {t("credits.increasePlanText")}
                                    </Typography>
                                    <br />
                                    <Typography fontSize={12}>
                                        {t("credits.toolWant")}{" "}
                                        <span style={{ color: "#0FF" }}>{t("credits.reducePlan")}</span>{" "}
                                        {t("credits.reducePlanText")}
                                    </Typography>
                                </>
                            }
                        >
                            <InfoIcon fontSize="small" color="primary" />
                        </LightTooltip>
                        <Typography fontSize={12}>
                            {t("subscription.termsAccept")}{" "}
                            <Typography
                                color="primary"
                                fontSize={12}
                                component={Link}
                                sx={{ cursor: "pointer", textDecoration: "none" }}
                                href={termsLink}
                                target="_blank"
                            >
                                {t("subscription.termsAndCon")}
                            </Typography>
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMess}
                agreeAction={finalPay}
                maxWidth="sm"
            />
            <AlertDialog
                open={openAlertDown}
                onClose={handleCloseDialogDown}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMessDown}
                agreeAction={confirmDowngrade}
                maxWidth="sm"
            />
            <AlertDialog
                open={openOndemand}
                onClose={handleCloseDialogOndemand}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMessOndemand}
                agreeAction={handleAgreeOndemand}
                maxWidth="sm"
            />
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setMessage}
                    setAlertType={setAlertType}
                    setIsLoading={setIsLoading}
                />
            )}
            <BaseSnackbar type={alertType} show={show} message={message} onClose={handleCloseSnack} />
            <SimpleBackdrop open={isLoading} />
            <FormDialog
                open={openAlertCancel}
                handleClose={handleCloseDialog}
                maxWidth="md"
                title={
                    <>
                        <span style={{ color: "#4A22D4" }}>{todos.userInfo.user_name}</span>, {t("plans.regretDes")}
                    </>
                }
                content={
                    <CancelPlanForm
                        reasons={reasons}
                        setReasons={setReasons}
                        details={details}
                        setDetails={setDetails}
                        isError={isError}
                        currentPlanInfo={currentPlanInfo}
                    />
                }
                actions={cancelPlanButtons}
                custom={true}
            />
            <FormDialog
                open={openFormDown}
                handleClose={handleCloseFormDown}
                maxWidth="md"
                title={
                    <>
                        <span style={{ color: "#4A22D4", fontSize: "16px" }}>
                            {language === "en"
                                ? usersFranq.length +
                                  invFranq.length -
                                  cartItems?.find((item) => item.line_type === 3)?.planData?.users +
                                  " " +
                                  t(
                                      usersFranq.length +
                                          invFranq.length -
                                          cartItems?.find((item) => item.line_type === 3)?.planData?.users ===
                                          1
                                          ? "subscription.downgradeFormMsg1"
                                          : "subscription.downgradeFormMsg1B"
                                  )
                                : t("subscription.downgradeFormMsg1") +
                                  " " +
                                  (usersFranq.length +
                                      invFranq.length -
                                      cartItems?.find((item) => item.line_type === 3)?.planData?.users) +
                                  " " +
                                  t(
                                      usersFranq.length +
                                          invFranq.length -
                                          cartItems?.find((item) => item.line_type === 3)?.planData?.users ===
                                          1
                                          ? "subscription.downgradeFormMsgExtra1"
                                          : "subscription.downgradeFormMsgExtra"
                                  )}
                        </span>
                    </>
                }
                content={
                    <DowngradePlanForm
                        downgradePlan={cartItems.find((item) => item.line_type === 3)}
                        usersFranq={usersFranq}
                        checked={checked}
                        setChecked={setChecked}
                        getDueDate={getDueDate}
                        invFranq={invFranq}
                        checked2={checked2}
                        setChecked2={setChecked2}
                    />
                }
                actions={downgradePlanButtons}
                custom={true}
            />
            <SimpleDialog
                open={openModal}
                handleClose={handleCloseModalCredits}
                maxWidth="sm"
                content={creditsContent}
            />
            {showChangePayment && (
                <ChangePayDialog
                    open={showChangePayment}
                    handleClose={handleCloseModalPay}
                    payments={payments}
                    setIsLoading={setIsLoading}
                    reloadData={reloadData}
                    defaultCard={defaultCard}
                    handleAddPaymentM={handleAddPaymentM}
                />
            )}
        </>
    );
};

export default ShoppingSummary;

/** React imports */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** React-Redux */
import { useSelector, useDispatch } from "react-redux";
import {
    addUserInfo,
    addAuth,
    addPermissions,
    addDateFormat,
    addAmountFormat,
    addTutorial,
    addCompanyID,
    addFranchiseID,
} from "../../../redux/userSlice";

/** Country flags */
import Flag from "react-world-flags";

/* Styles */
import classes from "./UserRegister.module.css";
import plansInfo from "../../Js/plans";

/* MUI Imports */
import {
    Typography,
    Grid,
    Divider,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Button,
    InputAdornment,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Box,
    styled,
    FilledInput,
    FormControl,
    Link,
    Stepper,
    Step,
    StepLabel,
    Stack,
    FormControlLabel,
    Checkbox,
    Container,
} from "@mui/material";
import GridUI from "@mui/material/Grid";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import InfoIcon from "@mui/icons-material/Info";

/* MUI Icons */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

/** Components imports */
import countriesProject from "../../Js/countries";
import BaseSnackbar from "../../ui/BaseSnackbar";
import SimpleBackdrop from "../../ui/SimpleBackdrop";
import DialogChangeEmail from "../../user/DialogChangeEmail";
import DialogSaveNewEmail from "../../user/DialogSaveNewEmail";
import CustomFilledSelect from "../../ui/CustomFilledSelect";
import businessActivity from "../../Js/businessActivity";
import LightTooltip from "../../ui/LightTooltip";
import AlertDialog from "../../ui/AlertDialog";

/* Services */
import authService from "../../../services/auth";
import userService from "../../../services/user";
import userConfigService from "../../../services/user_config";
import subscriptionService from "../../../services/subscription";
import ITTeam from "../../Js/ITTeam";
import Integrations from "../../subscription/Integrations";
import companyService from "../../../services/company";
import PlanSubs from "../../subscription/plans/PlansMain";
import ShoppingCartReg from "../../subscription/shoppingCart/ShoppingCartReg";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const UserRegister = (props) => {
    const {
        editMode,
        userEmail,
        handleShowRegister,
        independentUser,
        invitation,
        language,
        invitationId,
        isLoginRedirect,
    } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    /* RegExp */
    let uppercaseLetter = /[A-Z]/;
    let lowercaseLetter = /[a-z]/;
    let requiredNumber = /\d/;
    let requiredSymbol = /[,.#\-@$!?*/+]/;

    /** Countries */
    const allCountries = [...countriesProject];
    allCountries.sort((a, b) => {
        if (sessionStorage.getItem("lng") === "en" || language === "en") {
            return a.name_en.localeCompare(b.name_en);
        } else {
            return a.name_es.localeCompare(b.name_es);
        }
    });

    /* Component states */
    const [isError, setIsError] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [counter, setCounter] = useState(120);
    const [checkTandC, setCheckTandC] = useState(false);
    const [termsLink, setTermsLink] = useState(
        sessionStorage.getItem("lng") === "es" || language === "es"
            ? "https://kiiper.app/es/terms-and-conditions"
            : "https://kiiper.app/terms-and-conditions/"
    );

    const [userData, setUserData] = useState({
        fullName: "",
        role: "",
        tools: [],
        origin: "",
        country: "",
        email: "",
        practice_name: "",
        practice_size: "",
        company_type: "",
        business_activity: "",
        company_name: "",
        company_country_name: "",
        subscription_type: "",
    });

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    const [countryCode, setCountryCode] = useState("");

    const [password, setPassword] = useState({
        password: "",
        repeatPassword: "",
        showPassword: false,
        showRepeatPassword: false,
    });
    const [securityLevel, setSecurityLevel] = useState(0);
    const [validateSecurityLevel, setValidateSecurityLevel] = useState({
        uppercaseLetter: false,
        lowercaseLetter: false,
        requiredNumber: false,
        requiredSymbol: false,
        eightCharacters: false,
    });
    const [colorSecurityLevel, setColorSecurityLevel] = useState({
        firstLevel: "rgba(19, 31, 62, 0.05)",
        secondLevel: "rgba(19, 31, 62, 0.05)",
        thirdLevel: "rgba(19, 31, 62, 0.05)",
        fourthLevel: "rgba(19, 31, 62, 0.05)",
        fifthLevel: "rgba(19, 31, 62, 0.05)",
    });

    /* Estados para edición de perfil de usuario */
    const [errorEditMode, setErrorEditMode] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
    const [confirChangeEmail, setConfirmChangeEmail] = useState(false);
    const [updatePassword, setUpdatePassword] = useState({
        currentPassword: "",
        newPassword: "",
        repeatPassword: "",
        showCurrentPassword: false,
        showNewPassword: false,
        showRepeatNewPassword: false,
    });
    const [confirmPassword, setConfirmPassword] = useState({
        password: "",
        repeatPassword: "",
        showPassword: false,
        showRepeatPassword: false,
    });
    const [errorPassword, setErrorPassword] = useState(false);
    const [existingEmail, setExistingEmail] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [paramsChangeMail, setParamsChangeMail] = useState({});
    const [compareUserData, setCompareUserData] = useState({
        fullName: "",
        country: "",
        email: "",
        phoneCode: "",
        phoneNumber: "",
    });
    const [activeStep, setActiveStep] = useState(isLoginRedirect ? 2 : 0);
    const [inheritData, setInheritData] = useState(false);
    const [smbInfo, setSmbInfo] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [integ, setInteg] = useState(3);
    const [openAlert, setOpenAlert] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [bodyMess, setBodyMess] = useState("");
    const [agreeBtnMsg, setAgreeBtnMsg] = useState(t("dialog.continue"));
    const [disagreeBtnMsg, setDisagreeBtnMsg] = useState(t("dialog.cancel"));
    const [questionCustom, setQuestionCustom] = useState({
        question1: "",
        question2: "",
        question3: "",
    });
    const [currentPlan, setCurrentPlan] = useState({
        value: 1,
        custom: false,
    });
    const [paymentType, setPaymentType] = useState("monthly");
    const [pagesMonthly, setPagesMonthly] = useState("");
    const [priceMonthly, setPriceMonthly] = useState("");
    const [priceMonthlyOG, setPriceMonthlyOG] = useState("");
    const [errorCustomPlan, setErrorCustomPlan] = useState(false);

    const plans = [
        { id: 1, name: "micro" },
        { id: 2, name: "small" },
        { id: 3, name: "medium" },
        { id: 4, name: "enterprise" },
    ];

    const handleChangeQuestions = (event) => {
        const { name, value } = event.target;

        setQuestionCustom((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const customForm = (
        <>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                }}
            >
                {t("register.customCondMsg1")}
            </Typography>
            <Grid container spacing={1} mt={1} mb={2}>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.customCondQues1")}
                        name="question1"
                        onChange={handleChangeQuestions}
                        value={questionCustom.question1}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                        error={errorCustomPlan && !questionCustom.question1}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.customCondQues2")}
                        name="question2"
                        onChange={handleChangeQuestions}
                        value={questionCustom.question2}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                        error={errorCustomPlan && !questionCustom.question2}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.customCondQues3")}
                        name="question3"
                        onChange={handleChangeQuestions}
                        value={questionCustom.question3}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                        error={errorCustomPlan && !questionCustom.question3}
                    />
                </Grid>
            </Grid>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                }}
                dangerouslySetInnerHTML={{
                    __html: t("register.customCondMsg2"),
                }}
            ></Typography>
        </>
    );

    const [showCart, setShowCart] = useState(false);

    /* Component functions */
    const handleChangeUserData = (event) => {
        event.preventDefault();
        setUserData({
            ...userData,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangePhoneCode = (event) => {
        let codeFilter = allCountries.find((country) => country.country_code === event.target.value);
        if (codeFilter) {
            setCountryCode(event.target.value);
            setPhoneCode(codeFilter.phone_code);
        }
    };

    const handleChangePhoneNumber = (event) => {
        if (!isNaN(event.target.value)) {
            setPhoneNumber(event.target.value);
        }
    };

    const handleChangePassword = (event) => {
        if (!editMode) {
            setPassword({
                ...password,
                [event.target.name]: event.target.value,
            });
        } else {
            setUpdatePassword({
                ...updatePassword,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleChangeCurrentPassword = async (event) => {
        setUpdatePassword({
            ...updatePassword,
            currentPassword: event.target.value,
        });
    };

    const verifyPass = async () => {
        setOpenBackdrop(true);
        await userService
            .verifyPassword({
                user_id: todos?.userInfo._id,
                password: updatePassword.currentPassword,
            })
            .then((response) => {
                if (response.status === 200) {
                    setErrorPassword(false);
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setErrorPassword(true);
                }
            });

        setOpenBackdrop(false);
    };

    const verifyEmail = async (email) => {
        if (email.trim() && /.+@.+\..+/.test(email)) {
            await authService
                .verifyExistingUser(email)
                .then((response) => {
                    if (response.status === 200 && email !== compareUserData.email) {
                        setAlertType("error");
                        setExistingEmail(true);
                        setErrorEmail(true);
                        setShow(true);
                        setTexto(t("register.existingEmail"));
                    } else {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                });
        } else {
            setIsError(true);
        }
    };

    const handleChangeCurrentEmail = async (event) => {
        setUserData({
            ...userData,
            email: event.target.value,
        });
        verifyEmail(event.target.value);
    };

    const handleClickShowPassword = (event) => {
        if (!editMode) {
            setPassword({
                ...password,
                showPassword: !password.showPassword,
            });
        } else {
            setUpdatePassword({
                ...updatePassword,
                showCurrentPassword: !updatePassword.showCurrentPassword,
            });
        }
    };

    const handleClickShowRepeatPassword = (event) => {
        if (!editMode) {
            setPassword({
                ...password,
                showRepeatPassword: !password.showRepeatPassword,
            });
        } else {
            setUpdatePassword({
                ...updatePassword,
                showNewPassword: !updatePassword.showNewPassword,
            });
        }
    };

    const handleClickShowRepeatNewPassword = (event) => {
        setUpdatePassword({
            ...updatePassword,
            showRepeatNewPassword: !updatePassword.showRepeatNewPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    useEffect(() => {
        if ((todos?.userInfo?.email || userEmail) && !isLoginRedirect) {
            setOpenBackdrop(true);
            async function getUserData() {
                try {
                    let result = await userService.getUserData(todos?.userInfo?.email ?? userEmail);
                    dispatch(addUserInfo(result));

                    if (result) {
                        setUserData({
                            fullName: result.user_name,
                            country: result.country_name,
                            email: result.email,
                            tools: result.software,
                            origin: result.kiiper_discovery
                                ? result.kiiper_discovery
                                : invitation
                                ? t("register.myBusiness")
                                : "",
                            role: result.role,
                        });

                        await userConfigService
                            .getUserConfig(result._id)
                            .then((response) => {
                                if (response.status === 204) {
                                    sessionStorage.setItem("lng", "en");
                                    i18n.changeLanguage("en");

                                    dispatch(addDateFormat("MM/DD/YYYY"));
                                    dispatch(addAmountFormat("en-US"));
                                } else {
                                    sessionStorage.setItem("lng", response.language);
                                    i18n.changeLanguage(response.language);

                                    dispatch(addDateFormat(response.date_format));
                                    dispatch(addAmountFormat(response.amount_format));
                                }
                            })
                            .catch((error) => {
                                console.log(error.response);
                            });

                        setPhoneCode(result.phone_code);
                        setPhoneNumber(result.phone_number);

                        let codeFilter = allCountries.find((country) => country.phone_code === result.phone_code);
                        setCountryCode(codeFilter?.country_code);

                        setCompareUserData({
                            fullName: result.user_name,
                            country: result.country_name,
                            email: result.email,
                            phoneCode: result.phone_code,
                            phoneNumber: result.phone_number,
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            getUserData();
            setOpenBackdrop(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const newPassword = editMode ? updatePassword.newPassword : password.password;
        const hasUppercase = uppercaseLetter.test(newPassword);
        const hasLowercase = lowercaseLetter.test(newPassword);
        const hasNumber = requiredNumber.test(newPassword);
        const hasSymbol = requiredSymbol.test(newPassword);
        const hasMinLength = newPassword.length >= 8;
        const trueCount = [hasUppercase, hasLowercase, hasNumber, hasSymbol, hasMinLength].filter(Boolean).length;

        setValidateSecurityLevel({
            uppercaseLetter: hasUppercase,
            lowercaseLetter: hasLowercase,
            requiredNumber: hasNumber,
            requiredSymbol: hasSymbol,
            eightCharacters: hasMinLength,
        });

        setSecurityLevel(trueCount);
        // eslint-disable-next-line
    }, [password.password, updatePassword.newPassword, editMode]);

    useEffect(() => {
        if (securityLevel === 0) {
            setColorSecurityLevel({
                firstLevel: "rgba(19, 31, 62, 0.05)",
                secondLevel: "rgba(19, 31, 62, 0.05)",
                thirdLevel: "rgba(19, 31, 62, 0.05)",
                fourthLevel: "rgba(19, 31, 62, 0.05)",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel === 1) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#DD1717",
                secondLevel: "rgba(19, 31, 62, 0.05)",
                thirdLevel: "rgba(19, 31, 62, 0.05)",
                fourthLevel: "rgba(19, 31, 62, 0.05)",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel >= 2) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#DD1717",
                secondLevel: "#DD1717",
                thirdLevel: "rgba(19, 31, 62, 0.05)",
                fourthLevel: "rgba(19, 31, 62, 0.05)",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel >= 3 && securityLevel < 4) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#FFC266",
                secondLevel: "#FFC266",
                thirdLevel: "#FFC266",
                fourthLevel: "rgba(19, 31, 62, 0.05)",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel >= 4 && securityLevel < 5) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#00B147",
                secondLevel: "#00B147",
                thirdLevel: "#00B147",
                fourthLevel: "#00B147",
                fifthLevel: "rgba(19, 31, 62, 0.05)",
            });
        }

        if (securityLevel === 5) {
            setColorSecurityLevel({
                ...colorSecurityLevel,
                firstLevel: "#00B147",
                secondLevel: "#00B147",
                thirdLevel: "#00B147",
                fourthLevel: "#00B147",
                fifthLevel: "#00B147",
            });
        }
        // eslint-disable-next-line
    }, [securityLevel]);

    useEffect(() => {
        if (!editMode) {
            if (
                password.password &&
                password.repeatPassword &&
                userData.fullName &&
                userData.role &&
                userData.origin.length > 0 &&
                securityLevel === 5 &&
                phoneCode &&
                phoneNumber &&
                activeStep === 0
            ) {
                setDisabledButton(false);
            } else if (
                password.password &&
                password.repeatPassword &&
                userData.fullName &&
                userData.role &&
                userData.origin.length > 0 &&
                securityLevel === 5 &&
                phoneCode &&
                phoneNumber &&
                userData.country &&
                userData.practice_name &&
                userData.subscription_type &&
                userData.company_name &&
                userData.company_country_name &&
                userData.tools.length > 0 &&
                userData.business_activity &&
                (activeStep === 1 || activeStep === 2)
            ) {
                setDisabledButton(false);
            } else if (
                userData.fullName &&
                userData.role &&
                userData.origin.length > 0 &&
                userData.country &&
                userData.practice_name &&
                userData.subscription_type &&
                userData.company_name &&
                userData.company_country_name &&
                userData.tools.length > 0 &&
                userData.business_activity &&
                activeStep === 3
            ) {
                setDisabledButton(false);
            } else {
                setDisabledButton(false);
            }
        } else {
            if (userData.fullName && userData.role && userData.email && phoneCode && phoneNumber) {
                if (showChangePassword) {
                    if (
                        updatePassword.currentPassword &&
                        updatePassword.newPassword &&
                        updatePassword.repeatPassword &&
                        securityLevel === 5 &&
                        !errorPassword
                    ) {
                        setDisabledButton(false);
                    } else {
                        setDisabledButton(true);
                    }
                } else {
                    setDisabledButton(false);

                    if (existingEmail) {
                        setDisabledButton(true);
                    }

                    if (
                        userData.fullName === compareUserData.fullName &&
                        userData.role === compareUserData.role &&
                        userData.email === compareUserData.email &&
                        phoneCode === compareUserData.phoneCode &&
                        phoneNumber === compareUserData.phoneNumber &&
                        !showChangePassword
                    ) {
                        setDisabledButton(true);
                    }
                }
            } else {
                setDisabledButton(true);
            }
        }
    }, [
        showChangePassword,
        password.password,
        password.repeatPassword,
        userData.fullName,
        userData.role,
        userData.origin,
        userData.country,
        securityLevel,
        editMode,
        updatePassword.currentPassword,
        updatePassword.newPassword,
        updatePassword.repeatPassword,
        userData.email,
        phoneCode,
        phoneNumber,
        compareUserData.fullName,
        compareUserData.role,
        compareUserData.email,
        compareUserData.phoneCode,
        compareUserData.phoneNumber,
        errorPassword,
        existingEmail,
        userData.practice_name,
        userData.subscription_type,
        userData.company_name,
        userData.company_country_name,
        userData.tools.length,
        userData.business_activity,
        activeStep,
    ]);

    useEffect(() => {
        if (editMode) {
            if (!phoneCode || !phoneNumber || !userData.country || !userData.fullName || !userData.email) {
                setErrorEditMode(true);
                setDisabledButton(true);
            } else {
                setErrorEditMode(false);
            }
        }
    }, [editMode, userData.email, phoneCode, phoneNumber, userData.country, userData.fullName]);

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
            setTermsLink(
                language === "es" || i18n.language === "es"
                    ? "https://kiiper.app/es/terms-and-conditions"
                    : "https://kiiper.app/terms-and-conditions/"
            );
        }
    }, [language, i18n]);

    useEffect(() => {
        (async () => {
            const company_param = searchParams.get("company_id");
            const connected_param = searchParams.get("connected");
            const connect_param = searchParams.get("connect");
            const type_param = searchParams.get("redirectType");
            const alert_param = searchParams.get("alert");
            if (company_param && type_param === "3") {
                // Redirección de Xero o Quickbooks al conectarse
                if (connected_param && connected_param === "true" && connect_param) {
                    await companyService
                        .getCompanyDetails(company_param)
                        .then((data) => {
                            setSmbInfo(data);

                            searchParams.delete("connected");
                            searchParams.delete("connect");
                            searchParams.delete("company_id");
                            searchParams.delete("redirectType");
                            searchParams.delete("alert");
                            setSearchParams(searchParams);

                            setUserData({
                                ...userData,
                                fullName: data.user_info.user_name,
                                role: data.user_info.role,
                                tools: data.franchise_id.file_formats,
                                country: data.franchise_id.country_name,
                                email: data.user_info.email,
                                practice_name: data.franchise_id.franchise_name,
                                business_activity: data.franchise_id.business_activity,
                                company_name: data.company_name,
                                company_country_name: data.country_name,
                                subscription_type: data.franchise_id.type,
                                origin: data?.user_info?.kiiper_discovery,
                            });

                            setActiveStep(3);
                            setAlertType("success");
                            setTexto(t("dialog.connectionEstablished"));
                            setShow(true);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    // Botón de regresar
                    await companyService
                        .getCompanyDetails(company_param)
                        .then((data) => {
                            setSmbInfo(data);

                            setUserData({
                                ...userData,
                                fullName: data.user_info.user_name,
                                role: data.user_info.role,
                                tools: data.franchise_id.file_formats,
                                country: data.franchise_id.country_name,
                                email: data.user_info.email,
                                practice_name: data.franchise_id.franchise_name,
                                business_activity: data.franchise_id.business_activity,
                                company_name: data.company_name,
                                company_country_name: data.country_name,
                                subscription_type: data.franchise_id.type,
                                origin: data?.user_info?.kiiper_discovery,
                            });

                            searchParams.delete("connected");
                            searchParams.delete("connect");
                            searchParams.delete("company_id");
                            searchParams.delete("redirectType");
                            searchParams.delete("alert");
                            setSearchParams(searchParams);

                            setActiveStep(3);
                            if (alert_param && alert_param === "1") {
                                setAlertType("error");
                                setTexto(t("dialog.alreadyConnected"));
                                setShow(true);
                            } else if (alert_param && alert_param === "2") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError1"));
                                setShow(true);
                            } else if (alert_param && alert_param === "3") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError2"));
                                setShow(true);
                            } else if (alert_param && alert_param === "4") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError3"));
                                setShow(true);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        })();
    }, [searchParams, t, userData, setSearchParams]);

    useEffect(() => {
        (async () => {
            const redirectLogin = searchParams.get("redirectLogin");
            const redirectCompany = searchParams.get("company_id");
            if (redirectLogin && redirectLogin === "true" && redirectCompany) {
                // Redirección desde login
                await companyService
                    .getCompanyDetails(redirectCompany)
                    .then((data) => {
                        setSmbInfo(data);

                        searchParams.delete("redirectLogin");
                        searchParams.delete("company_id");
                        setSearchParams(searchParams);

                        setActiveStep(2);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        })();
    }, [searchParams, t, userData, setSearchParams]);

    const resetStates = () => {
        setPhoneNumber("");
        setPhoneCode("");
        setUserData({
            fullName: "",
            role: "",
            tools: [],
            origin: "",
            country: "",
            email: "",
            practice_name: "",
            practice_size: "",
            company_type: "",
            business_activity: "",
            company_name: "",
            company_country_name: "",
            subscription_type: "",
        });
        setPassword({
            password: "",
            repeatPassword: "",
            showPassword: false,
            showRepeatPassword: false,
        });
        setValidateSecurityLevel({
            uppercaseLetter: false,
            lowercaseLetter: false,
            requiredNumber: false,
            requiredSymbol: false,
            eightCharacters: false,
        });
        setColorSecurityLevel({
            firstLevel: "rgba(19, 31, 62, 0.05)",
            secondLevel: "rgba(19, 31, 62, 0.05)",
            thirdLevel: "rgba(19, 31, 62, 0.05)",
            fourthLevel: "rgba(19, 31, 62, 0.05)",
            fifthLevel: "rgba(19, 31, 62, 0.05)",
        });
        setSecurityLevel(0);
        setInheritData(false);
    };

    const handleResend = async () => {
        const params = {
            user_email: userEmail ?? todos?.userInfo?.email,
            user_name: userData.fullName,
            language: language ?? sessionStorage.getItem("lng"),
        };

        setDisabled(true);

        await authService
            .resend(params)
            .then(() => {
                setTexto(t("login.verifyEmail"));
                setAlertType("success");
                setShow(true);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        if (disabled && counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
        if (disabled && counter === 0) {
            setDisabled(false);
            setCounter(120);
        }
    }, [counter, disabled]);

    const updateFreePlan = async () => {
        handleCloseDialog();
        setOpenBackdrop(true)
        const currentLevel = plansInfo.flatMap((plan) => plan.levels).find((level) => level.pages === pagesMonthly)?.id;
        await authService
            .updateFreeTrialPlan({ company_id: smbInfo._id, planId: currentLevel, origin: "register" })
            .then(async () => {
                await redirectAfterSubmit()
            })
            .catch((error) => console.log(error.response));
        setOpenBackdrop(false)
    }

    const redirectAfterSubmit = async () => {
        setOpenBackdrop(true);
        setAlertType("success");
        setTexto(t("register.success"));
        setShow(true);
        resetStates();

        await authService
            .checkSession()
            .then(async (isActive) => {
                if (isActive) {
                    navigate("/dashboard", {
                        state: {
                            userEmail: userEmail ?? todos?.userInfo?.email,
                            independent_user: independentUser,
                        },
                    });
                    handleShowRegister();
                } else {
                    let paramsSession = {
                        user_email: userEmail ?? todos?.userInfo?.email,
                        password: password.password,
                    };
                    setTimeout(async () => {
                        await authService
                            .login(paramsSession)
                            .then((responseLogin) => {
                                const defaultTut = {
                                    step1: true,
                                    step2: false,
                                    step4: false,
                                    step5: false,
                                    step6: false,
                                    step7: false,
                                    step8: false,
                                    step9: false,
                                };

                                if (ITTeam.includes(responseLogin.data.user.email)) {
                                    defaultTut.step1 = false;
                                }

                                dispatch(addUserInfo(responseLogin.data.user));
                                dispatch(addPermissions(responseLogin.data.permissions));
                                dispatch(addAuth(true));
                                dispatch(addDateFormat("MM/DD/YYYY"));
                                dispatch(addAmountFormat("en-US"));
                                dispatch(addTutorial(defaultTut));

                                navigate("/dashboard", {
                                    state: {
                                        userEmail: userEmail ?? todos?.userInfo?.email,
                                        independent_user: independentUser,
                                    },
                                });
                                handleShowRegister();
                            })
                            .catch((err) => {
                                switch (err.response.status) {
                                    case 401:
                                        setTexto(t("login.loginError401"));
                                        break;
                                    case 403:
                                        setTexto(
                                            t("login.loginError403") +
                                            (
                                                <Link
                                                    sx={{ fontWeight: 600, p: 0, textDecoration: "none" }}
                                                    component={Button}
                                                    onClick={handleResend}
                                                    disabled={disabled}
                                                >
                                                    {t("team.resend")}{" "}
                                                    {disabled
                                                        ? ` ${t("login.on")} ` + counter + ` ${t("login.sec")} `
                                                        : ""}
                                                </Link>
                                            )
                                        );
                                        break;
                                    case 404:
                                        setTexto(t("login.loginError404"));
                                        break;
                                    default:
                                        setTexto(t("login.loginError500"));
                                        break;
                                }
                                setAlertType("error");
                                setShow(true);
                            });
                            setOpenBackdrop(false);
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error.response);
                setOpenBackdrop(false);
            });
    };

    const handleSubmitCustomPlan = async () => {
        if (questionCustom.question1 === "" || questionCustom.question2 === "" || questionCustom.question3 === "") {
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
            setErrorCustomPlan(true)
        } else {
            setErrorCustomPlan(false)
            handleCloseDialog()
            setOpenBackdrop(true);
            const paramsC = {
                company_id: smbInfo._id,
                origin: 1,
                question1: questionCustom.question1,
                question2: questionCustom.question2,
                question3: questionCustom.question3,
            }

            await authService
                .createCustomRequest(paramsC)
                .then(async () => {
                    await redirectAfterSubmit();
                })
                .catch((error) => console.log(error.response));
                
            setOpenBackdrop(false);
        }
    };

    const saveUserBasicData = async () => {
        if (
            !phoneNumber ||
            !phoneCode ||
            !userData.fullName ||
            !userData.role ||
            userData.origin.length === 0 ||
            !password.password ||
            !password.repeatPassword
        ) {
            setIsError(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
        } else if (password.password && password.repeatPassword && password.password !== password.repeatPassword) {
            setIsError(true);
            setAlertType("warning");
            setShow(true);
            setTexto(t("register.passwordError"));
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setIsError(false);
        }
    };

    const saveUserData = async () => {
        setOpenBackdrop(true);
        if (
            !phoneNumber ||
            !phoneCode ||
            !userData.fullName ||
            !userData.role ||
            userData.origin.length === 0 ||
            !userData.country ||
            !password.password ||
            !password.repeatPassword ||
            !userData.practice_name ||
            !userData.business_activity ||
            !userData.company_name ||
            !userData.company_country_name ||
            userData.tools.length === 0
        ) {
            setIsError(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
        } else if (password.password && password.repeatPassword && password.password !== password.repeatPassword) {
            setIsError(true);
            setAlertType("warning");
            setShow(true);
            setTexto(t("register.passwordError"));
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setIsError(false);
            let params = {
                user_email: userEmail ?? todos?.userInfo?.email,
                user_name: userData.fullName,
                role: userData.role,
                software: userData.tools,
                kiiper_discovery: userData.origin,
                country_name: userData.country,
                phone_code: phoneCode,
                phone_number: phoneNumber,
                user_password: password.password,
                practice_name: userData.practice_name,
                practice_size: userData.practice_size,
                company_type: userData.company_type,
                business_activity: userData.business_activity,
                language: language ?? sessionStorage.getItem("lng"),
                invitationId: invitationId && invitationId !== "" ? invitationId : undefined,
                company_name: userData.company_name,
                company_country_name: userData.company_country_name,
                subscription_type: userData.subscription_type,
            };

            await authService
                .updateUser(params)
                .then((data) => {
                    setSmbInfo(data.data);
                    dispatch(addCompanyID(data.data._id));
                    dispatch(addFranchiseID(data.data.franchise_id));
                })
                .catch((err) => {
                    switch (err.response.status) {
                        case 403:
                            setTexto(t("register.notVerified"));
                            break;
                        case 404:
                            setTexto(t("register.userError"));
                            break;
                        default:
                            setTexto(t("register.error"));
                            break;
                    }
                    setIsError(true);
                    setAlertType("error");
                    setShow(true);
                });
        }
        setOpenBackdrop(false);
    };

    const updateUserData = async () => {
        setOpenBackdrop(true);
        if (
            !phoneNumber ||
            !phoneCode ||
            !userData.fullName ||
            !userData.role ||
            !userData.email ||
            (showChangePassword &&
                (!updatePassword.currentPassword || !updatePassword.newPassword || !updatePassword.repeatPassword))
        ) {
            setIsError(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
        } else if (!/.+@.+\..+/.test(userData.email)) {
            setErrorEmail(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.invalidFormat"));
        } else if (showChangePassword && updatePassword.newPassword !== updatePassword.repeatPassword) {
            setIsError(true);
            setAlertType("error");
            setShow(true);
            setTexto(t("register.passwordError2"));
        } else {
            setIsError(false);
            setErrorEmail(false);
            setErrorPassword(false);
            let params = {
                user_id: todos?.userInfo?._id,
                user_email: userData.email,
                user_name: userData.fullName,
                role: userData.role,
                phone_code: phoneCode,
                phone_number: phoneNumber,
                language: language ?? sessionStorage.getItem("lng"),
            };

            if (showChangePassword) {
                params.new_password = updatePassword.newPassword;
                params.current_password = updatePassword.currentPassword;
            }

            if (userData.email !== todos?.userInfo?.email) {
                setOpenConfirmPassword(true);
                setParamsChangeMail(params);
            } else {
                await userService
                    .updateUserProfile(params)
                    .then(async () => {
                        setAlertType("success");
                        setTexto(t("register.success"));
                        setShow(true);
                        let result = await userService.getUserData(todos?.userInfo?.email);
                        if (result) {
                            setUserData({
                                fullName: result.user_name,
                                role: userData.role,
                                email: result.email,
                            });

                            setPhoneCode(result.phone_code);
                            setPhoneNumber(result.phone_number);

                            let codeFilter = allCountries.find((country) => country.phone_code === result.phone_code);
                            setCountryCode(codeFilter?.country_code);

                            setCompareUserData({
                                fullName: result.user_name,
                                role: userData.role,
                                email: result.email,
                                phoneCode: result.phone_code,
                                phoneNumber: result.phone_number,
                            });
                            setShowChangePassword(false);
                            setUpdatePassword({
                                currentPassword: "",
                                newPassword: "",
                                repeatPassword: "",
                                showCurrentPassword: false,
                                showNewPassword: false,
                                showRepeatNewPassword: false,
                            });
                        }
                    })
                    .catch((err) => {
                        switch (err.response.status) {
                            case 401:
                                setTexto(t("register.invalidPass"));
                                break;
                            case 404:
                                setTexto(t("register.error"));
                                break;
                            default:
                                setTexto(t("register.error"));
                                break;
                        }
                        setIsError(true);
                        setAlertType("error");
                        setShow(true);
                    });
            }
        }
        setOpenBackdrop(false);
    };

    const handleShowChangePassword = () => {
        setShowChangePassword((show) => !show);
        setUpdatePassword({
            currentPassword: "",
            newPassword: "",
            repeatPassword: "",
            showCurrentPassword: false,
            showNewPassword: false,
            showRepeatNewPassword: false,
        });
    };

    const handleCloseConfirmPass = () => {
        setOpenConfirmPassword(false);
        setConfirmPassword({
            password: "",
            repeatPassword: "",
            showPassword: false,
            showRepeatPassword: false,
        });
    };

    const handleCloseConfirmEmail = () => {
        setConfirmChangeEmail(false);
    };

    const boxSecurityLevel = (color) => (
        <Box sx={{ backgroundColor: color, width: "40px", height: "15px", borderRadius: "4px", ml: 0.6 }}></Box>
    );

    const securityLevelContent = (
        <GridUI item xs={6}>
            <InputLabel className={classes.labels}>{t("register.securityLevel")}</InputLabel>
            <Box sx={{ mt: 1.5, mb: 2, display: "flex", flexDirection: "row" }}>
                {boxSecurityLevel(colorSecurityLevel.firstLevel)}
                {boxSecurityLevel(colorSecurityLevel.secondLevel)}
                {boxSecurityLevel(colorSecurityLevel.thirdLevel)}
                {boxSecurityLevel(colorSecurityLevel.fourthLevel)}
                {boxSecurityLevel(colorSecurityLevel.fifthLevel)}
            </Box>
            <List sx={{ mt: -2 }}>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.uppercaseLetter ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.uppercaseLetter")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.lowercaseLetter ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.lowercaseLetter")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.requiredNumber ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.requiredNumber")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            color={validateSecurityLevel.requiredSymbol ? "success" : "disabled"}
                            sx={{ fontSize: "20px" }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.requiredSymbol")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.eightCharacters ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.eightCharacters")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
            </List>
        </GridUI>
    );

    const renderPasswordInput = (
        label,
        type,
        name,
        onChange,
        value,
        error,
        onClick,
        onMouseDown,
        iconName,
        showVisibility,
        mt,
        onBlur
    ) => {
        return (
            <GridUI item xs={12} mt={mt ? mt : null}>
                <FormControl fullWidth variant="filled" error={error}>
                    <InputLabel
                        id="type-simple-select-label"
                        sx={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            color: "#131F3E",
                        }}
                    >
                        {label}
                    </InputLabel>
                    <FilledInput
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        label={label}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        type={type}
                        autoComplete="one-time-code"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={onClick} onMouseDown={onMouseDown} edge="end" name={iconName}>
                                    {showVisibility ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </GridUI>
        );
    };

    const handleNext = async () => {
        if (activeStep === 0 && editMode) {
            updateUserData();
        } else if (activeStep === 0 && !editMode) {
            saveUserBasicData();
        } else if (activeStep === 1 && !editMode) {
            saveUserData();
        } else if (activeStep === 3 && !editMode) {
            await redirectAfterSubmit();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [t("register.userInfo"), t("register.business"), t("plans.choosePlan"), t("subscription.integ")];

    let bussinessActFinal = [];

    for (let i = 0; i < businessActivity.length; i++) {
        bussinessActFinal.push({
            id: businessActivity[i].id,
            name: t("dialog." + businessActivity[i].translation),
        });
    }

    const subscriptionType = [
        { id: 1, name: t("register.accountingFirm") },
        { id: 2, name: t("register.companiesGroup") },
        { id: 3, name: t("register.indivCompany") },
    ];

    const originList = [
        { id: 0, data: t("register.recommendation") },
        { id: 1, data: t("register.socialNetworks") },
        { id: 2, data: t("register.webPage") },
        { id: 3, data: "Xero app store" },
        { id: 4, data: t("register.forums") },
        { id: 5, data: t("register.myBusiness") },
        { id: 6, data: t("register.other") },
    ];

    const roleList = [
        { id: 1, data: t("register.accountant") },
        { id: 2, data: t("register.businessOwner") },
        { id: 3, data: t("register.manager") },
        { id: 4, data: t("register.administrator") },
        { id: 5, data: t("register.businessman") },
        { id: 6, data: t("register.other") },
    ];

    const toolsList = [
        { id: 0, data: "Xero" },
        { id: 1, data: "Quickbooks desktop" },
        { id: 2, data: "Quickbooks online" },
        { id: 3, data: "Profit" },
        { id: 4, data: "Excel" },
        { id: 5, data: "Odoo" },
        { id: 6, data: "Sage 50cloud" },
        { id: 7, data: "KashFlow" },
        { id: 8, data: "FuseMetrix" },
        { id: 9, data: "SortMyBooks" },
        { id: 10, data: "FreeAgent" },
        { id: 11, data: "Accounts IQ" },
        { id: 12, data: "ClearBooks" },
        { id: 13, data: "ReckonOne" },
        { id: 14, data: "AccountRightLive" },
        { id: 15, data: "Exact" },
        { id: 16, data: "FreshBooks" },
        { id: 17, data: "Wave" },
        { id: 18, data: "Zoho Books" },
        { id: 19, data: "NetSuite" },
        { id: 20, data: "Kashoo" },
        { id: 21, data: "AccountEdge" },
        { id: 22, data: "Bill.com" },
        { id: 23, data: t("register.other") },
    ];

    const countryAndPhoneInput = (
        <>
            <GridUI item xs={12} md={6}>
                <FormControl fullWidth variant="filled" error={(isError || errorEditMode) && (userData.role === "" || !userData.role)}>
                    <InputLabel
                        id="type-simple-select-label"
                        sx={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            color: "#131F3E",
                        }}
                    >
                        {t("register.role")}
                    </InputLabel>
                    <Select
                        name="role"
                        IconComponent={(props) => {
                            if (props.className.includes("MuiSelect-iconOpen")) {
                                return (
                                    <PlayArrowIcon
                                        sx={{
                                            position: "absolute",
                                            transform: "rotate(270deg)",
                                            color: "#131F3E",
                                            right: ".5rem",
                                            cursor: "pointer",
                                            zIndex: 0,
                                            pointerEvents: "none",
                                        }}
                                    />
                                );
                            }
                            return (
                                <PlayArrowIcon
                                    sx={{
                                        position: "absolute",
                                        transform: "rotate(90deg)",
                                        color: "#131F3E",
                                        right: ".5rem",
                                        cursor: "pointer",
                                        zIndex: 0,
                                        pointerEvents: "none",
                                    }}
                                />
                            );
                        }}
                        value={userData.role ?? ""}
                        onChange={(event) => handleChangeUserData(event)}
                        fullWidth
                        input={
                            <FilledInput
                                label={t("register.role")}
                                sx={{
                                    fontStyle: "normal",
                                    color: "#131F3E",
                                }}
                            />
                        }
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    mt: 0.7,
                                    borderRadius: 2,
                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    maxHeight: "300px",
                                },
                            },
                        }}
                        renderValue={() => (
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <Typography>{roleList.find((rol) => rol.id === userData.role)?.data}</Typography>
                            </Stack>
                        )}
                    >
                        {roleList?.map(({ id, data }) => (
                            <MenuItem value={id} key={`${id}${data}`}>
                                {data}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridUI>
            <GridUI item xs={12} md={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <FormControl
                            fullWidth
                            variant="filled"
                            error={(isError || errorEditMode) && (phoneCode === "" || !phoneCode)}
                        >
                            <InputLabel
                                id="type-simple-select-label"
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    color: "#131F3E",
                                }}
                            >
                                {t("register.code")}
                            </InputLabel>
                            <Select
                                IconComponent={(props) => {
                                    if (props.className.includes("MuiSelect-iconOpen")) {
                                        return (
                                            <PlayArrowIcon
                                                sx={{
                                                    position: "absolute",
                                                    transform: "rotate(270deg)",
                                                    color: "#131F3E",
                                                    right: ".5rem",
                                                    cursor: "pointer",
                                                    zIndex: 0,
                                                    pointerEvents: "none",
                                                }}
                                            />
                                        );
                                    }
                                    return (
                                        <PlayArrowIcon
                                            sx={{
                                                position: "absolute",
                                                transform: "rotate(90deg)",
                                                color: "#131F3E",
                                                right: ".5rem",
                                                cursor: "pointer",
                                                zIndex: 0,
                                                pointerEvents: "none",
                                            }}
                                        />
                                    );
                                }}
                                name="phoneCode"
                                value={countryCode ?? ""}
                                onChange={(event) => handleChangePhoneCode(event)}
                                fullWidth
                                input={
                                    <FilledInput
                                        label={t("register.code")}
                                        sx={{
                                            fontStyle: "normal",
                                            color: "#131F3E",
                                        }}
                                    />
                                }
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            maxHeight: "300px",
                                        },
                                    },
                                }}
                                renderValue={() => (
                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                        <Flag code={countryCode} height="12" width="20" />
                                        <Typography>{phoneCode}</Typography>
                                    </Stack>
                                )}
                            >
                                {allCountries.map((option) => (
                                    <MenuItem value={option.country_code} key={option.country_code}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Flag code={option.country_code} height="14" width="22" />
                                            <Typography>
                                                {sessionStorage.getItem("lng") === "en" || language === "en"
                                                    ? option.name_en
                                                    : option.name_es}
                                                {" ("}
                                                {option.phone_code + ")"}
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CustomTextField
                            fullWidth
                            label={t("register.phone")}
                            name="phoneNumber"
                            error={(isError || errorEditMode) && (phoneNumber === "" || !phoneNumber) ? true : false}
                            onChange={(event) => handleChangePhoneNumber(event)}
                            value={phoneNumber}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            type="text"
                            autoComplete="one-time-code"
                        />
                    </Grid>
                </Grid>
            </GridUI>
        </>
    );

    const handleChangeCheck = (event) => {
        setCheckTandC(event.target.checked);
    };

    const handleChangeInherit = (event) => {
        setInheritData(event.target.checked);
        if (event.target.checked) {
            setUserData({
                ...userData,
                company_name: userData.practice_name,
                company_country_name: userData.country,
            });
        } else {
            setUserData({
                ...userData,
                company_name: "",
                company_country_name: "",
            });
        }
    };

    const handleCloseDialog = () => {
        setQuestionCustom({
            question1: "",
            question2: "",
            question3: "",
        });
        setErrorCustomPlan(false)
        setOpenAlert(false);
    };

    const handleClickHelp = () => {};

    const showStep3 = () => {
        setShowCart(false);
    };

    const handleShowCart = async () => {
        handleCloseDialog(false);
        let subsPlan = plansInfo.flatMap((plan) => plan.levels).find((level) => level.pages === pagesMonthly).id

        const newVAl = {
            subscription_plan: subsPlan,
            line_type: 1,
            line_amount: paymentType === "annual" ? 12 * priceMonthly : priceMonthly,
            line_unit_amount: priceMonthly,
            line_qty: paymentType === "annual" ? 12 : 1,
            line_since_date: moment().format(),
            line_end_date: moment().add(1, "M").format(),
        };

        const params = {
            company_id: todos.companyID,
            cart_lines: [newVAl],
        };

        setOpenBackdrop(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setOpenBackdrop(false);

        setShowCart(true);
    };

    const finalF = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        showStep3();
    }

    return (
        <Container maxWidth={editMode ? "md" : "lg"}>
            <Paper elevation={2} className={classes.content}>
                <Typography className={classes.title} gutterBottom>
                    {editMode ? t("register.editProfile") : t("register.register")}
                </Typography>
                {!editMode && (
                    <Stepper
                        activeStep={activeStep}
                        sx={{ px: { xs: 0, sm: 8, md: 10 }, py: 2, justifyContent: "center" }}
                        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step
                                    key={label}
                                    {...stepProps}
                                    sx={{
                                        "& .MuiStepLabel-root .Mui-completed": {
                                            color: "#031851", // circle color (COMPLETED)
                                            borderRadius: "50%",
                                        },
                                        "& .MuiStepLabel-root .Mui-active": {
                                            color: "#031851", // circle color (ACTIVE)
                                            borderRadius: "50%",
                                        },
                                        ".MuiSvgIcon-root": {
                                            color: "rgba(0, 0, 0, 0.38)",
                                            borderRadius: "50%",
                                        },
                                    }}
                                >
                                    <StepLabel {...labelProps}>
                                        <Typography
                                            fontStyle="normal"
                                            fontSize={18}
                                            fontWeight={600}
                                            sx={{ color: "#131F3E", mx: 1 }}
                                        >
                                            {label}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                )}
                {activeStep === 0 && (
                    <>
                        <Grid container spacing={2}>
                            <GridUI item xs={12} md={6}>
                                <CustomTextField
                                    fullWidth
                                    error={
                                        (isError || errorEditMode) && (userData.fullName === "" || !userData.fullName) ? true : false
                                    }
                                    label={t("register.fullName")}
                                    name="fullName"
                                    onChange={(event) => handleChangeUserData(event)}
                                    value={userData.fullName}
                                    variant="filled"
                                    inputProps={{
                                        fontStyle: "normal",
                                    }}
                                    type="text"
                                />
                            </GridUI>
                            {!editMode ? (
                                <>
                                    <GridUI item xs={6}>
                                        <FormControl
                                            fullWidth
                                            variant="filled"
                                            error={
                                                (isError || errorEditMode) &&
                                                (userData.role === "" || !userData.role)
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <InputLabel
                                                id="type-simple-select-label"
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                                shrink
                                            >
                                                {t("register.role")}
                                            </InputLabel>
                                            <Select
                                                name="role"
                                                displayEmpty
                                                IconComponent={(props) => {
                                                    if (props.className.includes("MuiSelect-iconOpen")) {
                                                        return (
                                                            <PlayArrowIcon
                                                                sx={{
                                                                    position: "absolute",
                                                                    transform: "rotate(270deg)",
                                                                    color: "#131F3E",
                                                                    right: ".5rem",
                                                                    cursor: "pointer",
                                                                    zIndex: 0,
                                                                    pointerEvents: "none",
                                                                }}
                                                            />
                                                        );
                                                    }
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(90deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }}
                                                value={userData.role ?? ""}
                                                onChange={(event) => handleChangeUserData(event)}
                                                fullWidth
                                                input={
                                                    <FilledInput
                                                        label={t("register.role")}
                                                        sx={{
                                                            fontStyle: "normal",
                                                            color: "#131F3E",
                                                        }}
                                                    />
                                                }
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            mt: 0.7,
                                                            borderRadius: 2,
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            maxHeight: "300px",
                                                        },
                                                    },
                                                }}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return t("register.select");
                                                    }
                                                    return roleList.find((rol) => rol.id === selected)?.data;
                                                }}
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{t("register.select")}</em>
                                                </MenuItem>
                                                {roleList?.map(({ id, data }) => (
                                                    <MenuItem value={id} key={`${id}${data}`}>
                                                        {data}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </GridUI>
                                    <GridUI item xs={6}>
                                        <FormControl
                                            fullWidth
                                            variant="filled"
                                            error={isError && userData.origin.length === 0 ? true : false}
                                        >
                                            <InputLabel
                                                id="type-simple-select-label"
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                                shrink
                                            >
                                                {t("register.origin")}
                                            </InputLabel>
                                            <Select
                                                IconComponent={(props) => {
                                                    if (props.className.includes("MuiSelect-iconOpen")) {
                                                        return (
                                                            <PlayArrowIcon
                                                                sx={{
                                                                    position: "absolute",
                                                                    transform: "rotate(270deg)",
                                                                    color: "#131F3E",
                                                                    right: ".5rem",
                                                                    cursor: "pointer",
                                                                    zIndex: 0,
                                                                    pointerEvents: "none",
                                                                }}
                                                            />
                                                        );
                                                    }
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(90deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }}
                                                displayEmpty
                                                name="origin"
                                                value={userData.origin}
                                                onChange={(event) => handleChangeUserData(event)}
                                                fullWidth
                                                input={
                                                    <FilledInput
                                                        label={t("register.origin")}
                                                        sx={{
                                                            fontStyle: "normal",
                                                            color: "#131F3E",
                                                        }}
                                                    />
                                                }
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            mt: 0.7,
                                                            borderRadius: 2,
                                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                            maxHeight: "300px",
                                                        },
                                                    },
                                                }}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return t("register.select");
                                                    }
                                             
                                                    return selected;
                                                }}
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{t("register.select")}</em>
                                                </MenuItem>
                                                {originList?.map(({ id, data }) => (
                                                    <MenuItem value={data} key={`${id}${data}`}>
                                                        {data}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </GridUI>
                                    <GridUI item xs={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                                <FormControl
                                                    fullWidth
                                                    variant="filled"
                                                    error={
                                                        (isError || errorEditMode) && (phoneCode === "" || !phoneCode)
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <InputLabel
                                                        id="type-simple-select-label"
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("register.code")}
                                                    </InputLabel>
                                                    <Select
                                                        IconComponent={(props) => {
                                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                                return (
                                                                    <PlayArrowIcon
                                                                        sx={{
                                                                            position: "absolute",
                                                                            transform: "rotate(270deg)",
                                                                            color: "#131F3E",
                                                                            right: ".5rem",
                                                                            cursor: "pointer",
                                                                            zIndex: 0,
                                                                            pointerEvents: "none",
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                            return (
                                                                <PlayArrowIcon
                                                                    sx={{
                                                                        position: "absolute",
                                                                        transform: "rotate(90deg)",
                                                                        color: "#131F3E",
                                                                        right: ".5rem",
                                                                        cursor: "pointer",
                                                                        zIndex: 0,
                                                                        pointerEvents: "none",
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                        name="phoneCode"
                                                        value={countryCode ?? ""}
                                                        onChange={(event) => handleChangePhoneCode(event)}
                                                        fullWidth
                                                        input={
                                                            <FilledInput
                                                                label={t("register.code")}
                                                                sx={{
                                                                    fontStyle: "normal",
                                                                    color: "#131F3E",
                                                                }}
                                                            />
                                                        }
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                    maxHeight: "300px",
                                                                },
                                                            },
                                                        }}
                                                        renderValue={() => (
                                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                                <Flag code={countryCode} height="12" width="20" />
                                                                <Typography>{phoneCode}</Typography>
                                                            </Stack>
                                                        )}
                                                    >
                                                        {allCountries.map((option) => (
                                                            <MenuItem
                                                                value={option.country_code}
                                                                key={option.country_code}
                                                            >
                                                                <Stack direction="row" spacing={1} alignItems="center">
                                                                    <Flag
                                                                        code={option.country_code}
                                                                        height="14"
                                                                        width="22"
                                                                    />
                                                                    <Typography>
                                                                        {sessionStorage.getItem("lng") === "en" ||
                                                                        language === "en"
                                                                            ? option.name_en
                                                                            : option.name_es}
                                                                        {" ("}
                                                                        {option.phone_code + ")"}
                                                                    </Typography>
                                                                </Stack>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <CustomTextField
                                                    fullWidth
                                                    label={t("register.phone")}
                                                    name="phoneNumber"
                                                    error={
                                                        (isError || errorEditMode) &&
                                                        (phoneNumber === "" || !phoneNumber)
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(event) => handleChangePhoneNumber(event)}
                                                    value={phoneNumber}
                                                    variant="filled"
                                                    inputProps={{
                                                        fontStyle: "normal",
                                                    }}
                                                    type="text"
                                                    autoComplete="one-time-code"
                                                />
                                            </Grid>
                                        </Grid>
                                    </GridUI>
                                </>
                            ) : (
                                <>
                                    <GridUI item xs={12} md={6}>
                                        <CustomTextField
                                            fullWidth
                                            label={t("register.email")}
                                            name="email"
                                            onChange={(event) => handleChangeCurrentEmail(event)}
                                            error={
                                                ((isError || errorEditMode || errorEmail) &&
                                                    !/.+@.+\..+/.test(userData.email)) ||
                                                existingEmail
                                            }
                                            value={userData.email}
                                            variant="filled"
                                            inputProps={{
                                                fontStyle: "normal",
                                            }}
                                            type="text"
                                        />
                                    </GridUI>
                                    {countryAndPhoneInput}
                                </>
                            )}
                        </Grid>
                        {!editMode ? (
                            <>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Divider sx={{ mt: 2, mb: 2, width: "50%" }} />
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        {renderPasswordInput(
                                            t("register.password"),
                                            password.showPassword ? "text" : "password",
                                            "password",
                                            handleChangePassword,
                                            password.password,
                                            isError &&
                                                (password.password === "" ||
                                                    password.password !== password.repeatPassword)
                                                ? true
                                                : false,
                                            handleClickShowPassword,
                                            handleMouseDownPassword,
                                            "showPassword",
                                            password.showPassword
                                        )}
                                        {renderPasswordInput(
                                            t("register.repeatPassword"),
                                            password.showRepeatPassword ? "text" : "password",
                                            "repeatPassword",
                                            handleChangePassword,
                                            password.repeatPassword,
                                            isError &&
                                                (password.repeatPassword === "" ||
                                                    password.password !== password.repeatPassword)
                                                ? true
                                                : false,
                                            handleClickShowRepeatPassword,
                                            handleMouseDownPassword,
                                            "showRepeatPassword",
                                            password.showRepeatPassword,
                                            3
                                        )}
                                        {password.password &&
                                            password.repeatPassword &&
                                            password.password !== password.repeatPassword && (
                                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                    {
                                                        <Typography sx={{ fontSize: "12px" }} color="error" mt={1}>
                                                            {t("register.passwordError")}
                                                        </Typography>
                                                    }
                                                </Box>
                                            )}
                                    </Grid>
                                    {securityLevelContent}
                                </Grid>
                            </>
                        ) : !showChangePassword ? (
                            <Button sx={{ mt: 2, mb: 2 }} onClick={handleShowChangePassword}>
                                {t("register.changePassword")}
                            </Button>
                        ) : null}
                        {showChangePassword ? (
                            <>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Divider sx={{ mt: 2, mb: 2, width: "50%" }} />
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        {renderPasswordInput(
                                            t("register.currentPassword"),
                                            updatePassword.showCurrentPassword ? "text" : "password",
                                            "currentPassword",
                                            handleChangeCurrentPassword,
                                            updatePassword.currentPassword,
                                            errorPassword ? true : false,
                                            handleClickShowPassword,
                                            handleMouseDownPassword,
                                            "showCurrentPassword",
                                            updatePassword.showCurrentPassword,
                                            3,
                                            verifyPass
                                        )}
                                        {renderPasswordInput(
                                            t("register.newPassword"),
                                            updatePassword.showNewPassword ? "text" : "password",
                                            "newPassword",
                                            handleChangePassword,
                                            updatePassword.newPassword,
                                            isError &&
                                                (updatePassword.newPassword === "" ||
                                                    updatePassword.newPassword !== updatePassword.repeatPassword)
                                                ? true
                                                : false,
                                            handleClickShowRepeatPassword,
                                            handleMouseDownPassword,
                                            "showNewPassword",
                                            updatePassword.showNewPassword,
                                            3
                                        )}
                                        {renderPasswordInput(
                                            t("register.repeatPassword"),
                                            updatePassword.showRepeatNewPassword ? "text" : "password",
                                            "repeatPassword",
                                            handleChangePassword,
                                            updatePassword.repeatPassword,
                                            isError &&
                                                (updatePassword.repeatPassword === "" ||
                                                    updatePassword.repeatPassword !== updatePassword.newPassword)
                                                ? true
                                                : false,
                                            handleClickShowRepeatNewPassword,
                                            handleMouseDownPassword,
                                            "showRepeatPassword",
                                            updatePassword.showRepeatNewPassword,
                                            3
                                        )}
                                        {updatePassword.newPassword &&
                                            updatePassword.repeatPassword &&
                                            updatePassword.newPassword !== updatePassword.repeatPassword && (
                                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                    {
                                                        <Typography sx={{ fontSize: "12px" }} color="error" mt={1}>
                                                            {t("register.passwordError")}
                                                        </Typography>
                                                    }
                                                </Box>
                                            )}
                                    </Grid>
                                    {securityLevelContent}
                                </Grid>
                            </>
                        ) : null}
                        <Grid item sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                            {editMode && showChangePassword && (
                                <Typography
                                    onClick={handleShowChangePassword}
                                    sx={{
                                        color: "#4A22D4",
                                        fontWeight: "600",
                                        mr: "auto",
                                        mt: 1,
                                        cursor: "pointer",
                                    }}
                                >
                                    {t("register.cancelChangePass")}
                                </Typography>
                            )}
                        </Grid>
                    </>
                )}
                {activeStep === 1 && (
                    <>
                        <Typography
                            sx={{ py: 2.5 }}
                            dangerouslySetInnerHTML={{
                                __html: t("register.createSubsMsg"),
                            }}
                        ></Typography>
                        <Typography fontWeight={600} sx={{ mt: 3, fontSize: 16 }}>
                            {t("register.subsInfo")}
                        </Typography>
                        <Grid container sx={{ mt: 0 }} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    fullWidth
                                    label={t("register.subsName")}
                                    name="practice_name"
                                    required={true}
                                    onChange={(event) => handleChangeUserData(event)}
                                    value={userData.practice_name ?? ""}
                                    variant="filled"
                                    InputProps={{
                                        fontStyle: "normal",
                                        color: "#131F3E",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LightTooltip title={t("register.tooltipPractice")}>
                                                    <InfoIcon
                                                        fontSize="inherit"
                                                        sx={{ color: "#4A22D4", cursor: "pointer" }}
                                                    />
                                                </LightTooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={
                                        (isError || errorEditMode) &&
                                        (userData.practice_name === "" || !userData.practice_name)
                                            ? true
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomFilledSelect
                                    error={isError && !userData.subscription_type}
                                    required
                                    label={t("register.subsType")}
                                    value={userData.subscription_type ?? ""}
                                    onChange={(event) => handleChangeUserData(event)}
                                    name="subscription_type"
                                    values={subscriptionType}
                                    idName="id"
                                    valueName="name"
                                />
                            </Grid>
                            <GridUI item xs={12} md={6}>
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                    error={
                                        (isError || errorEditMode) && (userData.country === "" || !userData.country)
                                            ? true
                                            : false
                                    }
                                    required
                                >
                                    <InputLabel
                                        id="type-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("register.country")}
                                    </InputLabel>
                                    <Select
                                        IconComponent={(props) => {
                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        name="country"
                                        value={userData.country ?? ""}
                                        onChange={(event) => handleChangeUserData(event)}
                                        fullWidth
                                        input={
                                            <FilledInput
                                                label={t("register.country")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    maxHeight: "300px",
                                                },
                                            },
                                        }}
                                    >
                                        {allCountries.map((option) => (
                                            <MenuItem value={option.code} key={option.code}>
                                                {sessionStorage.getItem("lng") === "en" || language === "en"
                                                    ? option.name_en
                                                    : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridUI>
                        </Grid>
                        <Typography fontWeight={600} sx={{ mt: 4, fontSize: 16 }}>
                            {t("register.firstCompanyInfo")}
                        </Typography>
                        <Paper elevation={0}>
                            <FormControlLabel
                                control={<Checkbox checked={inheritData} onChange={handleChangeInherit} size="small" />}
                                label={<Typography>{t("register.copySubsInfo")}</Typography>}
                            />
                        </Paper>
                        <Grid container sx={{ mt: 0 }} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    fullWidth
                                    label={t("register.companyName")}
                                    name="company_name"
                                    required={true}
                                    onChange={(event) => handleChangeUserData(event)}
                                    value={userData.company_name ?? ""}
                                    variant="filled"
                                    error={
                                        (isError || errorEditMode) &&
                                        (userData.company_name === "" || !userData.company_name)
                                            ? true
                                            : false
                                    }
                                    InputProps={{
                                        endAdornment: inheritData ? (
                                            <InputAdornment position="end">
                                                <LightTooltip title={t("register.tooltipSubsComp")}>
                                                    <InfoIcon
                                                        fontSize="inherit"
                                                        sx={{ color: "#4A22D4", cursor: "pointer" }}
                                                    />
                                                </LightTooltip>
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                />
                            </Grid>
                            <GridUI item xs={12} md={6}>
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                    error={
                                        (isError || errorEditMode) &&
                                        (userData.company_country_name === "" || !userData.company_country_name)
                                            ? true
                                            : false
                                    }
                                    required
                                >
                                    <InputLabel
                                        id="type-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("register.country")}
                                    </InputLabel>
                                    <Select
                                        IconComponent={(props) => {
                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        name="company_country_name"
                                        value={userData.company_country_name ?? ""}
                                        onChange={(event) => handleChangeUserData(event)}
                                        fullWidth
                                        input={
                                            <FilledInput
                                                label={t("register.country")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    maxHeight: "300px",
                                                },
                                            },
                                        }}
                                    >
                                        {allCountries.map((option) => (
                                            <MenuItem value={option.code} key={option.code}>
                                                {sessionStorage.getItem("lng") === "en" || language === "en"
                                                    ? option.name_en
                                                    : option.name_es}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridUI>
                            <Grid item xs={12} md={6}>
                                <CustomFilledSelect
                                    error={isError && !userData.business_activity}
                                    required
                                    label={t("dialog.ecActivity")}
                                    value={userData.business_activity ?? ""}
                                    onChange={(event) => handleChangeUserData(event)}
                                    name="business_activity"
                                    values={bussinessActFinal}
                                    idName="id"
                                    valueName="name"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                    error={isError && userData.tools.length === 0 ? true : false}
                                    required
                                >
                                    <InputLabel
                                        id="type-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("register.accountingSystems")}
                                    </InputLabel>
                                    <Select
                                        IconComponent={(props) => {
                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        name="tools"
                                        multiple
                                        value={userData.tools}
                                        onChange={(event) => handleChangeUserData(event)}
                                        fullWidth
                                        renderValue={(selected) => selected.join(", ")}
                                        input={
                                            <FilledInput
                                                label={t("register.accountingSystems")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    maxHeight: "300px",
                                                },
                                            },
                                        }}
                                    >
                                        {toolsList?.map(({ id, data }) => (
                                            <MenuItem value={data} key={`${id}${data}`}>
                                                <Checkbox checked={userData.tools.includes(data)} />
                                                {data}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {invitation && (
                                <Grid item xs={12}>
                                    <Paper elevation={0} className={classes.message}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkTandC}
                                                    onChange={handleChangeCheck}
                                                    size="small"
                                                />
                                            }
                                            label={
                                                <Typography>
                                                    {t("login.tryText")}{" "}
                                                    <Link
                                                        sx={{ fontWeight: 600, textDecoration: "none" }}
                                                        href={termsLink}
                                                        target="_blank"
                                                    >
                                                        {t("login.tryText3")}
                                                    </Link>{" "}
                                                </Typography>
                                            }
                                        />
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
                {activeStep === 2 && !showCart && (
                    <PlanSubs
                        setTitleModal={setTitleModal}
                        setOpenAlert={setOpenAlert}
                        setAgreeBtnMsg={setAgreeBtnMsg}
                        setDisagreeBtnMsg={setDisagreeBtnMsg}
                        plans={plans}
                        setBodyMess={setBodyMess}
                        setCurrentPlan={setCurrentPlan}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        setPagesMonthly={setPagesMonthly}
                        setPriceMonthly={setPriceMonthly}
                        setPriceMonthlyOG={setPriceMonthlyOG}
                        pagesMonthly={pagesMonthly}
                        priceMonthly={priceMonthly}
                        origin="register"
                        currentPlan={currentPlan}
                        plansInfo={plansInfo}
                    />
                )}
                {activeStep === 3 && (
                    <Integrations
                        smbInfo={smbInfo}
                        setTexto={setTexto}
                        setShow={setShow}
                        setAlertType={setAlertType}
                        integ={integ}
                        setInteg={setInteg}
                    />
                )}
                {!showCart && activeStep !== 2 && (
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        {!editMode && (activeStep === 1 || activeStep === 2 || activeStep === 3) && (
                            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                {t("register.back")}
                            </Button>
                        )}
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                            variant="contained"
                            onClick={() => handleNext()}
                            disabled={activeStep === 0 ? disabledButton : invitation ? !checkTandC : disabledButton}
                        >
                            {editMode
                                ? t("register.saveChanges")
                                : t(
                                      activeStep === 3
                                          ? integ === 3
                                              ? "register.continueWithoutInteg"
                                              : "register.continue"
                                          : "register.continue"
                                  )}
                        </Button>
                    </Box>
                )}
                <DialogChangeEmail
                    open={openConfirmPassword}
                    onClose={handleCloseConfirmPass}
                    setConfirmChangeEmail={setConfirmChangeEmail}
                    params={paramsChangeMail}
                    setOpenBackdrop={setOpenBackdrop}
                    isError={isError}
                    setShow={setShow}
                    setTexto={setTexto}
                    setIsError={setIsError}
                    setAlertType={setAlertType}
                    texto={texto}
                    password={confirmPassword}
                    setPassword={setConfirmPassword}
                    existingEmail={existingEmail}
                />
                <DialogSaveNewEmail open={confirChangeEmail} onClose={handleCloseConfirmEmail} />
                <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
                <SimpleBackdrop open={openBackdrop} />
                <AlertDialog
                    open={openAlert}
                    onClose={handleCloseDialog}
                    agreeBtnLabel={agreeBtnMsg}
                    disagreeBtnLabel={disagreeBtnMsg}
                    type="info2"
                    title={titleModal}
                    message={currentPlan.value === 3 && currentPlan.custom === true ? customForm : bodyMess}
                    agreeAction={
                        currentPlan.value === 0
                            ? redirectAfterSubmit
                            : currentPlan.value === 1 ||
                              currentPlan.value === 2 ||
                              (currentPlan.value === 3 && !currentPlan.custom)
                            ? handleShowCart
                            : handleSubmitCustomPlan
                    }
                    maxWidth="sm"
                    origin="converter"
                    onlyAction={true}
                    oneAction={
                        currentPlan.value === 1 ||
                        currentPlan.value === 2 ||
                        (currentPlan.value === 3 && !currentPlan.custom)
                            ? updateFreePlan
                            : handleCloseDialog
                    }
                />
            </Paper>
            {showCart && (
                <>
                    <Stack direction="row" spacing={2} justifyContent="space-between" mt={2}>
                        <Button variant="outlined" onClick={showStep3} sx={{ width: 170 }}>
                            {t("miscellaneous.goBack")}
                        </Button>
                        <Button onClick={handleClickHelp}>{t("accounts.needHelp")}</Button>
                    </Stack>
                    <ShoppingCartReg
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        pagesMonthly={pagesMonthly}
                        priceMonthly={priceMonthly}
                        priceMonthlyOG={priceMonthlyOG}
                        setPriceMonthly={setPriceMonthly}
                        setActiveStep={setActiveStep}
                        showStep3={showStep3}
                        userEmail={userEmail}
                        setIsLoading={setOpenBackdrop}
                        origin="register"
                        allCompanies={[]}
                        plansInfo={plansInfo}
                        smbInfo={smbInfo}
                        finalF={finalF}
                    />
                </>
            )}
        </Container>
    );
};

export default UserRegister;

import { useEffect, useState } from "react";
import UpdateCompanyDialog from "./UpdateCompanyDialog";
import AlertDialog from "../ui/AlertDialog";
import companyService from "../../services/company";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addCompanyID, addFranchiseID } from "../../redux/userSlice";
import { useSearchParams } from "react-router-dom";

const EditCompany = (props) => {
    const {
        setShowModalConfig,
        setIsLoading,
        companyID,
        franchiseID,
        setReload,
        setShow,
        setTexto,
        setAlertType,
        showModalConfig,
        setSelectedCompany,
        setSelectedFranchise,
        subscription,
        companyRedirect,
        setCompanyRedirect
    } = props;

    /* React redux */
    const dispatch = useDispatch();

    const [companyData, setCompanyData] = useState({});
    const [t] = useTranslation("global");
    const [openAlert, setOpenAlert] = useState(false);
    const [titleDialog, setTitleDialog] = useState("");
    const [messageDialog, setMessageDialog] = useState("");
    const [companyFirm, setCompanyFirm] = useState(false);
    //const navigate = useNavigate();
    const [firmSettings, setFirmSettings] = useState(false);
    const [typeDialog, setTypeDialog] = useState("");
    const [actions, setActions] = useState(() => { });

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!firmSettings) {
            if (franchiseID && !companyID) {
                setTypeDialog("Firm")
            } else {
                setTypeDialog("Smb")
            }
        }
    }, [companyID, firmSettings, franchiseID]);

    useEffect(() => {
        (async () => {
            if (showModalConfig && !firmSettings) {
                setIsLoading(true);

                if (typeDialog === "Smb") {
                    await companyService
                        .getCompanyDetails(companyID)
                        .then((data) => {
                            setCompanyData(data);
                            if (data.franchise_id && data.franchise_id !== null && data.franchise_id?._id) {
                                setCompanyFirm(true);
                            };
                        })
                        .catch((error) => {
                            console.log(error)
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                } else {
                    await companyService
                        .getFranchiseDetailsCustom(franchiseID)
                        .then((data) => {
                            setCompanyData(data);
                        })
                        .catch((error) => {
                            console.log(error)
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                }

                setIsLoading(false);
            }
        })();
    }, [companyID, firmSettings, franchiseID, setAlertType, setIsLoading, setShow, setTexto, showModalConfig, typeDialog]);

    const handleCloseDialog = () => {
        searchParams.delete("connected");
        searchParams.delete("connect");
        searchParams.delete("company_id");
        searchParams.delete("redirectType");
        setSearchParams(searchParams, { replace: true });
        setOpenAlert(false);
        setCompanyData({});
        if (companyRedirect) {
            setCompanyRedirect(null)
        }
    };

    const deleteCompany = async (id) => {
        setIsLoading(true);
        if (typeDialog === "Firm") {
            const params = {
                franchise_id: id,
            };
            await companyService
                .disableCompany(params)
                .then(() => {
                    dispatch(addFranchiseID(null));
                    dispatch(addCompanyID(null));
                    handleCloseDialog();
                    setTexto(t("dialog.successDeleteFirm"));
                    setAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                });
        } else {
            const params = {
                company_id: id,
            };
            await companyService
                .disableCompany(params)
                .then(() => {
                    dispatch(addFranchiseID(null));
                    dispatch(addCompanyID(null));
                    handleCloseDialog();
                    setTexto(t("dialog.successDeleteSmb"));
                    setAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                });
        }
        setReload((prevState) => !prevState);
        setIsLoading(false);
    };

    const handleOnClose = () => {
        searchParams.delete("connected");
        searchParams.delete("connect");
        searchParams.delete("company_id");
        searchParams.delete("redirectType");
        setSearchParams(searchParams, { replace: true });
        setTypeDialog((franchiseID && !companyID) ? "Firm" : "Smb");
        setFirmSettings(false);
        setShowModalConfig(false);
        setCompanyData({});
        if (companyRedirect) {
            setCompanyRedirect(null)
        }
    };

    return (
        <>
            {Object.keys(companyData).length > 0 && (
                <UpdateCompanyDialog
                    open={showModalConfig}
                    setOpenUpdate={setShowModalConfig}
                    data={companyData}
                    type={typeDialog}
                    setTitleDialog={setTitleDialog}
                    setOpenAlert={setOpenAlert}
                    setMessageDialog={setMessageDialog}
                    setIsLoading={setIsLoading}
                    setReload={setReload}
                    onClose={handleOnClose}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    setShow={setShow}
                    setShowModalConfig={setShowModalConfig}
                    setSelectedCompany={setSelectedCompany}
                    setSelectedFranchise={setSelectedFranchise}
                    subscription={subscription}
                    companyRedirect={companyRedirect}
                    companyFirm={companyFirm}
                    setCompanyData={setCompanyData}
                    setFirmSettings={setFirmSettings}
                    setTypeDialog={setTypeDialog}
                    setActions={setActions}
                    deleteCompany={deleteCompany}
                    handleCloseDialog={handleCloseDialog}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    setCompanyRedirect={setCompanyRedirect}
                    setCompanyFirm={setCompanyFirm}
                />
            )}
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={titleDialog}
                message={messageDialog}
                agreeAction={actions}
                maxWidth="sm"
                justifyContent={"space-around"}
            />
        </>
    );
};

export default EditCompany;

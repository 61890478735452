import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addCompanyID } from "../../../redux/userSlice";

import CheckIcon from "@mui/icons-material/Check";
import Visa from "../../../assets/visa.svg";
import Mastercard from "../../../assets/mastercard.svg";
import Amex from "../../../assets/amex.svg";
import Discover from "../../../assets/discover.svg";
import Diners from "../../../assets/diners.svg";
import JCB from "../../../assets/jcb.svg";
import UnionPay from "../../../assets/unionpay.svg";

import moment from "moment/moment";
import PlanSubs from "../../subscription/plans/PlansMain";
import ShoppingCartReg from "../../subscription/shoppingCart/ShoppingCartReg";
import AlertDialog from "../../ui/AlertDialog";
import Integrations from "../../subscription/Integrations";
import subscriptionService from "../../../services/subscription";
import authService from "../../../services/auth";
import companyService from "../../../services/user_company";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const CompanyDet = (props) => {
    const {
        setOpenCompanyDet,
        companyDetails,
        setShowCompanyDetails,
        allCompanies,
        plansInfo,
        activeStep,
        setActiveStep,
        smbInfo,
        setShow,
        setTexto,
        setAlertType,
        setIsLoading,
        setCompanyDetails
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const dispatch = useDispatch();

    const [openAlert, setOpenAlert] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [bodyMess, setBodyMess] = useState("");
    const [agreeBtnMsg, setAgreeBtnMsg] = useState(t("dialog.continue"));
    const [disagreeBtnMsg, setDisagreeBtnMsg] = useState(t("dialog.cancel"));
    const [questionCustom, setQuestionCustom] = useState({
        question1: "",
        question2: "",
        question3: "",
    });
    const [currentPlan, setCurrentPlan] = useState({
        value: 0,
        custom: false,
    });
    const [paymentType, setPaymentType] = useState("monthly");
    const [pagesMonthly, setPagesMonthly] = useState("");
    const [priceMonthly, setPriceMonthly] = useState("");
    const [priceMonthlyOG, setPriceMonthlyOG] = useState("");
    const [showCart, setShowCart] = useState(false);
    const [showPlans, setShowPlans] = useState(false);
    const [integ, setInteg] = useState(companyDetails.system_integration);
    const [errorCustomPlan, setErrorCustomPlan] = useState(false);

    const plans = [
        { id: 1, name: "micro" },
        { id: 2, name: "small" },
        { id: 3, name: "medium" },
        { id: 4, name: "enterprise" },
    ];

    const goBack = () => setOpenCompanyDet(false);

    const goToSettings = () => {
        dispatch(addCompanyID(companyDetails._id));
        setShowCompanyDetails(true);
    };

    const allPlans = [
        "Micro",
        `Small`,
        `Small`,
        `Small`,
        `Small`,
        `Medium`,
        `Medium`,
        `Medium`,
        `Medium`,
        `Enterprise`,
        `Enterprise`,
        `Enterprise`,
        t("plans.customize"),
    ];

    const allPlansPages = [
        "",
        `200 ${t("plans.pageMonth")}`,
        `300 ${t("plans.pageMonth")}`,
        `400 ${t("plans.pageMonth")}`,
        `500 ${t("plans.pageMonth")}`,
        `1000 ${t("plans.pageMonth")}`,
        `1500 ${t("plans.pageMonth")}`,
        `2000 ${t("plans.pageMonth")}`,
        `2500 ${t("plans.pageMonth")}`,
        `5000 ${t("plans.pageMonth")}`,
        `10000 ${t("plans.pageMonth")}`,
        `15000 ${t("plans.pageMonth")}`,
        "",
    ];

    const prices = [0, 49, 79, 99, 129, 199, 299, 399, 499, 749, 1499, 2249];

    const planFeatures = ["Onboarding", t("plans.training"), t("plans.channels"), t("plans.people")];

    const planFeatures2 = [t("plans.users1"), t("plans.panelMonit"), t("plans.productUpda"), t("plans.exten")];

    const getFeatures = (featureName, plan) => {
        let newText = "";
        if (featureName === "Onboarding") {
            if (plan === 0) {
                newText = t("plans.essential");
            } else if (plan === 12) {
                newText = t("plans.customize");
            } else {
                newText = t("plans.standar");
            }
        }
        if (featureName === t("plans.training")) {
            if ([0, 1, 2, 3, 4].includes(plan)) {
                newText = t("plans.knowlBase");
            } else {
                newText = t("plans.customize");
            }
        }
        if (featureName === t("plans.channels")) {
            if ([0, 1, 2, 3, 4].includes(plan)) {
                newText = t("plans.inTheApp");
            } else if ([5, 6, 7, 8].includes(plan)) {
                newText = t("plans.appMail");
            } else {
                newText = t("plans.appMailPhone");
            }
        }
        if (featureName === t("plans.people")) {
            if ([9, 10, 11, 12].includes(plan)) {
                newText = t("plans.managerAcc");
            } else {
                newText = t("plans.specialCustomServ");
            }
        }
        if (featureName === t("plans.users1")) {
            if (plan === 0) {
                newText = `2 ${t("plans.users")}`;
            } else {
                newText = t("plans.unlimitedUpp");
            }
        }
        if (featureName === t("plans.panelMonit")) {
            if (plan === 0) {
                newText = t("dashboard.unavailable");
            } else {
                newText = t("plans.converCon");
            }
        }
        if (featureName === t("plans.productUpda")) {
            if ([0, 1, 2, 3, 4].includes(plan)) {
                newText = t("plans.standar");
            } else {
                newText = t("inbox.priority");
            }
        }
        if (featureName === t("plans.exten")) {
            if (plan === 0) {
                newText = `0 ${t("miscellaneous.days")}`;
            } else if ([1, 2, 3, 4].includes(plan)) {
                newText = `30 ${t("miscellaneous.days")}`;
            } else if ([5, 6, 7, 8].includes(plan)) {
                newText = `60 ${t("miscellaneous.days")}`;
            } else {
                newText = `90 ${t("miscellaneous.days")}`;
            }
        }
        return newText;
    };

    const showStep3 = () => {
        setShowCart(false);
    };

    const handlePlans = () => {
        showStep3();
        setShowPlans(true);
    };

    const handleCloseDialog = () => {
        setQuestionCustom({
            question1: "",
            question2: "",
            question3: "",
        });
        setOpenAlert(false);
    };

    const handleChangeQuestions = (event) => {
        const { name, value } = event.target;

        setQuestionCustom((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const customForm = (
        <>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                }}
            >
                {t("register.customCondMsg1")}
            </Typography>
            <Grid container spacing={1} mt={1} mb={2}>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.customCondQues1")}
                        name="question1"
                        onChange={handleChangeQuestions}
                        value={questionCustom.question1}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                        error={errorCustomPlan && !questionCustom.question1}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.customCondQues2")}
                        name="question2"
                        onChange={handleChangeQuestions}
                        value={questionCustom.question2}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                        error={errorCustomPlan && !questionCustom.question2}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.customCondQues3")}
                        name="question3"
                        onChange={handleChangeQuestions}
                        value={questionCustom.question3}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                        error={errorCustomPlan && !questionCustom.question3}
                    />
                </Grid>
            </Grid>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                }}
                dangerouslySetInnerHTML={{
                    __html: t("register.customCondMsg2"),
                }}
            ></Typography>
        </>
    );

    const handleShowCart = async () => {
        handleCloseDialog(false);

        let subsPlan = plansInfo.flatMap((plan) => plan.levels).find((level) => level.pages === pagesMonthly).id
        let activePlan = companyDetails.subscription.subscription_plan;
        if (activePlan && activePlan >= 0) {
            if (activePlan > subsPlan) {
                //Downgrade
                const newVAl = {
                    subscription_plan: subsPlan,
                    line_type: 3,
                    line_amount: paymentType === "annual" ? 12 * priceMonthly : priceMonthly,
                    line_unit_amount: priceMonthly,
                    line_qty: paymentType === "annual" ? 12 : 1,
                    line_since_date: moment().format(),
                    line_end_date: moment().add(1, "M").format(),
                };

                const params = {
                    company_id: companyDetails._id,
                    cart_lines: [newVAl],
                };

                setIsLoading(true);

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setIsLoading(false);
                setShowCart(true);
            } else if (activePlan < subsPlan) {
                //Upgrade
                const newVAl = {
                    subscription_plan: subsPlan,
                    line_type: 4,
                    line_amount: paymentType === "annual" ? 12 * priceMonthly : priceMonthly,
                    line_unit_amount: priceMonthly,
                    line_qty: paymentType === "annual" ? 12 : 1,
                    line_since_date: moment().format(),
                    line_end_date: moment().add(1, "M").format(),
                };

                const params = {
                    company_id: companyDetails._id,
                    cart_lines: [newVAl],
                };

                setIsLoading(true);

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setIsLoading(false);
                setShowCart(true);
            }
        } else {
            const newVAl = {
                subscription_plan: subsPlan,
                line_type: 1,
                line_amount: paymentType === "annual" ? 12 * priceMonthly : priceMonthly,
                line_unit_amount: priceMonthly,
                line_qty: paymentType === "annual" ? 12 : 1,
                line_since_date: moment().format(),
                line_end_date: moment().add(1, "M").format(),
            };
    
            const params = {
                company_id: companyDetails._id,
                cart_lines: [newVAl],
            };
    
            setIsLoading(true);
    
            await subscriptionService
                .updateCart(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });
    
            setIsLoading(false);
            setShowCart(true);
        }
    };

    const handleSubmitCustomPlan = async () => {
        if (questionCustom.question1 === "" || questionCustom.question2 === "" || questionCustom.question3 === "") {
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
            setErrorCustomPlan(true)
        } else {
            setErrorCustomPlan(false)
            handleCloseDialog()
            setIsLoading(true)
            const paramsC = {
                company_id: companyDetails._id,
                origin: 3,
                question1: questionCustom.question1,
                question2: questionCustom.question2,
                question3: questionCustom.question3,
            }

            await authService
                .createCustomRequest(paramsC)
                .then(async () => {
                    await updateCompany()
                })
                .catch((error) => console.log(error.response));
            setIsLoading(false)
        }
    };

    const updateCompany = async () => {
        
        setIsLoading(true);
        await companyService
            .getBusinessDirectoryDetails({ company_id: companyDetails._id })
            .then((data) => {
                setShowCart(false)
                setShowPlans(false)
                setOpenCompanyDet(true)
                setActiveStep(0)
                setCompanyDetails(data);
                setOpenCompanyDet(true);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const cancelPlan = async () => {
        const newVAl = {
            subscription_plan: companyDetails.subscription.subscription_plan,
            line_type: 7,
            line_amount: 0.0,
            line_unit_amount: 0.0,
            line_qty: 1,
            line_since_date: moment().format(),
            line_end_date: moment().add(1, "M").format(),
        };

        const params = {
            company_id: companyDetails._id,
            cart_lines: [newVAl],
        };

        setCurrentPlan({
            value: companyDetails.subscription.subscription_plan,
            custom: companyDetails.subscription.subscription_plan === 12,
            endDate: companyDetails.subscription.end_date,
            type: "cancel",
        });

        const pages = plansInfo
            .flatMap((plan) => plan.levels)
            .find((level) => level.id === companyDetails.subscription.subscription_plan)?.pages;

        setPriceMonthlyOG(0);
        setPagesMonthly(pages);

        setIsLoading(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setShowCart(true);
    };

    const checkRequestCustom = (req, res) => {
        return "request" in companyDetails
    }

    return (
        <>
            {!showCart && (
                <Button variant="outlined" onClick={goBack} sx={{ width: 150, mb: 2 }}>
                    {t("miscellaneous.goBack")}
                </Button>
            )}

            {!showPlans && !showCart && (
                <Paper elevation={0} sx={{ p: 4, borderRadius: 3, mt: 2 }}>
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h1" color="primary">
                                {companyDetails.company_name}
                            </Typography>
                            <Typography>
                                {`${t("management.companyCreated")} ${moment
                                    .utc(companyDetails.creation_date)
                                    .format(todos.dateFormat)}`}
                            </Typography>
                        </Stack>
                        <Button variant="outlined" onClick={goToSettings} disabled={checkRequestCustom()}>
                            {t("management.configComp")}
                        </Button>
                    </Stack>
                    <Box
                        sx={{
                            border: `1px solid #ccc`,
                            borderRadius: 3,
                            p: 3,
                            mt: 2,
                        }}
                    >
                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                            <Typography>
                                <span
                                    style={{
                                        fontSize: 18,
                                        color: "#4a22d4",
                                        fontWeight: 600,
                                    }}
                                >
                                    Plan{" "}{checkRequestCustom() ? t("plans.customize") : allPlans[companyDetails.subscription.subscription_plan] ?? t("plans.customize")}{" "}
                                </span>
                                {checkRequestCustom() ? "" : allPlansPages[companyDetails.subscription.subscription_plan] ?? ""}
                            </Typography>
                            <Box>
                                {!checkRequestCustom() && <Button color="error" sx={{ mr: 4 }} onClick={cancelPlan}>
                                    {t("management.cancelPlan")}
                                </Button>}
                                <Button variant="contained" disableElevation sx={{ width: 150 }} onClick={handlePlans} disabled={checkRequestCustom()}>
                                    {t("subscription.changePlan")}
                                </Button>
                            </Box>
                        </Stack>
                        {!checkRequestCustom() && <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <List>
                                    {planFeatures.map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                            <ListItemIcon sx={{ minWidth: 35 }}>
                                                <CheckIcon sx={{ color: "#00B147" }} fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Stack direction="row" spacing={2}>
                                                        <b>{item}</b>{" "}
                                                        <Typography>
                                                            {getFeatures(
                                                                item,
                                                                companyDetails.subscription.subscription_plan
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <List>
                                    {planFeatures2.map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                            <ListItemIcon sx={{ minWidth: 35 }}>
                                                <CheckIcon sx={{ color: "#00B147" }} fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Stack direction="row" spacing={2}>
                                                        <b>{item}</b>{" "}
                                                        <Typography>
                                                            {getFeatures(
                                                                item,
                                                                companyDetails.subscription.subscription_plan
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>}
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            sx={{ backgroundColor: "#f8f8f9", p: 2, mt: checkRequestCustom() ? 2 : 0  }}
                        >
                            {!checkRequestCustom() ?
                            <>
                                <Typography variant="h2">{t("converter.price")}</Typography>
                                <Typography>{`US$ ${parseFloat(
                                    prices[companyDetails.subscription.subscription_plan ?? 0]
                                ).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                })}/${t("converter.month")}`}</Typography>
                            </> : <Typography mt={2} fontWeight={600}>{t("plans.customMsg")}</Typography>}
                        </Stack>
                    </Box>
                    {!checkRequestCustom() && <Box
                        sx={{
                            border: `1px solid #ccc`,
                            borderRadius: 3,
                            p: 3,
                            mt: 2,
                        }}
                    >
                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                            <Box>
                                <Typography variant="h1">{t("payment.paymentMethod")}</Typography>
                                <Typography>
                                    {t("payment.nextPayment")}{" "}
                                    {moment.utc(companyDetails.subscription?.since_date).format(todos.dateFormat)}
                                </Typography>
                            </Box>
                            {companyDetails.payment_method && (
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Box
                                        component="img"
                                        src={
                                            companyDetails.payment_method.brand === "visa"
                                                ? Visa
                                                : companyDetails.payment_method.brand === "mastercard"
                                                ? Mastercard
                                                : companyDetails.payment_method.brand === "amex"
                                                ? Amex
                                                : companyDetails.payment_method.brand === "discover"
                                                ? Discover
                                                : companyDetails.payment_method.brand === "diners"
                                                ? Diners
                                                : companyDetails.payment_method.brand === "unionpay"
                                                ? UnionPay
                                                : JCB
                                        }
                                        sx={{ width: 45 }}
                                    />
                                    <Typography variant="h3">
                                        {companyDetails.payment_method.brand.charAt(0).toUpperCase() +
                                            companyDetails.payment_method.brand.slice(1)}{" "}
                                        {t("credits.endingIn")} *
                                        {companyDetails.payment_method.card_number.split("-").pop()}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Box>}
                </Paper>
            )}
            {showPlans && !showCart && (
                <Box
                    sx={{
                        borderRadius: "8px",
                        px: { xs: 3, md: 10 },
                        py: { xs: 3, md: 4 },
                        minWidth: "980px",
                        backgroundColor: "#FFF",
                    }}
                >
                    <Box sx={{ width: "100%" }}>
                        <>
                            {activeStep === 0 && !showCart && showPlans && (
                                <Box mt={3}>
                                    <PlanSubs
                                        setTitleModal={setTitleModal}
                                        setOpenAlert={setOpenAlert}
                                        setAgreeBtnMsg={setAgreeBtnMsg}
                                        setDisagreeBtnMsg={setDisagreeBtnMsg}
                                        plans={plans}
                                        setBodyMess={setBodyMess}
                                        setCurrentPlan={setCurrentPlan}
                                        paymentType={paymentType}
                                        setPaymentType={setPaymentType}
                                        setPagesMonthly={setPagesMonthly}
                                        setPriceMonthly={setPriceMonthly}
                                        setPriceMonthlyOG={setPriceMonthlyOG}
                                        pagesMonthly={pagesMonthly}
                                        priceMonthly={priceMonthly}
                                        origin="editCompany"
                                        activePlan={companyDetails.subscription.subscription_plan}
                                        currentPlan={currentPlan}
                                        plansInfo={plansInfo}
                                        setIsLoading={setIsLoading}
                                        setShowCart={setShowCart}
                                        companyDetails={companyDetails}
                                    />
                                </Box>
                            )}
                            {activeStep === 1 && (
                                <Box mt={3}>
                                    <Integrations
                                        smbInfo={smbInfo}
                                        setTexto={setTexto}
                                        setShow={setShow}
                                        setAlertType={setAlertType}
                                        integ={integ}
                                        setInteg={setInteg}
                                        origin="company"
                                    />
                                </Box>
                            )}
                        </>
                    </Box>
                </Box>
            )}
            {activeStep === 0 && showCart && (
                <>
                    <Stack direction="row" spacing={2} justifyContent="space-between" mt={2}>
                        <Button variant="outlined" onClick={showStep3} sx={{ width: 170 }}>
                            {t("miscellaneous.goBack")}
                        </Button>
                        <Button
                        //onClick={handleClickHelp}
                        >
                            {t("accounts.needHelp")}
                        </Button>
                    </Stack>
                    {allCompanies.length >= 11 && (
                        <Box
                            my={2}
                            sx={{
                                backgroundColor: "#FFF",
                                px: 5,
                                py: 2.5,
                                borderRadius: "8px",
                                width: "100%",
                                // maxWidth: 450,
                                // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                            }}
                        >
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="column" spacing={0.5}>
                                    <Typography>
                                        Tu descuento por cantidad de empresas creadas ya está reflejado en el carrito.
                                    </Typography>
                                    <Typography fontWeight={600}>
                                        ¡Sigue creando más empresas para obtener mayores descuentos!
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Stack direction="column" spacing={0}>
                                        <Typography textAlign="end" fontSize={18} color="primary">
                                            {allCompanies.length} empresas creadas
                                        </Typography>
                                        <Typography textAlign="end" color="primary">
                                            11 - 50 empresas
                                        </Typography>
                                    </Stack>
                                    <Typography fontSize={24} color="#ff5e77" fontWeight={600}>
                                        (-15%)
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    )}
                    <ShoppingCartReg
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        pagesMonthly={pagesMonthly}
                        priceMonthly={priceMonthly}
                        priceMonthlyOG={priceMonthlyOG}
                        setPriceMonthly={setPriceMonthly}
                        setActiveStep={setActiveStep}
                        showStep3={showStep3}
                        origin="editCompany"
                        allCompanies={allCompanies}
                        plansInfo={plansInfo}
                        activePlan={companyDetails.subscription.subscription_plan}
                        //userEmail={userEmail}
                        setIsLoading={setIsLoading}
                        smbInfo={companyDetails}
                        finalF={updateCompany}
                    />
                </>
            )}
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={agreeBtnMsg}
                disagreeBtnLabel={disagreeBtnMsg}
                type="info2"
                title={titleModal}
                message={currentPlan.value === 3 && currentPlan.custom === true ? customForm : bodyMess}
                agreeAction={
                    !currentPlan.custom
                        ? currentPlan.value === 0
                            ? updateCompany
                            : handleShowCart
                        : handleSubmitCustomPlan
                }
                maxWidth="sm"
                origin="converter"
                onlyAction={true}
                oneAction={handleCloseDialog}
            />
        </>
    );
};

export default CompanyDet;

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import conversionService from "../../../services/conversion";

import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Popper,
    Select,
    Stack,
    styled,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";

import XeroLogo from "../../../assets/xero.svg";
import SyncIcon from "@mui/icons-material/Sync";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReplyIcon from "@mui/icons-material/Reply";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import WarningIcon from "@mui/icons-material/WarningRounded";
import EditIcon from "@mui/icons-material/Edit";

import LightTooltip from "../../ui/LightTooltip";
import InvoicesSyncTable from "./InvoicesSyncTable";
import InvoiceSlider from "./InvoiceSlider";
import SimpleDialog from "../../ui/SimpleDialog";
import FormDialog from "../../ui/FormDialog";
import EditContactChecks from "../checks/EditContactChecks";

const checkIconDown = (props) => {
    if (props.className.includes("MuiSelect-iconOpen")) {
        return (
            <PlayArrowIcon
                sx={{
                    position: "absolute",
                    transform: "rotate(270deg)",
                    color: "#131F3E",
                    right: ".5rem",
                    cursor: "pointer",
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            />
        );
    }
    return (
        <PlayArrowIcon
            sx={{
                position: "absolute",
                transform: "rotate(90deg)",
                color: "#131F3E",
                right: ".5rem",
                cursor: "pointer",
                zIndex: 0,
                pointerEvents: "none",
            }}
        />
    );
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const greyBoxStyles = {
    backgroundColor: "#F8F8F9",
    px: 3,
    py: 2,
    borderRadius: 4,
    mt: 2,
};

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 300,
    mr: 3,
    backgroundColor: "#f6f4fd",
    borderRadius: 3,
    minHeight: 35,
    height: 35,
    color: "#131F3E !important",
    "&.Mui-selected": {
        backgroundColor: "#c9bdf2",
        fontWeight: 600,
        borderRadius: 3,
    },
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
    [`& .${autocompleteClasses.paper}`]: {
        borderRadius: "15px",
    },
});

const InvoicesSync = (props) => {
    const {
        setIsLoading,
        handleGoBack,
        downloadInv,
        invoicesData,
        returnInvoiceModal,
        setOpenPreview,
        setFileData,
        handleHistSync,
        setBasePreviewData,
        setOpenHeavy,
        setTexto,
        setAlertType,
        setShow,
        replaceInvBill,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const filteredInvoicesData = JSON.parse(JSON.stringify(invoicesData));

    const invoicesOriginal =
        filteredInvoicesData.invoices?.length > 0
            ? filteredInvoicesData.invoices.filter(
                  (item) => item.id_status !== 2 && item.id_status !== 5 && item.id_status !== 6
              )
            : [{ ...filteredInvoicesData }];

    const [status, setStatus] = useState(0);
    const [invoiceData, setInvoiceData] = useState(
        invoicesOriginal?.length > 0 ? invoicesOriginal[0] : { ...invoicesOriginal }
    );
    const [invoices, setInvoices] = useState(
        invoicesOriginal?.length > 0 ? invoicesOriginal : [{ ...invoicesOriginal }]
    );

    const [selectedAllAcc, setSelectedAllAcc] = useState("");
    const [selectedContact, setSelectedContact] = useState({});
    const [accountsXero, setAccountsXero] = useState([]);
    const [contactsXero, setContactsXero] = useState([]);
    const [xeroTaxRates, setXeroTaxRates] = useState([]);
    const [xeroItems, setXeroItems] = useState([]);
    const [contactsXeroOriginal, setContactsXeroOriginal] = useState([]);
    const [valueTab, setValueTab] = useState(0);
    const [showModalLeftSync, setShowModalLeftSync] = useState(false);
    const [contactXeroUpdated, setContactXeroUpdated] = useState("");
    const [extContactName, setExtContactName] = useState("");
    const [openModalEditContact, setOpenModalEditContact] = useState(false);
    const [edited, setEdited] = useState([]);
    const [rowsAux, setRowsAux] = useState([]);
    const [newRowDiff, setNewRowDiff] = useState(false);
    const [toBeCompleted, setToBeCompleted] = useState([]);
    const [toReview, setToReview] = useState([]);
    const [readyToSync, setReadyToSync] = useState([]);
    const [editGlobal, setEditGlobal] = useState(false);

    const sumSubtotalTax = (obj) => {
        return (
            parseFloat(obj.formatted_amount ?? obj.amount_xero ?? 0) +
            parseFloat(obj.tax_amount ?? obj.tax_amount_xero ?? 0)
        );
    };

    const isGreaterExt =
        rowsAux.reduce((sum, obj) => sum + sumSubtotalTax(obj), 0) < invoiceData.formatted_invoice_total;

    const isEqual =
        invoiceData.formatted_invoice_total ===
        (edited.length > 0
            ? parseFloat(rowsAux.reduce((sum, obj) => sum + sumSubtotalTax(obj), 0).toFixed(2))
            : parseFloat(invoiceData.line_items?.reduce((sum, obj) => sum + sumSubtotalTax(obj), 0).toFixed(2)));

    useEffect(() => {
        const approximatelyEqual = (v1, v2, epsilon = 0.01) => Math.abs(v1 - v2) < epsilon;

        // Listo para sincronizar
        let filter3 = invoices.filter((item) => {
            const lines = item?.line_items ?? [];
            const count = lines.length;
            return (lines?.filter((item) => item.account_code_xero !== "" ?? item.account_code !== "").length === count) && (lines?.filter((item) => item.quantity_xero !== "" ?? item.quantity !== "").length === count) && (lines?.filter((item) => item.unit_price_xero !== "" ?? item.unit_price !== "").length === count) && (item.contactXeroID !== undefined || (item.conversion_type === 1 ? item.customer_name !== undefined : item.vendor_name !== undefined)) && (edited.length > 0 ? approximatelyEqual(rowsAux.reduce((sum, obj) => sum + sumSubtotalTax(obj), 0), item.formatted_invoice_total ) : approximatelyEqual(lines?.reduce((sum, obj) => sum + sumSubtotalTax(obj), 0), item.formatted_invoice_total ))
        })

        // Por revisar
        let filter2 = invoices.filter((item) => {
            const lines = item?.line_items ?? [];
            const count = lines.length;
            return (lines?.filter((item) => item.account_code_xero !== "" ?? item.account_code !== "").length === count) && (lines?.filter((item) => item.quantity_xero !== "" ?? item.quantity !== "").length === count) && (lines?.filter((item) => item.unit_price_xero !== "" ?? item.unit_price !== "").length === count) && (item.contactXeroID !== undefined || (item.conversion_type === 1 ? item.customer_name !== undefined : item.vendor_name !== undefined)) && (edited.length > 0 ? !approximatelyEqual(rowsAux.reduce((sum, obj) => sum + sumSubtotalTax(obj), 0), item.formatted_invoice_total ) : !approximatelyEqual(lines?.reduce((sum, obj) => sum + sumSubtotalTax(obj), 0), item.formatted_invoice_total ))
        })

        let filter1 = invoices.filter((item) => {
            return !filter2.some((item2) => item2._id === item._id) && !filter3.some((item2) => item2._id === item._id)
        })
        
        // Revisar si hay cambios en general
        if (JSON.stringify(toBeCompleted) !== JSON.stringify(filter1)) {
            // Cambios de ids
            if ((JSON.stringify(toBeCompleted.map((item) => item._id)) !== JSON.stringify(filter1.map((item) => item._id)))) {
                setToBeCompleted(filter1);
                if (valueTab === 0) {
                    if (filter1.length > 0) {
                        setInvoiceData(filter1[0])
                    } else {
                        setInvoiceData({})
                    }
                }
            } else {
                setToBeCompleted(filter1);
            }
        }
        if (JSON.stringify(toReview) !== JSON.stringify(filter2)) {
            // Cambios de ids
            if (JSON.stringify(toReview.map((item) => item._id)) !== JSON.stringify(filter2.map((item) => item._id))) {
                setToReview(filter2);
                if (valueTab === 1) {
                    if (filter2.length > 0) {
                        setInvoiceData(filter2[0])
                    } else {
                        setInvoiceData({})
                    }
                }
            } else {
                setToReview(filter2);
            }
        }

        if (JSON.stringify(readyToSync) !== JSON.stringify(filter3)) {
            if (JSON.stringify(readyToSync.map((item) => item._id)) !== JSON.stringify(filter3.map((item) => item._id))) {
                setReadyToSync(filter3);
                if (valueTab === 2) {
                    if (filter3.length > 0) {
                        setInvoiceData(filter3[0])
                    } else {
                        setInvoiceData({})
                    }
                }
            } else {
                setReadyToSync(filter3);
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices]); // Añadir valueTab como dependencia

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            await conversionService
                .getChartOfAccounts({
                    company_id: todos.companyID,
                    type: 1,
                })
                .then((data) => {
                    setAccountsXero(data.map((item) => ({ value: item.Code, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getContacts({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    setContactsXeroOriginal(data.map((item) => ({ value: item.ContactID, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getTaxRates({
                    company_id: todos.companyID,
                    type: 2, // 2 o 3
                })
                .then((data) => {
                    setXeroTaxRates(
                        data.map((item) => ({
                            value: item.TaxType,
                            name: `${item.Name} - ${item.EffectiveRate}%`,
                            taxRate: item.EffectiveRate,
                            EffectiveRate: item.EffectiveRate,
                            isZeroTax: item.TaxComponents[0].Name === "No Tax",
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getXeroItems({
                    company_id: todos.companyID,
                    type: 1,
                })
                .then((data) => {
                    setXeroItems(data.map((item) => ({ value: item.ItemID, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            setIsLoading(false);
        })();
    }, [setIsLoading, todos.companyID]);

    useEffect(() => {
        if (contactsXeroOriginal.length > 0) {
            let newContacts = [];
            const selectedContact = {
                value: "first",
                name: invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name,
            };

            if (
                contactsXeroOriginal.find(
                    (item) =>
                        item.name ===
                        (invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name)
                )
            ) {
                newContacts = contactsXeroOriginal;
                selectedContact.value = contactsXeroOriginal.find(
                    (item) =>
                        item.name ===
                        (invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name)
                ).value;
            } else {
                newContacts = [
                    {
                        value: "first",
                        name: invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name,
                    },
                    ...contactsXeroOriginal,
                ];
            }

            setContactsXero(newContacts);
            setSelectedContact(selectedContact);
        }
    }, [contactsXeroOriginal, invoiceData.conversion_type, invoiceData.customer_name, invoiceData.vendor_name]);

    const handleChangeSelectAcc = (event) => {
        setSelectedAllAcc(event.target.value);
        setInvoices(
            invoices.map((aux) => ({
                ...aux,
                line_items: aux.line_items.map((item) => ({ ...item, account_code: event.target.value })),
            }))
        );
        setInvoiceData({
            ...invoiceData,
            line_items: invoiceData.line_items.map((aux) => ({ ...aux, account_code: event.target.value })),
            global_acc_code: event.target.value,
        });
    };

    const handleChangeSelectContact = (newValue) => {
        setSelectedContact(newValue);
        if (newValue !== null) {
            setInvoices(
                invoices.map((aux) => {
                    if (aux._id === invoiceData._id) {
                        aux.contactXeroID = newValue.value;
                        aux.customer_name = newValue.name;
                    }
                    return aux;
                })
            );
        }
    };

    const handleCloseSyncModal = () => setShowModalLeftSync(false);

    //Funcion de sincronizacion
    const syncInvoices = async () => {
        let readyToSyncAmounts = readyToSync.concat(toReview);

        if (readyToSyncAmounts.length !== invoicesOriginal.length) {
            setShowModalLeftSync(true);
        } else {
            setIsLoading(true);

            // Sincronizamos las facturas
            const params = {
                invoices: readyToSyncAmounts.map((item) => ({
                    _id: item._id,
                    ContactXeroID: item.contactXeroID,
                    ContactXeroName: item.conversion_type === 1 ? item.customer_name : item.vendor_name,
                    line_items: JSON.stringify(item.line_items),
                })),
            };

            if (invoicesData?.invoices?.length > 0) {
                params.grouped_invoice_id = invoicesData._id;
            }

            await conversionService
                .syncInvoices(params)
                .then(async (response) => {
                    if (response.data.status === 203) {
                        setIsLoading(false);
                        endSync();
                        setOpenHeavy(true);
                    } else {
                        let invoicesIdSync = readyToSyncAmounts.map((item) => ({ _id: item._id }));
                        await handleHistSync(invoicesIdSync);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            setIsLoading(false);
        }
    };

    const handleClickEditContact = (event, name) => {
        event.stopPropagation();
        setContactXeroUpdated(name);
        setExtContactName(invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name);
        setOpenModalEditContact(true);
    };

    const endSync = () => {
        setStatus(0);
        handleGoBack();
    };

    const handleCloseDialog = (event) => {
        event.preventDefault();
        setOpenModalEditContact(false);
        setContactXeroUpdated("");
    };

    const handleSync = async () => {
        setIsLoading(true);
        // Sincronizamos las facturas
        const params = {
            invoices: readyToSync.map((item) => ({
                _id: item._id,
                ContactXeroID: item.contactXeroID,
                ContactXeroName: item.conversion_type === 1 ? item.customer_name : item.vendor_name,
                line_items: JSON.stringify(item.line_items),
            })),
        };

        await conversionService
            .syncInvoices(params)
            .then(async () => {
                let invoicesIdSync = readyToSync.map((item) => ({ _id: item._id }));
                await handleHistSync(invoicesIdSync);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
        setShowModalLeftSync(false);
    };

    const handleInvoice = (invoice) => {
        setInvoiceData(invoice);
        setContactsXero([
            { name: invoice.conversion_type === 1 ? invoice.customer_name : invoice.vendor_name, value: "first" },
            ...contactsXeroOriginal,
        ]);
        setSelectedContact({
            value: invoice.contactXeroID ?? "first",
            name: invoice.conversion_type === 1 ? invoice.customer_name : invoice.vendor_name,
        });
    };

    const handleChangeTab = async (event, newValue) => {
        setValueTab(newValue);

        let newData = {};

        if (newValue === 0) {
            newData = toBeCompleted[0] ?? {};
        }
        if (newValue === 1) {
            newData = toReview[0] ?? {};
        }
        if (newValue === 2) {
            newData = readyToSync[0] ?? {};
        }

        setInvoiceData(newData);
    };

    const handleConfirmUpdateXeroContact = () => {
        const finalRows = invoices.map((aux) => {
            if (aux._id === invoiceData._id) {
                return {
                    ...aux,
                    customer_name: contactXeroUpdated,
                };
            } else {
                return aux;
            }
        });
        setInvoices(finalRows);
        setContactsXero(
            contactsXero.map((item) => {
                if (item.value === "first") {
                    item.name = contactXeroUpdated;
                    item.new = true;
                }
                return item;
            })
        );
        setSelectedContact({
            value: "first",
            name: contactXeroUpdated,
        });
        setOpenModalEditContact(false);
    };

    const dialogContactButtons = (
        <Grid container justifyContent="space-evenly">
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseDialog}
                    color="primary"
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3,
                    }}
                >
                    {t("team.cancel")}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmUpdateXeroContact}
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3,
                    }}
                    disabled={!contactXeroUpdated || contactXeroUpdated === ""}
                >
                    {t("subscription.confirm")}
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Box
                sx={{
                    backgroundColor: status === 0 ? "#F6F4FD" : status === 1 || status === 2 ? "#DBF4E5" : "#FFD8D5",
                    px: 3,
                    py: 2,
                    borderRadius: 4,
                    mt: 2,
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    {status === 0 ? (
                        <>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h3">{replaceInvBill(t("converter.syncXeroInvoice"))}</Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 20,
                                        width: 20,
                                    }}
                                    src={XeroLogo}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" endIcon={<DownloadIcon />} onClick={downloadInv}>
                                    {t("payment.download")}
                                </Button>
                                <LightTooltip title={(readyToSync.length + toReview.length) === 0 ? t("converter.disabledSync") : ""}>
                                    <span>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            endIcon={<SyncIcon />}
                                            onClick={syncInvoices}
                                            disabled={
                                                (readyToSync.length + toReview.length) === 0 || editGlobal ? true : false
                                            }
                                        >
                                            {t("converter.sync")}
                                        </Button>
                                    </span>
                                </LightTooltip>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h3">
                                    {status === 1 || status === 2
                                        ? t("converter.checksSyncSucc")
                                        : t("converter.bannerErr")}
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 20,
                                        width: 20,
                                    }}
                                    src={XeroLogo}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={4}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{
                                        backgroundColor: status !== 3 ? "#E4F7EB" : "#FFE1E6",
                                        borderRadius: 10,
                                        px: 2,
                                        py: 1,
                                    }}
                                >
                                    {status === 1 ? (
                                        <CheckIcon color="success" />
                                    ) : status === 2 ? (
                                        <ErrorIcon color="warning" />
                                    ) : (
                                        <CloseIcon color="error" />
                                    )}
                                    <Typography>
                                        {status === 1
                                            ? t("converter.bannerCheck")
                                            : status === 2
                                            ? t("converter.bannerWarning")
                                            : t("converter.bannerError")}
                                    </Typography>
                                </Stack>
                                <Button variant="contained" disableElevation onClick={endSync}>
                                    {t("dialog.finally")}
                                </Button>
                            </Stack>
                        </>
                    )}
                </Stack>
                {invoicesOriginal.length > 1 && (
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography>
                            <b>{invoicesOriginal.length}</b> {replaceInvBill(t("converter.totalInvo"))}
                        </Typography>
                        <Box>|</Box>
                        <Typography sx={{ color: "#00943B", fontWeight: 600 }}>
                            {(readyToSync.length + toReview.length)}
                            {replaceInvBill(t("converter.invReadyToSync"))}
                        </Typography>
                    </Stack>
                )}
            </Box>
            <Box sx={greyBoxStyles}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h1">{replaceInvBill(t("converter.invSync"))}</Typography>
                    {invoicesOriginal.length > 1 && (
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography>{replaceInvBill(t("converter.selectAccAllInv"))}:</Typography>
                            <FormControl variant="filled" sx={{ width: 300 }}>
                                <InputLabel
                                    id="simple-select-label-all"
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        color: "#131F3E",
                                    }}
                                >
                                    {t("accounts.accountNum")}
                                </InputLabel>
                                <Select
                                    labelId="simple-select-label-all"
                                    name="accXero"
                                    value={selectedAllAcc}
                                    onChange={handleChangeSelectAcc}
                                    IconComponent={(props) => checkIconDown(props)}
                                    size="small"
                                    MenuProps={MenuProps}
                                >
                                    {accountsXero.map((item) => (
                                        <MenuItem value={item.value} key={item.value}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <Button
                                variant="outlined"
                                sx={{ backgroundColor: "white", px: 4, py: 1 }}
                                startIcon={<FilterAltIcon />}
                            >
                                {t("dashboard.filters")}
                            </Button> */}
                        </Stack>
                    )}
                </Stack>
                {invoicesOriginal.length > 1 && (
                    <Tabs
                        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                        aria-label="Horizontal tabs"
                        sx={{
                            p: 1,
                            mt: 2,
                        }}
                        TabIndicatorProps={{
                            style: { display: "none" },
                        }}
                        value={valueTab}
                        onChange={handleChangeTab}
                    >
                        <Tab
                            label={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontWeight: valueTab === 0 ? 600 : 500, fontSize: 16 }}>
                                        {t("converter.toComplete")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "#4a22d4",
                                            color: "white",
                                            px: 1,
                                            py: 0.2,
                                            fontSize: 12,
                                        }}
                                    >
                                        {toBeCompleted.length}
                                    </Box>
                                </Stack>
                            }
                            {...a11yProps(0)}
                            sx={TabProps}
                        />
                        <Tab
                            label={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontWeight: valueTab === 1 ? 600 : 500, fontSize: 16 }}>
                                        {t("converter.toReview")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "#4a22d4",
                                            color: "white",
                                            px: 1,
                                            py: 0.2,
                                            fontSize: 12,
                                        }}
                                    >
                                        {toReview.length}
                                    </Box>
                                </Stack>
                            }
                            {...a11yProps(1)}
                            sx={TabProps}
                        />
                        <Tab
                            label={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontWeight: valueTab === 2 ? 600 : 500, fontSize: 16 }}>
                                        {t("converter.toBeSynch")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "#4a22d4",
                                            color: "white",
                                            px: 1,
                                            py: 0.2,
                                            fontSize: 12,
                                        }}
                                    >
                                        {readyToSync.length}
                                    </Box>
                                </Stack>
                            }
                            {...a11yProps(2)}
                            sx={TabProps}
                        />
                    </Tabs>
                )}
                <TabPanel value={valueTab} index={0}>
                    <InvoiceSlider
                        invoices={invoicesOriginal.length === 1 ? invoicesOriginal : toBeCompleted}
                        invoiceData={invoiceData}
                        handleInvoice={handleInvoice}
                        icon={
                            readyToSync.length === 1 && readyToSync.find((e) => e._id === invoiceData._id)
                                ? "check"
                                : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id)
                                ? "warning"
                                : "alert"
                        }
                    />
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                    <InvoiceSlider
                        invoices={toReview}
                        invoiceData={invoiceData}
                        handleInvoice={handleInvoice}
                        icon="warning"
                    />
                </TabPanel>
                <TabPanel value={valueTab} index={2}>
                    <InvoiceSlider
                        invoices={readyToSync}
                        invoiceData={invoiceData}
                        handleInvoice={handleInvoice}
                        icon="check"
                    />
                </TabPanel>
            </Box>
            <Box sx={greyBoxStyles}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item={12} md={3}>
                        <Typography variant="h1" gutterBottom>
                            {replaceInvBill(t("payment.invoice"))} <span style={{ color: "#4a22d4" }}>{invoiceData.invoice_id}</span>
                        </Typography>
                        <Typography sx={{ width: 350 }} gutterBottom>
                            <b>{t("converter.contactExt")}:</b>{" "}
                            {invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Autocomplete
                                PopperComponent={StyledPopper}
                                sx={{ width: 300 }}
                                options={contactsXero}
                                getOptionLabel={(option) => (option.name ? option.name : "")}
                                value={selectedContact}
                                onChange={(event, newValue) => handleChangeSelectContact(newValue)}
                                renderOption={(props, option, { index }) => {
                                    const { key, ...optionProps } = props;
                                    if (option.value === "first") {
                                        return (
                                            <Box
                                                key={key + index}
                                                component="li"
                                                {...optionProps}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <Stack direction="column">
                                                        <Typography fontWeight={600} color="primary">
                                                            {t("converter.edit")} {option.name}
                                                        </Typography>
                                                        <Typography fontSize={11} lineHeight={1} color="#131f3e">
                                                            {t("converter.editContactMsg")}
                                                        </Typography>
                                                    </Stack>
                                                    <IconButton
                                                        onClick={(event) => handleClickEditContact(event, option.name)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Box>
                                        );
                                    } else {
                                        return (
                                            <Box
                                                key={key + index}
                                                component="li"
                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                {...optionProps}
                                            >
                                                <Typography color="#131f3e">{option.name}</Typography>
                                            </Box>
                                        );
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={
                                            <Typography fontWeight={600} color="#131F3E">
                                                {invoiceData.contactXeroID === "first"
                                                    ? t("converter.createContact")
                                                    : t("converter.chooseContact")}
                                            </Typography>
                                        }
                                        variant="filled"
                                        size="small"
                                    />
                                )}
                                popupIcon={
                                    <PlayArrowIcon
                                        sx={{
                                            transform: "rotate(90deg)",
                                            color: "#131F3E",
                                            right: ".5rem",
                                            cursor: "pointer",
                                            zIndex: 0,
                                            pointerEvents: "none",
                                        }}
                                    />
                                }
                                noOptionsText={
                                    <Box sx={{ bgcolor: "#e8e9ec", p: 0.8, borderRadius: 1 }}>
                                        <Typography fontWeight={600} fontSize={13} mt={0.5} color="#131f3e">
                                            {t("converter.noContact")}
                                        </Typography>
                                    </Box>
                                }
                            />
                            <LightTooltip
                                title={
                                    contactsXeroOriginal.find(
                                        (item) =>
                                            item.name ===
                                            (invoiceData.conversion_type === 1
                                                ? invoiceData.customer_name
                                                : invoiceData.vendor_name)
                                    ) &&
                                    (invoiceData.conversion_type === 1
                                        ? invoiceData.customer_name
                                        : invoiceData.vendor_name) === selectedContact?.name
                                        ? t("converter.tooltipSyncMatch")
                                        : contactsXeroOriginal.find((item) => item.value === selectedContact?.value) ||
                                          contactsXero.find((item) => item.value === selectedContact?.value && item.new)
                                        ? t("converter.tooltipNewContact")
                                        : t("converter.tooltipSyncNotMatch")
                                }
                            >
                                {contactsXeroOriginal.find(
                                    (item) =>
                                        item.name ===
                                        (invoiceData.conversion_type === 1
                                            ? invoiceData.customer_name
                                            : invoiceData.vendor_name)
                                ) ||
                                contactsXeroOriginal.find((item) => item.value === selectedContact?.value) ||
                                contactsXero.find((item) => item.value === selectedContact?.value && item.new) ? (
                                    <CheckIcon
                                        color={
                                            contactsXeroOriginal.find(
                                                (item) =>
                                                    item.name ===
                                                    (invoiceData.conversion_type === 1
                                                        ? invoiceData.customer_name
                                                        : invoiceData.vendor_name)
                                            ) &&
                                            (invoiceData.conversion_type === 1
                                                ? invoiceData.customer_name
                                                : invoiceData.vendor_name) === selectedContact?.name
                                                ? "success"
                                                : "primary"
                                        }
                                    />
                                ) : (
                                    <WarningIcon color="primary" />
                                )}
                            </LightTooltip>
                        </Stack>
                    </Grid>
                    <Grid item={12} md={2}>
                        {Object.keys(invoiceData).length > 0 && (
                            <LightTooltip title={t("converter.tooltipReturnInv")}>
                                <Button
                                    variant="outlined"
                                    endIcon={<ReplyIcon />}
                                    onClick={() => returnInvoiceModal(invoiceData._id)}
                                >
                                    {replaceInvBill(t("converter.returnInvoice"))}
                                </Button>
                            </LightTooltip>
                        )}
                    </Grid>
                    <Grid item={12} md={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.subtotalExt")}</Typography>
                                    <Typography>
                                        $
                                        {parseFloat(invoiceData.formatted_sub_total ?? 0).toLocaleString(
                                            todos.amountFormat,
                                            {
                                                maximumFractionDigits: 2,
                                            }
                                        )}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.totalImpExt")}</Typography>
                                    <Typography>
                                        $
                                        {parseFloat(invoiceData.formatted_total_tax ?? 0).toLocaleString(
                                            todos.amountFormat,
                                            {
                                                maximumFractionDigits: 2,
                                            }
                                        )}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor:
                                            valueTab === 2 ||
                                            (isEqual && readyToSync.find((e) => e._id === invoiceData._id))
                                                ? "#DBF4E5"
                                                : valueTab === 1 ||
                                                  (!isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id))
                                                ? "#FFF1DB"
                                                : "#FFD8DE",
                                        p: 2,
                                        borderRadius: 3,
                                    }}
                                >
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h3">{t("converter.totalExt")}</Typography>
                                        <LightTooltip
                                            title={
                                                readyToSync.length > 0 &&
                                                readyToSync.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.readySyncLines")
                                                    : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.amountLines")
                                                    : t("converter.pendingLines")
                                            }
                                        >
                                            <InfoIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="primary"
                                            />
                                        </LightTooltip>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography>
                                            $
                                            {parseFloat(invoiceData.formatted_invoice_total ?? 0).toLocaleString(
                                                todos.amountFormat,
                                                {
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                        </Typography>
                                        {readyToSync.length > 0 &&
                                        readyToSync.find((e) => e._id === invoiceData._id) ? (
                                            <CheckIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="success"
                                            />
                                        ) : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id) ? (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"warning"}
                                            />
                                        ) : (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"error"}
                                            />
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.subtotalCalc")}</Typography>
                                    <Typography>
                                        {edited.length === 0
                                            ? ` $${parseFloat(
                                                  invoiceData.line_items?.reduce(
                                                      (sum, obj) =>
                                                          sum + (obj.amount_xero ?? obj.formatted_amount ?? 0),
                                                      0
                                                  ) ?? 0
                                              ).toLocaleString(todos.amountFormat, {
                                                  maximumFractionDigits: 2,
                                              })}
                                        `
                                            : ` $${parseFloat(
                                                  rowsAux.reduce(
                                                      (sum, obj) =>
                                                          sum + (obj.amount_xero ?? obj.formatted_amount ?? 0),
                                                      0
                                                  ) ?? 0
                                              ).toLocaleString(todos.amountFormat, {
                                                  maximumFractionDigits: 2,
                                              })}
                                        `}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.totalImpCalc")}</Typography>
                                    <Typography>
                                        $
                                        {parseFloat(
                                            invoiceData.line_items?.reduce((sum, obj) => sum + (obj.tax_amount ?? 0), 0)
                                        ).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor:
                                            valueTab === 2 ||
                                            (isEqual && readyToSync.find((e) => e._id === invoiceData._id))
                                                ? "#DBF4E5"
                                                : valueTab === 1 ||
                                                  (!isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id))
                                                ? "#FFF1DB"
                                                : "#FFD8DE",
                                        p: 2,
                                        borderRadius: 3,
                                    }}
                                >
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h3">{t("converter.totalCalc")}</Typography>
                                        <LightTooltip
                                            title={
                                                isEqual && readyToSync.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.readySyncLines")
                                                    : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.amountLines")
                                                    : t("converter.pendingLines")
                                            }
                                        >
                                            <InfoIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="primary"
                                            />
                                        </LightTooltip>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography>
                                            {` $${parseFloat(
                                                rowsAux.reduce(
                                                    (sum, obj) =>
                                                        sum +
                                                        (obj.amount_xero
                                                            ? obj.amount_xero
                                                            : obj.formatted_amount ?? 0) +
                                                        (obj.tax_amount ?? 0),
                                                    0
                                                ) ?? 0
                                            ).toLocaleString(todos.amountFormat, {
                                                maximumFractionDigits: 2,
                                            })}
                                        `}
                                        </Typography>
                                        {readyToSync.length > 0 &&
                                        readyToSync.find((e) => e._id === invoiceData._id) ? (
                                            <CheckIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="success"
                                            />
                                        ) : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id) ? (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"warning"}
                                            />
                                        ) : (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"error"}
                                            />
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <InvoicesSyncTable
                invoiceData={invoiceData}
                accountsXero={accountsXero}
                isGreaterExt={isGreaterExt}
                setOpenPreview={setOpenPreview}
                setIsLoading={setIsLoading}
                setFileData={setFileData}
                xeroTaxRates={xeroTaxRates}
                xeroItems={xeroItems}
                setInvoiceData={setInvoiceData}
                setInvoices={setInvoices}
                invoices={invoices}
                setBasePreviewData={setBasePreviewData}
                rowsAux={rowsAux}
                setRowsAux={setRowsAux}
                edited={edited}
                setEdited={setEdited}
                setNewRowDiff={setNewRowDiff}
                newRowDiff={newRowDiff}
                sumSubtotalTax={sumSubtotalTax}
                setTexto={setTexto}
                setAlertType={setAlertType}
                setShow={setShow}
                editGlobal={editGlobal}
                setEditGlobal={setEditGlobal}
                replaceInvBill={replaceInvBill}
            />
            <SimpleDialog
                open={showModalLeftSync}
                handleClose={handleCloseSyncModal}
                maxWidth="sm"
                content={
                    <Box sx={{ px: 4 }}>
                        <Stack direction="row" alignItems="center" sx={{ pb: 3 }} spacing={2}>
                            <WarningIcon sx={{ color: "#FFB23F", fontSize: 16 }} />
                            <Typography variant="h2" sx={{ color: "#FFB23F" }}>
                                {t("dialog.dialogSyncLeftTitle")}
                            </Typography>
                        </Stack>
                        <Typography sx={{ pb: 2 }}>{t("dialog.dialogSyncLeftText")}</Typography>
                        <Typography sx={{ pb: 3 }}>{t("dialog.dialogSyncLeftText2")}</Typography>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                            <Button onClick={handleCloseSyncModal}>{t("register.back")}</Button>
                            <Button variant="contained" disableElevation onClick={handleSync}>
                                {t("dialog.continue")}
                            </Button>
                        </Stack>
                    </Box>
                }
            />
            <FormDialog
                open={openModalEditContact}
                handleClose={handleCloseDialog}
                maxWidth="sm"
                title={
                    <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center">
                        <InfoIcon fontSize="small" sx={{ color: "#4A22D4" }} />
                        <Typography fontSize={24} color="primary" fontWeight={600} textAlign="center">
                            {t("converter.dialogContactXero")}
                        </Typography>
                    </Stack>
                }
                content={
                    <EditContactChecks
                        extContactName={extContactName}
                        contactXeroUpdated={contactXeroUpdated}
                        setContactXeroUpdated={setContactXeroUpdated}
                    />
                }
                actions={dialogContactButtons}
                align="center"
            />
        </>
    );
};

export default InvoicesSync;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import introJs from "intro.js";

import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Link,
    Stack,
    styled,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import UploadFile from "../../ui/UploadFile";
import BaseLinearProgress from "../../ui/BaseLinearProgress";
import LightTooltip from "../../ui/LightTooltip";

import classes from "../MainConverter.module.css";

import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import ErrorIconConverter from "../../../assets/error-icon-converter.svg";
import WarningIcon from "@mui/icons-material/Warning";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import conversionFormats from "../../Js/conversionFormats";
import invoicesService from "../../../services/invoices";
import subscriptionService from "../../../services/subscription";
import paymentMethodsService from "../../../services/payment_methods";
import moment from "moment/moment";

import AddCreditPacksDialog from "../../credits/AddCreditPacksDialog";
import AddPaymentDialog from "../../payments/AddPaymentDialog";
import AlertDialog from "../../ui/AlertDialog";

import { useDispatch, useSelector } from "react-redux";
import { addCredits, addTutorial } from "../../../redux/userSlice";
import ChangePayDialog from "../../subscription/shoppingCart/ChangePayDialog";
import BackgroundDialog from "../BackgroundDialog";
import SimpleDialog from "../../ui/SimpleDialog";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { fetchFileFromS3, getFranchiseCredits, zeroPad } from "../../../utils/utils";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "& td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const InvoicesConverter = (props) => {
    const {
        setIsLoading,
        setTexto,
        setAlertType,
        setShow,
        correlative,
        setCorrelative,
        setConversionId,
        setFileNameSaved,
        setConvertDoneNew,
        setShowPrev,
        setShowModalCredits,
        showModalCredits,
        resetConversionMain,
        setResetConversionMain,
        handleClickHelp,
        setFileData,
        setConversionData,
        disabledInput,
        setDisabledInput,
        setConvertDone,
        convertDone,
        setConvertRev,
        convertRev,
        setConvertInvalid,
        convertInvalid,
        setStatus,
        status,
        setConvFormat,
        setFileNameFull,
        groupedData,
        setGroupedData,
        setGroupedPeriods,
        acceptedFiles,
        setAcceptedFiles,
        setS3FileName,
        s3FileName,
        progress,
        setProgress,
        setEndConversion,
        setRows,
        rows,
        setCustomCredits,
        customCredits,
        setOpenModalGroupedHist,
        setOpenModalGrouped,
        setModalType,
        formats,
        setRowsModal,
        rowsModal,
        valueMain,
        conversion_type,
        reloadInvoices,
        showRepeatModal,
        setShowRepeatModal,
        showRepeatVal,
        setShowRepeatVal,
        setBasePreviewData,
        setOpenPreview
    } = props;

    const [t] = useTranslation("global");
    /* React redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const [showBanner, setShowBanner] = useState(false);

    const [showAddPay, setShowAddPay] = useState(false);
    const [dialogCancelConvert, setDialogCancelConvert] = useState(false);
    const [showChangePay, setShowChangePay] = useState(false); // Modal para cambiar tarjeta
    const [defaultPayMethod, setDefaultPayMethod] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [activePlan, setActivePlan] = useState(null); // Plan seleccionado
    const [openModal, setOpenModal] = useState(false);

    const [isLoadingConv, setIsLoadingConv] = useState(false);
    const [timer, setTimer] = useState(0);

    const columns = [
        { name: "documentName", title: t("converter.documentName") },
        { name: "convNum", title: t("converter.convertNumber") },
    ];

    useEffect(() => {
        let timeout;

        if (isLoadingConv) {
            let finalTime = 0;
            let ticker = 0;

            finalTime = rows.length * 8;
            ticker = 100 / finalTime;

            if (timer < finalTime) {
                timeout = setTimeout(() => {
                    setProgress((prev) => prev + ticker);
                    setTimer((prev) => prev + 1);
                }, 1000);
            }
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isLoadingConv, rows, setProgress, timer]);

    const handleCloseModalBack = () => {
        setOpenModal(false);
        setDisabledInput(false);
    };

    const handleCloseChangePay = () => {
        setShowChangePay(false);
        setShowModalCredits(true);
    };

    useEffect(() => {
        let isDone = false;
        if (todos.steps.step6) {
            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} 6`,
                                element: "#account4-tutorial",
                                intro: t("miscellaneous.tutorialConv1"),
                            },
                        ],
                    })
                    .oncomplete(() => {
                        isDone = true;
                        dispatch(addTutorial({ ...todos.steps, step6: false, step7: true }));
                    })
                    .onexit(() => {
                        if (!isDone) {
                            dispatch(addTutorial({ ...todos.steps, step6: false }));
                        }
                    })
                    .start();
            }, 1500);
        }
        if (status && (convertDone || convertRev || convertInvalid) && todos.steps.step7) {
            let textTut = "";
            let element = "";

            if (status === 1) {
                element = "#account7-tutorial";
                textTut = t("miscellaneous.tutorialConv4");
            }
            if (status === 2) {
                element = "#account6-tutorial";
                textTut = t("miscellaneous.tutorialConv3");
            }
            if (status === 3) {
                element = "#account5-tutorial";
                textTut = t("miscellaneous.tutorialConv2");
            }

            introJs()
                .setOptions({
                    disableInteraction: true,
                    exitOnOverlayClick: false,
                    keyboardNavigation: false,
                    nextLabel: t("dialog.continue"),
                    prevLabel: t("miscellaneous.goBack"),
                    doneLabel: t("miscellaneous.accept"),
                    steps: [
                        {
                            title: `${t("miscellaneous.step")} 7`,
                            element: element,
                            intro: textTut,
                        },
                    ],
                })
                .oncomplete(() => {
                    isDone = true;
                    if (status === 3) {
                        dispatch(addTutorial({ ...todos.steps, step7: false, step8: true }));
                    } else {
                        dispatch(addTutorial({ ...todos.steps, step7: false }));
                    }
                })
                .onexit(() => {
                    if (!isDone) {
                        dispatch(addTutorial({ ...todos.steps, step7: false }));
                    }
                })
                .start();
        }
    }, [dispatch, t, todos.steps, status, convertDone, convertRev, convertInvalid]);

    const [textId, setTextId] = useState(0);

    const handleCloseRepeModal = () => setShowRepeatModal(false);

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(true);
            getPaymentMethods(true);
        }
    };

    const handleCloseModal2 = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(false);
            getPaymentMethods(true);
            setShowModalCredits(true);
        }
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
        setShowChangePay(false);
    };

    const resetConversion = () => {
        setProgress(0);
        setConvertDone(false);
        setFileData(null);
        setConvFormat(conversionFormats);
        setAcceptedFiles([]);
        setShowBanner(false);
        setDisabledInput(false);
        setEndConversion(false);
        setConversionId(null);
        setConvertInvalid(false);
        setConvertRev(false);
        setStatus(0);
        // setNoTransactions(false);
        setConvertDoneNew(false);
        setRows([]);
        setRowsModal([]);
    };

    useEffect(() => {
        if (resetConversionMain) {
            resetConversion();
            setResetConversionMain(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetConversionMain]);

    const showConversions = async () => {
        setModalType("invoice");
        setOpenModalGroupedHist(false);
        setOpenModalGrouped(true);
        setRowsModal([...rows]);
    };

    useEffect(() => {
        const changeTextInterval = async () => {
            let currentIdx = textId;
            if (currentIdx === 2) {
                setTextId(0);
            } else {
                setTextId(currentIdx + 1);
            }
        };

        if (showBanner) {
            const intervalId = setInterval(changeTextInterval, 2000);

            return () => clearInterval(intervalId);
        }
    }, [showBanner, textId]);

    const handleClose = () => {
        setShowModalCredits(false);
    };

    const handleSetShowModalCredits = async () => {
        setCustomCredits(
            rows.reduce(function (sum, item) {
                return sum + item.price;
            }, 0) - todos.credits
        );
        setShowModalCredits(true);
    };

    const handleCloseDialogCancel = () => {
        setDialogCancelConvert(false);
    };

    const handleCancelConvert = async () => {
        handleCloseDialogCancel();
        setProgress(0);
        setStatus(0);
        setEndConversion(false);
        setConvertInvalid(false);
        setConvertRev(false);
        setConvertDone(false);
        setConvertDoneNew(false);
        setDisabledInput(false);
        setConversionId(null);
    };

    const handleShowPrevDownload = async () => {
        setIsLoadingConv(true);
        setDisabledInput(true);

        let statusConv = 0;

        const params = {
            data: rows.filter((item) => item.status === 200),
            company_id: todos.companyID,
            conversion_type,
        };

        const isMultipleChecks = acceptedFiles.length > 1;

        // Existen varios cheques en una sola imagen, se debe generar una conversión agrupada
        if (isMultipleChecks) {
            try {
                const response = await invoicesService.createGroupedConversionInvoice(params);
                params.groupedInvoiceId = response;
            } catch (err) {
                console.log(err);
            }
        }

        // 0: Facturas separadas. 1: Facturas consolidadas.
        params.invoice_type = valueMain;

        await invoicesService
            .convertInvoices(params)
            .then(async (response) => {
                if (response.status === 203) {
                    setCorrelative(response.data.correlative);
                    setOpenModal(true);
                    resetConversion();
                } else {
                    switch (response.data.status) {
                        case "review":
                            setStatus(2);
                            statusConv = 2;
                            break;
                        default:
                            setStatus(3);
                            statusConv = 3;
                            const finalData = response.data.invoices;
                            setConversionData(finalData);
                            setCorrelative(finalData.correlative);

                            let paramsGetFile = "";
                            if (
                                response.data.groupedInvoiceId !== undefined &&
                                response.data.groupedInvoiceId !== null
                            ) {
                                setGroupedData(finalData);
                                setFileNameSaved("GROUP-" + zeroPad(finalData.correlative ?? 0, 5));
                                const filteredConv = finalData.invoices.filter(
                                    (item) => item.id_status === 3 || item.id_status === 4
                                );

                                // Ordena el arreglo por la fecha
                                const sortedFilteredConv = filteredConv.sort((a, b) => {
                                    const dateA = moment.utc(a.formatted_invoice_date);
                                    const dateB = moment.utc(b.formatted_invoice_date);
                                    return dateA - dateB; // Orden ascendente (para descendente usa `dateB - dateA`)
                                });
                                setGroupedPeriods(sortedFilteredConv);
                                paramsGetFile = finalData.invoices[0].file_name;
                                setFileNameFull(finalData.invoices[0].uploaded_file);

                                setBasePreviewData({
                                    id: finalData.invoices[0]._id,
                                    type: "invoice",
                                });
                            } else {
                                setConversionId(finalData._id);
                                setFileNameSaved(finalData.uploaded_file.replace(/\.[^/.]+$/, ""));
                                paramsGetFile = finalData.file_name;
                                setFileNameFull(finalData.uploaded_file);

                                setBasePreviewData({
                                    id: finalData._id,
                                    type: "invoice",
                                });
                            }

                            const data = await fetchFileFromS3(paramsGetFile);

                            if (!data) {
                                setTexto(t("converter.dnldError"));
                                setAlertType("error");
                                setShow(true);
                            } else {
                                setFileData(data);
                            }
                            break;
                    }
                }
            })
            .catch(async (err) => {
                console.log(err);
                setStatus(1);
                statusConv = 1;
            });

        setEndConversion(true);
        setProgress(0);
        setTimer(0);
        setIsLoadingConv(false);

        if (statusConv === 3) {
            const credits = await getFranchiseCredits(todos.franchiseID);
            dispatch(addCredits(credits));

            setShowPrev(true);
            setAcceptedFiles([]);
            setS3FileName([]);
            setRows([]);
            setRowsModal([]);
        } else if (statusConv === 2 || statusConv === 1) {
            reloadInvoices();
            setDisabledInput(false);
        }
    };

    const getPaymentMethods = async (reload) => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            companyID: todos.companyID,
        };

        try {
            if (todos.companyID || todos.franchiseID) {
                const result = await paymentMethodsService.getPaymentMethods(params);
                const defaultPaymentMethod = result.find((card) => card.default_method);
                setDefaultPayMethod(defaultPaymentMethod);
                setPaymentMethods(result);
            }
        } catch (error) {
            console.log(error);
        }

        if (!reload) {
            await subscriptionService
                .getSubscription(params)
                .then((response) => {
                    if (response) {
                        setActivePlan(response.subscription_plan);
                    } else {
                        setActivePlan(null);
                    }
                })
                .catch((err) => console.log(err));
        }

        setIsLoading(false);
    };

    const bodyMess = (
        <>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                    mb: 3,
                    mt: 2,
                }}
                textAlign="justify"
            >
                {t("converter.textCancelConvert")}
            </Typography>
        </>
    );

    const openTutorial = () => {
        const defaultTut = {
            step1: false,
            step2: false,
            step4: false,
            step5: false,
            step6: false,
            step7: false,
            step8: false,
            step9: false,
        };

        let isDone = false;

        dispatch(addTutorial(defaultTut));

        introJs()
            .setOptions({
                disableInteraction: true,
                exitOnOverlayClick: false,
                keyboardNavigation: false,
                nextLabel: t("dialog.continue"),
                prevLabel: t("miscellaneous.goBack"),
                doneLabel: t("miscellaneous.accept"),
                steps: [
                    {
                        title: `${t("miscellaneous.step")} 6`,
                        element: "#account4-tutorial",
                        intro: t("miscellaneous.tutorialConv1"),
                    },
                ],
            })
            .oncomplete(() => {
                isDone = true;
                dispatch(addTutorial({ ...todos.steps, step6: false, step7: true }));
            })
            .onexit(() => {
                if (!isDone) {
                    dispatch(addTutorial({ ...todos.steps, step6: false }));
                }
            })
            .start();
    };

    const previewDoc = async (e) => {
        e.stopPropagation();

        setFileData(acceptedFiles[0]);
        setFileNameFull(s3FileName[0]);

        setBasePreviewData({
            type: "invoice",
            file_s3: rows[0].file_name_s3,
            file: rows[0].file_name,
        });

        // Open modal
        setOpenPreview(true);
    };

    return (
        <Grid container direction="column" sx={{ mb: 2 }}>
            <Grid item>
                <Box
                    className={
                        status === 2
                            ? classes.backYellowConv
                            : rows.filter((item) => item.status === 200).length > 0 && status !== 1
                            ? classes.backGreenConv
                            : (rows.filter((item) => item.status !== 200).length > 0 &&
                                  rows.filter((item) => item.status !== 200).length === rows.length) ||
                              status === 1
                            ? classes.backRed
                            : classes.backGray
                    }
                    id="account4-tutorial"
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h1" sx={{ mb: 1.5 }}>
                                {conversion_type === 1 ? t("converter.importInvoices") : t("converter.importBills")}
                            </Typography>
                            <LightTooltip title={t("miscellaneous.help")}>
                                <IconButton onClick={openTutorial}>
                                    <HelpIcon fontSize="small" color="primary" />
                                </IconButton>
                            </LightTooltip>
                        </Stack>
                        <Button onClick={() => handleClickHelp("generalConv", "", "")}>{t("accounts.needHelp")}</Button>
                    </Stack>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={rows.length > 1 ? 6.5 : 7}>
                            <UploadFile
                                acceptedFiles={acceptedFiles}
                                setAcceptedFiles={setAcceptedFiles}
                                formats={formats}
                                disabled={false}
                                setTexto={setTexto}
                                setAlertType={setAlertType}
                                setShow={setShow}
                                setS3FileName={setS3FileName}
                                s3FileName={s3FileName}
                                conversionFormats={conversionFormats}
                                convertDone={convertDone}
                                convertRev={convertRev}
                                convertInvalid={convertInvalid}
                                accountId={""}
                                setProgress={setProgress}
                                progress={progress}
                                setShowRepeatModal={setShowRepeatModal}
                                setShowRepeatVal={setShowRepeatVal}
                                rows={rows}
                                setRows={setRows}
                                setStatus={setStatus}
                                type="invoice"
                                setRowsModal={setRowsModal}
                                rowsModal={rowsModal}
                                conversionType={conversion_type}
                                previewDoc={previewDoc}
                            />
                        </Grid>
                        <Grid item xs={12} md={rows.length > 1 ? 2.5 : 3.5}>
                            {status !== 1 && rows.filter((item) => item.status === 200).length > 0 && (
                                <Stack spacing={1} alignItems="flex-start">
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="h3" sx={{ color: status === 2 ? "#FFB23F" : "#34bd6a" }}>
                                            {rows.filter((item) => item.status === 200).length > 1
                                                ? "#" +
                                                  zeroPad(
                                                      rows.filter((item) => item.status === 200)[0].number ?? 0,
                                                      5
                                                  ) +
                                                  " - " +
                                                  "#" +
                                                  zeroPad(
                                                      rows.filter((item) => item.status === 200)[
                                                          rows.filter((item) => item.status === 200).length - 1
                                                      ].number ?? 0,
                                                      5
                                                  )
                                                : (groupedData ? "GROUP-" : "#") +
                                                  zeroPad(
                                                      groupedData ? groupedData.correlative ?? 0 : rows[0].number ?? 0,
                                                      5
                                                  )}
                                        </Typography>
                                        <Typography variant="h3">
                                            {status === 2 ? t("converter.conversionRev") : t("converter.readyConv")}
                                        </Typography>
                                        {status === 2 ? (
                                            <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="small" />
                                        ) : (
                                            <CheckIcon sx={{ color: "#00943b" }} fontSize="small" />
                                        )}
                                    </Stack>
                                    <Box className={classes.backYellow}>
                                        {status === 2 ? (
                                            <Typography variant="h3">{t("converter.convRev")}</Typography>
                                        ) : (
                                            <Typography>
                                                <b>{t("converter.price")}</b>:{" "}
                                                {`${rows.reduce(function (sum, item) {
                                                    return sum + item.price;
                                                }, 0)} ${
                                                    rows.reduce(function (sum, item) {
                                                        return sum + item.price;
                                                    }, 0) === 1
                                                        ? t("converter.credit")
                                                        : t("converter.credits")
                                                }`}
                                            </Typography>
                                        )}
                                    </Box>
                                    {status === 2 && (
                                        <Typography variant="h3">{t("converter.conversionRevMsg2")}</Typography>
                                    )}
                                    {todos.credits -
                                        rows.reduce(function (sum, item) {
                                            return sum + item.price;
                                        }, 0) >
                                        0 && (
                                        <Typography className={classes.creditsText}>
                                            <b>{t("converter.rest")}:</b>{" "}
                                            <span
                                                style={{
                                                    display: "inline",
                                                    color:
                                                        todos.credits -
                                                            rows.reduce(function (sum, item) {
                                                                return sum + item.price;
                                                            }, 0) <
                                                        0
                                                            ? "#FF5E77"
                                                            : "auto",
                                                }}
                                            >
                                                {todos.credits -
                                                    rows.reduce(function (sum, item) {
                                                        return sum + item.price;
                                                    }, 0)}
                                            </span>{" "}
                                            {t("register.conversions")}
                                        </Typography>
                                    )}
                                </Stack>
                            )}
                            {(status === 1 ||
                                (rows.filter((item) => item.status !== 200).length > 0 &&
                                    rows.filter((item) => item.status !== 200).length === rows.length)) && (
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={12} md={10}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="h3" sx={{ color: "#FF5E77" }}>
                                                {(groupedData ? "GROUP-" : "#") +
                                                    zeroPad(
                                                        groupedData
                                                            ? groupedData.correlative ?? 0
                                                            : rows[0].number ?? 0,
                                                        5
                                                    )}
                                            </Typography>
                                            <Typography variant="h3">{t("converter.invalidLec")}</Typography>
                                            <CloseIcon sx={{ color: "#FF5E77" }} fontSize="small" />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Typography>{t("converter.kiiperInvalid")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Typography>
                                            <b style={{ color: "#4A22D4" }}>{t("converter.contactSupport")}</b>{" "}
                                            {t("converter.contactSupp")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {rows.length > 1 && (
                            <Grid item xs={12} md={1.5}>
                                <Button
                                    variant="outlined"
                                    endIcon={<SearchIcon style={{ width: "27px", height: "27px" }} />}
                                    onClick={showConversions}
                                    sx={{
                                        py: 1.4,
                                        width: { xs: "100%", md: "80%" },
                                        justifyContent: "space-between",
                                        backgroundColor: "white",
                                        "&:hover": {
                                            backgroundColor: "#E5E5E5",
                                        },
                                        "&:disabled": {
                                            color: "#FFF",
                                            backgroundColor: "rgba(189, 189, 189, 1)",
                                        },
                                    }}
                                >
                                    {t("converter.seeDetails")}
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={12} md={1.5}>
                            {progress > 0 ? (
                                <>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <BaseLinearProgress progress={progress} />
                                        {progress === 100 && isLoadingConv && <CircularProgress color="secondary" />}
                                    </Stack>
                                    <Typography variant="h2" sx={{ pl: 3, pt: 1 }}>
                                        {isLoadingConv ? t("converter.calculate") : t("converter.uploadingCheck")}
                                    </Typography>
                                </>
                            ) : (
                                <Button
                                    variant="contained"
                                    endIcon={<CachedIcon style={{ width: "27px", height: "27px" }} />}
                                    disabled={
                                        disabledInput ||
                                        todos.credits -
                                            rows.reduce(function (sum, item) {
                                                return sum + item.price;
                                            }, 0) <
                                            0 ||
                                        rows.length === 0
                                    }
                                    onClick={
                                        (rows.filter((item) => item.status !== 200).length > 0 &&
                                            rows.filter((item) => item.status !== 200).length === rows.length) ||
                                        status === 2 ||
                                        status === 1
                                            ? () => resetConversion()
                                            : () => handleShowPrevDownload()
                                    }
                                    sx={{
                                        py: 1.4,
                                        width: { xs: "100%", md: "100%" },
                                        justifyContent: "space-between",
                                        "&:disabled": {
                                            color: "#FFF",
                                            backgroundColor: "rgba(189, 189, 189, 1)",
                                        },
                                    }}
                                >
                                    {(rows.filter((item) => item.status !== 200).length > 0 &&
                                        rows.filter((item) => item.status !== 200).length === rows.length) ||
                                    status === 2 ||
                                    status === 1
                                        ? t("converter.newConv")
                                        : t("converter.convert")}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                            <Typography>
                                {conversion_type === 1
                                    ? t("converter.discountConvInvoices")
                                    : t("converter.discountConvBills")}
                            </Typography>
                        </Stack>
                        {todos.credits -
                            rows.reduce(function (sum, item) {
                                return sum + item.price;
                            }, 0) <
                            0 && (
                            <Stack direction="row" alignItems="center" spacing={2} className={classes.backMiddle}>
                                <Box component="img" src={ErrorIconConverter} sx={{ width: 25 }} />
                                <Box>
                                    <Typography className={classes.textBlue4}>
                                        {t("converter.noCreditsLeft")}
                                    </Typography>
                                    <Link
                                        sx={{ textDecoration: "none", fontWeight: "bold", cursor: "pointer" }}
                                        onClick={handleSetShowModalCredits}
                                    >
                                        {t("converter.rechargeCredits")}
                                    </Link>
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                </Box>
            </Grid>
            {showModalCredits && (
                <AddCreditPacksDialog
                    open={showModalCredits}
                    onClose={handleClose}
                    setShowAddPay={setShowAddPay}
                    setShowChangePay={setShowChangePay}
                    getPaymentMethods={() => getPaymentMethods(false)}
                    activePlan={activePlan}
                    paymentMethod={defaultPayMethod}
                    setIsLoading={setIsLoading}
                    setCustomCredits={setCustomCredits}
                    customCredits={customCredits}
                />
            )}
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setTexto}
                    setAlertType={setAlertType}
                    setIsLoading={setIsLoading}
                    handleClose2={handleCloseModal2}
                    converter={true}
                />
            )}
            {dialogCancelConvert && (
                <AlertDialog
                    type="warning"
                    open={dialogCancelConvert}
                    onClose={handleCloseDialogCancel}
                    title={t("converter.alertNewConvert")}
                    message={bodyMess}
                    disagreeBtnLabel={t("dialog.cancel1")}
                    agreeBtnLabel={t("dialog.continue1")}
                    maxWidth="sm"
                    agreeAction={handleCancelConvert}
                    origin="converter"
                />
            )}
            {showChangePay && (
                <ChangePayDialog
                    open={showChangePay}
                    handleClose={handleCloseChangePay}
                    payments={paymentMethods}
                    setIsLoading={setIsLoading}
                    reloadData={() => getPaymentMethods(true)}
                    defaultCard={defaultPayMethod}
                    handleAddPaymentM={handleAddPaymentM}
                />
            )}
            <SimpleDialog
                open={showRepeatModal}
                handleClose={handleCloseRepeModal}
                maxWidth="md"
                content={
                    <Box sx={{ px: 4, maxWidth: 800 }}>
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ pb: 2 }} spacing={2}>
                            <WarningIcon size="small" sx={{ color: "#FFB23F" }} />
                            <Typography variant="h1" sx={{ color: "#FFB23F" }}>
                                {t("converter.dupliConv")}
                            </Typography>
                        </Stack>
                        {showRepeatVal.length === 1 && (
                            <Typography gutterBottom>
                                {`${t("converter.mainText")} #${zeroPad(showRepeatVal[0].correlative ?? 0, 5)}${t(
                                    "converter.mainText2"
                                )}`}
                            </Typography>
                        )}
                        {showRepeatVal.length > 1 && (
                            <>
                                {rows.length > 1 ? (
                                    <Typography gutterBottom>{t("converter.mainText3")}</Typography>
                                ) : (
                                    <Typography sx={{ pb: 2 }}>{t("converter.secondText")}</Typography>
                                )}
                                <TableContainer sx={{ maxHeight: 500 }}>
                                    <Table
                                        aria-label="simple table"
                                        sx={{ borderCollapse: "separate", borderSpacing: "0 4px" }}
                                    >
                                        <TableHead>
                                            <StyledTableRow>
                                                {columns.map((item) => (
                                                    <StyledTableCell key={item.name}>{item.title}</StyledTableCell>
                                                ))}
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {showRepeatVal.map((row) => (
                                                <StyledTableRow
                                                    key={row.correlative}
                                                    sx={{
                                                        "& td:first-of-type": {
                                                            border: 0,
                                                            borderTopLeftRadius: 20,
                                                            borderBottomLeftRadius: 20,
                                                        },
                                                        "& td:last-child": {
                                                            borderTopRightRadius: 20,
                                                            borderBottomRightRadius: 20,
                                                        },
                                                        backgroundColor: "#F8F8F9",
                                                    }}
                                                >
                                                    <StyledTableCell>{row.uploaded_file}</StyledTableCell>
                                                    <StyledTableCell>
                                                        {`${t("subscription.conversion")} #${zeroPad(
                                                            row.correlative ?? 0,
                                                            5
                                                        )}`}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                        <Button onClick={handleCloseRepeModal} sx={{ ml: 2 }}>
                            {t("register.back")}
                        </Button>
                    </Box>
                }
            />
            <BackgroundDialog open={openModal} handleClose={handleCloseModalBack} convNum={zeroPad(correlative, 5)} />
        </Grid>
    );
};

export default InvoicesConverter;

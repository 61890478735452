/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import conversionService from "../../services/conversion";
import invoicesService from "../../services/invoices";
import caseService from "../../services/case";
import { useNavigate } from "react-router-dom";

/** MUI Icons */
// import ConverterIcon from "../../assets/converter-icon.svg";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import ConverterBg from "../../assets/converterbg.svg";
import CheckIcon from "@mui/icons-material/Check";
import CachedIcon from "@mui/icons-material/Cached";
import PreviewIcon from "@mui/icons-material/Preview";

/** MUI imports */
import { Box, Grid, IconButton, Link, Stack, Typography } from "@mui/material";

/** Component imports */
import classes from "./UploadFile.module.css";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import LightTooltip from "./LightTooltip";
import moment from "moment/moment";

//const formats = [".xlsx", ".pdf", ".csv"];

const UploadFile = (props) => {
    const {
        acceptedFiles,
        setAcceptedFiles,
        formats,
        disabled,
        setTexto,
        setAlertType,
        setShow,
        setS3FileName,
        s3FileName,
        convertDone,
        convertRev,
        convertInvalid,
        isModal,
        setRows,
        rows,
        accountId,
        progress,
        setProgress,
        setShowRepeatModal,
        setShowRepeatVal,
        setStatus,
        type,
        reload,
        convInfo,
        setConvInfo,
        accountDet,
        setOpenModal,
        setRowsModal,
        rowsModal,
        isLoadingConv,
        conversionType,
        previewDoc
    } = props;

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    const navigate = useNavigate();

    const todos = useSelector((state) => state.value);

    /** Component states */
    const [loadingFile, setLoadingFile] = useState(true);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));

    let acceptFormat = {};
    if (formats && formats.map((item) => item.name).includes("pdf")) {
        acceptFormat["application/pdf"] = [];
    }
    if (formats && formats.map((item) => item.name).includes("csv")) {
        acceptFormat["text/csv"] = [];
    }
    if (formats && formats.map((item) => item.name).includes("txt")) {
        acceptFormat["text/plain"] = [];
    }
    if (formats && formats.map((item) => item.name).includes("xlsx")) {
        acceptFormat["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] = [];
    }
    if (formats && formats.map((item) => item.name).includes("xls")) {
        acceptFormat["application/vnd.ms-excel"] = [];
    }
    if (formats && formats.map((item) => item.name).includes("png")) {
        acceptFormat["image/png"] = [];
    }
    if (formats && formats.map((item) => item.name).includes("jpg")) {
        acceptFormat["image/jpg"] = [];
        acceptFormat["image/jpeg"] = [];
    }

    function generateUniqueId() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    }

    function findFinalCorrelative(correlative, rows) {
        let correlativeI = correlative;
        if (rows.length > 0) {
            // Buscar si hay conversiones previas
            let filtersCorrelatives = rows.map((item) => item.number);
            correlativeI = Math.max(...filtersCorrelatives) + 1;
        }

        return correlativeI;
    }

    /** Component functions */
    const onDrop = useCallback(
        async (newAcceptedFiles) => {
            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded / total) * 100);

                    if (percent < 100) {
                        setProgress(percent);
                    }
                },
            };

            setLoadingFile(true);

            let isHeavy = false;
            let isHeavyLoad = false;
            const data = new FormData();
            data.append("accountId", accountId);
            data.append("type", type);
            data.append("company_id", todos.companyID);

            if (type === "check") {
                if (newAcceptedFiles.length > 1) {
                    for (const value of newAcceptedFiles) {
                        if (value.size >= 50000000) {
                            isHeavy = true;
                        } else {
                            data.append("files", value);
                        }
                    }
                    if (!isHeavy) {
                        setAcceptedFiles([...acceptedFiles, ...newAcceptedFiles]);

                        await conversionService
                            .uploadMulFileS3(data, options)
                            .then(async (response) => {
                                let correlativeI = findFinalCorrelative(response.firstCorrelative, rows);

                                let counter = correlativeI;

                                let newRows = response.statusByFile.flatMap((fileItem) => {
                                    if (fileItem.cropAreas.length === 0) {
                                        return [
                                            {
                                                id: generateUniqueId(),
                                                number: counter++,
                                                price: fileItem.price,
                                                file_name: fileItem.original_filename,
                                                file_name_s3: fileItem.filename,
                                                status: fileItem.status,
                                                data: fileItem.cropAreas,
                                            },
                                        ];
                                    } else {
                                        let uniqueId = generateUniqueId();
                                        return fileItem.cropAreas.map((cropItem) => ({
                                            id: uniqueId,
                                            number: counter++,
                                            price: fileItem.price / fileItem.cropAreas.length,
                                            file_name: fileItem.original_filename,
                                            file_name_s3: fileItem.filename,
                                            status: fileItem.status,
                                            data: cropItem,
                                        }));
                                    }
                                });

                                setRows([...rows, ...newRows]);

                                if (response.repeatedConversions.length > 0) {
                                    setShowRepeatModal(true);
                                    setShowRepeatVal(response.repeatedConversions);
                                }

                                setS3FileName([...s3FileName, ...newRows.map((item) => item.file_name_s3)]);
                            })
                            .catch((err) => {
                                console.log(err);
                                if (err.response.data.correlative) {
                                    let correlativeI = findFinalCorrelative(err.response.data.correlative, rows);

                                    let newRows;
                                    if (err.response.data.statusByFile.length > 0) {
                                        newRows = err.response.data.statusByFile.map((item, index) => ({
                                            id: err.response.data.id,
                                            number: correlativeI + index,
                                            price: 0,
                                            file_name: item.original_filename,
                                            file_name_s3: item.filename,
                                            status: item.status,
                                            data: item.cropAreas,
                                        }));
                                    } else {
                                        newRows = newAcceptedFiles.map((item, index) => ({
                                            id: err.response.data.id,
                                            number: correlativeI + index,
                                            price: 0,
                                            file_name: newAcceptedFiles[index].name,
                                            file_name_s3: "",
                                            status: err.response.status,
                                            data: [],
                                        }));
                                    }

                                    setRows([...rows, ...newRows]);

                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                } else {
                                    let correlativeI = findFinalCorrelative(1, rows);
                                    let newRows = newAcceptedFiles.map((item, index) => ({
                                        id: generateUniqueId(),
                                        number: correlativeI + index,
                                        price: 0,
                                        file_name: newAcceptedFiles[index].name,
                                        file_name_s3: "",
                                        status: err.response.status,
                                        data: [],
                                    }));

                                    setRows([...rows, ...newRows]);

                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                }

                                reload();
                            });
                    }
                } else if (newAcceptedFiles.length === 1) {
                    if (newAcceptedFiles[0].size >= 50000000) {
                        isHeavy = true;
                    } else {
                        data.append("file", newAcceptedFiles[0]);

                        setAcceptedFiles([...acceptedFiles, newAcceptedFiles[0]]);

                        await conversionService
                            .uploadFileS3(data, options)
                            .then((response) => {
                                let correlativeI = findFinalCorrelative(response.correlative, rows);

                                let newRows;
                                let counter = correlativeI;
                                if (response.statusByFile[0].cropAreas.length === 0) {
                                    newRows = [
                                        {
                                            id: generateUniqueId(),
                                            number: counter++,
                                            price: response.statusByFile[0].price,
                                            file_name: response.statusByFile[0].original_filename,
                                            file_name_s3: response.statusByFile[0].filename,
                                            status: response.statusByFile[0].status,
                                            data: response.statusByFile[0].cropAreas,
                                        },
                                    ];
                                } else {
                                    let uniqueId = generateUniqueId();
                                    newRows = response.statusByFile[0].cropAreas.map((item) => ({
                                        id: uniqueId,
                                        number: counter++,
                                        price:
                                            response.statusByFile[0].price / response.statusByFile[0].cropAreas.length,
                                        file_name: response.statusByFile[0].original_filename,
                                        file_name_s3: response.statusByFile[0].filename,
                                        status: response.statusByFile[0].status,
                                        data: item,
                                    }));
                                }

                                setRows([...rows, ...newRows]);

                                if (response.repeatedConversions.length > 0) {
                                    setShowRepeatModal(true);
                                    setShowRepeatVal(response.repeatedConversions);
                                }

                                setS3FileName([...s3FileName, newRows.file_name_s3]);
                            })
                            .catch((err) => {
                                console.log(err);

                                if (err.response.data.correlative) {
                                    let correlativeI = findFinalCorrelative(err.response.data.correlative, rows);

                                    let newRows;
                                    let counter = correlativeI;

                                    if (err.response.data.statusByFile.length > 0) {
                                        if (err.response.data.statusByFile[0].cropAreas.length === 0) {
                                            newRows = [
                                                {
                                                    id: err.response.data.id,
                                                    number: counter++,
                                                    price: err.response.data.statusByFile[0].price,
                                                    file_name: err.response.data.statusByFile[0].original_filename,
                                                    file_name_s3: err.response.data.statusByFile[0].filename,
                                                    status: err.response.data.statusByFile[0].status,
                                                    data: err.response.data.statusByFile[0].cropAreas,
                                                },
                                            ];
                                        } else {
                                            newRows = err.response.data.statusByFile[0].cropAreas.map((item) => ({
                                                id: err.response.data.id,
                                                number: counter++,
                                                price:
                                                    err.response.data.statusByFile[0].price /
                                                    err.response.data.statusByFile[0].cropAreas.length,
                                                file_name: err.response.data.statusByFile[0].original_filename,
                                                file_name_s3: err.response.data.statusByFile[0].filename,
                                                status: err.response.data.statusByFile[0].status,
                                                data: item,
                                            }));
                                        }
                                    } else {
                                        newRows = [
                                            {
                                                id: err.response.data.id,
                                                number: counter++,
                                                price: 0,
                                                file_name: newAcceptedFiles[0].name,
                                                file_name_s3: "",
                                                status: err.response.status,
                                                data: [],
                                            },
                                        ];
                                    }
                                    setRows([...rows, ...newRows]);
                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                } else {
                                    let correlativeI = findFinalCorrelative(1, rows);

                                    let newRows = [
                                        {
                                            id: generateUniqueId(),
                                            number: correlativeI,
                                            price: 0,
                                            file_name: newAcceptedFiles[0].name,
                                            file_name_s3: "",
                                            status: err.response.status,
                                            data: [],
                                        },
                                    ];

                                    setRows([...rows, ...newRows]);

                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                }

                                reload();
                            });
                    }
                }
            } else if (type === "bankStatement") {
                for (const value of newAcceptedFiles) {
                    if (value.size >= 50000000) {
                        isHeavy = true;
                    } else {
                        data.append("files", value);
                    }
                    if (value.size >= 10485760) {
                        isHeavyLoad = true;
                    }
                }
                if (!isHeavy && newAcceptedFiles.length > 0) {
                    setAcceptedFiles([...acceptedFiles, ...newAcceptedFiles]);

                    let finalStatus = 0;
                    if ("groupedConversionId" in convInfo && convInfo.groupedConversionId !== undefined) {
                        data.append("groupedConversionId", convInfo.groupedConversionId);
                    }

                    if ("statusByFile" in convInfo && convInfo.statusByFile.length > 0) {
                        data.append("previewConversions", JSON.stringify(convInfo.statusByFile));
                    }

                    await conversionService
                        .uploadFileBankStatement(data, options)
                        .then(async (response) => {
                            const finalData = response.data;
                            finalStatus = finalData.statusByFile.some((item) => item.status === 200) ? 1 : 2;

                            let newRows = finalData.statusByFile.map((item) => ({
                                id: item.conversionId,
                                number: item.correlative,
                                price: item.price,
                                file_name: item.original_filename,
                                file_name_s3: item.filename,
                                status: item.status,
                                period:
                                    moment(item.initialDate).format(todos.dateFormat) +
                                    " - " +
                                    moment(item.endDate).format(todos.dateFormat),
                            }));

                            // 500: Inválido
                            // 200: Válido (verde)
                            // 204/205/400: En revisión

                            if (finalData.repeatedConversions.length > 0) {
                                setShowRepeatModal(true);
                                setShowRepeatVal(finalData.repeatedConversions);
                            }

                            setS3FileName([...s3FileName, ...finalData.statusByFile.map((item) => item.filename)]);

                            setConvInfo({
                                groupedConversionId: finalData.groupedConversionId,
                                statusByFile: finalData.statusByFile,
                            });

                            if (isHeavyLoad && accountDet.bank.large_conversion) {
                                setOpenModal(true);
                            } else {
                                setRows([...newRows]);
                                setRowsModal([...newRows]);
                                setStatus(finalStatus);
                                await reload(null, true);
                            }
                        })
                        .catch(async (err) => {
                            console.log(err);
                            if (err?.response?.data?.statusByFile) {
                                finalStatus = err.response.data.statusByFile.some((item) => item.status === 200)
                                    ? 1
                                    : err.response.data.statusByFile.filter((item) => item.status !== 200).length ===
                                      err.response.data.statusByFile.length
                                    ? 2
                                    : 3;

                                //finalStatus = err.response.status === 400 ? 2 : 3;
                                console.log(err.response.data);
                                if (err.response.status === 400) {
                                    var found = err.response.data.statusByFile.find((x) => x.status === 400);
                                    if (found) {
                                        await caseService
                                            .getCaseInfo({ conversion_id: found.conversionId })
                                            .then((response) => {
                                                setTexto(
                                                    `${t("inbox.dialogCreatedCase")} ${
                                                        response.caseInfo.case_number
                                                    }. ${t("inbox.dialogCreatedCase2")}`
                                                );
                                                setAlertType("success");
                                                setShow(true);
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                    }
                                }

                                const newRows = err.response.data.statusByFile.map((item) => ({
                                    id: item.conversionId,
                                    number: item.correlative,
                                    price: item.price,
                                    file_name: item.original_filename,
                                    file_name_s3: item.filename,
                                    status: item.status,
                                    period:
                                        moment(item.initialDate).format(todos.dateFormat) +
                                        " - " +
                                        moment(item.endDate).format(todos.dateFormat),
                                }));

                                setConvInfo({
                                    groupedConversionId: err?.response?.data?.groupedConversionId ?? undefined,
                                    statusByFile: err?.response?.data?.statusByFile ?? [],
                                });

                                if (isHeavyLoad && accountDet.bank.large_conversion) {
                                    setOpenModal(true);
                                } else {
                                    await reload(null, true);
                                    setRows([...newRows]);
                                    setRowsModal([...newRows]);
                                    setStatus(finalStatus);
                                }
                            } else {
                                await reload(null, true);
                                setTexto(t("converter.errorUpload"));
                                setAlertType("error");
                                setShow(true);
                            }
                        });
                }
            } else if (type === "invoice") {
                if (newAcceptedFiles.length > 1) {
                    for (const value of newAcceptedFiles) {
                        if (value.size >= 50000000) {
                            isHeavy = true;
                        } else {
                            data.append("files", value);
                        }
                    }
                    if (!isHeavy) {
                        setAcceptedFiles([...acceptedFiles, ...newAcceptedFiles]);

                        await conversionService
                            .uploadMulFileS3(data, options)
                            .then(async (response) => {
                                let correlativeI = findFinalCorrelative(response.firstCorrelative, rows);

                                let counter = correlativeI;

                                let newRows = response.statusByFile.flatMap((fileItem) => {
                                    return [
                                        {
                                            id: generateUniqueId(),
                                            number: counter++,
                                            price: fileItem.price,
                                            file_name: fileItem.original_filename,
                                            file_name_s3: fileItem.filename,
                                            status: fileItem.status,
                                        },
                                    ];
                                });

                                setRows([...rows, ...newRows]);
                                setRowsModal([...rowsModal, ...newRows]);

                                if (response.repeatedConversions.length > 0) {
                                    setShowRepeatModal(true);
                                    setShowRepeatVal(response.repeatedConversions);
                                }

                                setS3FileName([...s3FileName, ...newRows.map((item) => item.file_name_s3)]);
                            })
                            .catch((err) => {
                                console.log(err);
                                if (err.response.data.correlative) {
                                    let correlativeI = findFinalCorrelative(err.response.data.correlative, rows);

                                    let newRows;
                                    if (err.response.data.statusByFile.length > 0) {
                                        newRows = err.response.data.statusByFile.map((item, index) => ({
                                            id: generateUniqueId(),
                                            number: correlativeI + index,
                                            price: 0,
                                            file_name: item.original_filename,
                                            file_name_s3: item.filename,
                                            status: item.status,
                                        }));
                                    } else {
                                        newRows = newAcceptedFiles.map((item, index) => ({
                                            id: generateUniqueId(),
                                            number: correlativeI + index,
                                            price: 0,
                                            file_name: newAcceptedFiles[index].name,
                                            file_name_s3: "",
                                            status: err.response.status,
                                        }));
                                    }

                                    setRows([...rows, ...newRows]);
                                    setRowsModal([...rowsModal, ...newRows]);

                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                } else {
                                    let correlativeI = findFinalCorrelative(1, rows);
                                    let newRows = newAcceptedFiles.map((item, index) => ({
                                        id: generateUniqueId(),
                                        number: correlativeI + index,
                                        price: 0,
                                        file_name: newAcceptedFiles[index].name,
                                        file_name_s3: "",
                                        status: err.response.status,
                                    }));

                                    setRows([...rows, ...newRows]);
                                    setRowsModal([...rowsModal, ...newRows]);

                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                }
                            });
                    }
                } else if (newAcceptedFiles.length === 1) {
                    if (newAcceptedFiles[0].size >= 50000000) {
                        isHeavy = true;
                    } else {
                        data.append("file", newAcceptedFiles[0]);

                        setAcceptedFiles([...acceptedFiles, newAcceptedFiles[0]]);

                        await conversionService
                            .uploadFileS3(data, options)
                            .then((response) => {
                                let correlativeI = findFinalCorrelative(response.correlative, rows);

                                let counter = correlativeI;
                                let newRows = [
                                    {
                                        id: generateUniqueId(),
                                        number: counter++,
                                        price: response.statusByFile[0].price,
                                        file_name: response.statusByFile[0].original_filename,
                                        file_name_s3: response.statusByFile[0].filename,
                                        status: response.statusByFile[0].status,
                                    },
                                ];

                                setRows([...rows, ...newRows]);
                                setRowsModal([...rowsModal, ...newRows]);

                                if (response.repeatedConversions.length > 0) {
                                    setShowRepeatModal(true);
                                    setShowRepeatVal(response.repeatedConversions);
                                }

                                setS3FileName([...s3FileName, newRows.file_name_s3]);
                            })
                            .catch((err) => {
                                console.log(err);

                                if (err.response.data.correlative) {
                                    let correlativeI = findFinalCorrelative(err.response.data.correlative, rows);

                                    let newRows;
                                    let counter = correlativeI;

                                    if (err.response.data.statusByFile.length > 0) {
                                        newRows = [
                                            {
                                                id: generateUniqueId(),
                                                number: counter++,
                                                price: err.response.data.statusByFile[0].price,
                                                file_name: err.response.data.statusByFile[0].original_filename,
                                                file_name_s3: err.response.data.statusByFile[0].filename,
                                                status: err.response.data.statusByFile[0].status,
                                            },
                                        ];
                                    } else {
                                        newRows = [
                                            {
                                                id: generateUniqueId(),
                                                number: counter++,
                                                price: 0,
                                                file_name: newAcceptedFiles[0].name,
                                                file_name_s3: "",
                                                status: err.response.status,
                                            },
                                        ];
                                    }
                                    setRows([...rows, ...newRows]);
                                    setRowsModal([...rowsModal, ...newRows]);
                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                } else {
                                    let correlativeI = findFinalCorrelative(1, rows);

                                    let newRows = [
                                        {
                                            id: generateUniqueId(),
                                            number: correlativeI,
                                            price: 0,
                                            file_name: newAcceptedFiles[0].name,
                                            file_name_s3: "",
                                            status: err.response.status,
                                        },
                                    ];

                                    setRows([...rows, ...newRows]);
                                    setRowsModal([...rowsModal, ...newRows]);

                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                }
                            });
                    }
                }
            } else if (type === "consolidated") {
                if (newAcceptedFiles.length === 1) {
                    if (newAcceptedFiles[0].size >= 50000000) {
                        isHeavy = true;
                    } else {
                        data.append("file", newAcceptedFiles[0]);

                        setAcceptedFiles([newAcceptedFiles[0]]);

                        await invoicesService
                            .uploadConsolidatedFileS3(data, options)
                            .then((response) => {
                                let correlativeI = findFinalCorrelative(response.correlative, rows);

                                let counter = correlativeI;
                                let newRows = [
                                    {
                                        id: generateUniqueId(),
                                        number: counter++,
                                        price: response.statusByFile[0].price,
                                        file_name: response.statusByFile[0].original_filename,
                                        file_name_s3: response.statusByFile[0].filename,
                                        status: response.statusByFile[0].status,
                                    },
                                ];

                                setRows(newRows);
                                setRowsModal(newRows);

                                if (response.repeatedConversions.length > 0) {
                                    setShowRepeatModal(true);
                                    setShowRepeatVal(response.repeatedConversions);
                                }

                                setS3FileName([newRows[0].file_name_s3]);
                            })
                            .catch((err) => {
                                console.log(err);

                                if (err.response.data.correlative) {
                                    let correlativeI = findFinalCorrelative(err.response.data.correlative, rows);

                                    let newRows;
                                    let counter = correlativeI;

                                    if (err.response.data.statusByFile.length > 0) {
                                        newRows = [
                                            {
                                                id: generateUniqueId(),
                                                number: counter++,
                                                price: err.response.data.statusByFile[0].price,
                                                file_name: err.response.data.statusByFile[0].original_filename,
                                                file_name_s3: err.response.data.statusByFile[0].filename,
                                                status: err.response.data.statusByFile[0].status,
                                            },
                                        ];
                                    } else {
                                        newRows = [
                                            {
                                                id: generateUniqueId(),
                                                number: counter++,
                                                price: 0,
                                                file_name: newAcceptedFiles[0].name,
                                                file_name_s3: "",
                                                status: err.response.status,
                                            },
                                        ];
                                    }
                                    setRows(newRows);
                                    setRowsModal(newRows);
                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                } else {
                                    let correlativeI = findFinalCorrelative(1, rows);

                                    let newRows = [
                                        {
                                            id: generateUniqueId(),
                                            number: correlativeI,
                                            price: 0,
                                            file_name: newAcceptedFiles[0].name,
                                            file_name_s3: "",
                                            status: err.response.status,
                                        },
                                    ];

                                    setRows(newRows);
                                    setRowsModal(newRows);

                                    setTexto(t("converter.errorUpload"));
                                    setAlertType("error");
                                    setShow(true);
                                }
                            });
                    }
                }
            }
            if (newAcceptedFiles.length === 0) {
                setTexto(t("converter.warningWrongFormat"));
                setAlertType("warning");
                setShow(true);
            }
            if (isHeavy) {
                setTexto(t("converter.warningMaxSize"));
                setAlertType("warning");
                setShow(true);

                const params = {
                    user_name: todos.userInfo.user_name,
                    franchise_name: todos.allComData.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    ).franchise_name,
                    company_name: todos.allComData.find(
                        (company) => !company.franchise_name && company._id === todos.companyID
                    ).company_name,
                };

                //Send email
                await conversionService.notifySupport(params).catch((err) => {
                    console.log(err);
                });
            }

            setProgress(0);
            setLoadingFile(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            accountDet,
            accountId,
            reload,
            s3FileName,
            setAcceptedFiles,
            setAlertType,
            setConvInfo,
            setProgress,
            setRows,
            setS3FileName,
            setShow,
            setShowRepeatModal,
            setShowRepeatVal,
            setStatus,
            setTexto,
            t,
            todos.allComData,
            todos.companyID,
            todos.franchiseID,
            todos.userInfo.user_name,
            type,
        ]
    );

    const handleClick = (e, type) => {
        e.stopPropagation();
        if (type) {
            navigate("/inbox");
        }
    };

    const deleteFile = async (e) => {
        e.stopPropagation();

        if (rows.length > 0) {
            if (!(convertDone || convertRev || convertInvalid)) {
                if (rows.length > 1) {
                    const params = {
                        uploaded_file: rows.filter((item) => item.file_name_s3 !== "").map((item) => item.file_name_s3),
                        type: type,
                    };

                    if (
                        type === "bankStatement" &&
                        "groupedConversionId" in convInfo &&
                        convInfo.groupedConversionId !== undefined
                    ) {
                        params.groupedConversionId = convInfo.groupedConversionId;
                    }

                    await conversionService
                        .deleteMulFileS3(params)
                        .then(() => {
                            setAcceptedFiles([]);
                            setS3FileName([]);
                            setStatus(0);
                            setRows([]);
                            setRowsModal([]);
                            if (type === "bankStatement") {
                                setConvInfo({});
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setTexto(t("converter.errorUpload"));
                            setAlertType("error");
                            setShow(true);
                        });
                } else {
                    const params = {
                        uploaded_file: rows[0].file_name_s3,
                        type: type,
                        company_id: todos.companyID,
                    };

                    if (
                        type === "bankStatement" &&
                        "groupedConversionId" in convInfo &&
                        convInfo.groupedConversionId !== undefined
                    ) {
                        params.groupedConversionId = convInfo.groupedConversionId;
                    }

                    if (type === "bankStatement" && "statusByFile" in convInfo) {
                        params.statusByFile = JSON.stringify(convInfo.statusByFile);
                    }

                    await conversionService
                        .deleteFileS3(params)
                        .then(() => {
                            setAcceptedFiles([]);
                            setS3FileName([]);
                            setStatus(0);
                            setRows([]);
                            setRowsModal([]);
                            if (type === "bankStatement") {
                                setConvInfo({});
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setTexto(t("converter.errorUpload"));
                            setAlertType("error");
                            setShow(true);
                        });
                }
            } else {
                setAcceptedFiles([]);
                setS3FileName([]);
                setStatus(0);
                setRows([]);
                setRowsModal([]);
                if (type === "bankStatement") {
                    setConvInfo({});
                }
            }
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        disabled: formats.length === 0 || disabled || progress > 0,
        accept: acceptFormat,
        onDrop,
    });

    const formatBytes = (bytes) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
    };

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    return (
        <Box
            {...getRootProps({
                className: formats.length === 0 || disabled || progress > 0 ? classes.dropzone : classes.dropzoneActive,
            })}
            sx={{
                backgroundImage: `url(${ConverterBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <input {...getInputProps()} />
            <Grid container spacing={2} justifyContent="center">
                <Grid
                    item
                    xs={12}
                    sm={11}
                    sx={{
                        bgcolor: "#E5E0F9",
                        borderRadius: "6px",
                        boxShadow: "3px 3px 5px 0px rgba(74, 34, 212, 0.14)",
                        padding: "6px 10px 12px 30px !important",
                        mt: 2,
                    }}
                >
                    <Stack direction="row" spacing={0} mt={1} alignItems="center" justifyContent="space-between">
                        <Box>
                            <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={window.innerWidth > 1360 ? 15 : 12}
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        formats.length === 0 ? "converter.dragDropDisabled" : "converter.dragDrop"
                                    ),
                                }}
                            ></Typography>
                            <Stack direction="row" spacing={1} mt={2} alignItems="center">
                                <Typography variant="h2" fontWeight={500} fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                    {type === "invoice" || type === "consolidated"
                                        ? t("converter.formatInv")
                                        : t("converter.formatAcc")}
                                    :
                                </Typography>
                                {formats.map((item, index) => (
                                    <Box
                                        component="span"
                                        className={classes.boxFormat}
                                        key={"format_" + index}
                                        sx={{
                                            width:
                                                window.innerWidth > 1360
                                                    ? item.name === "xlsx" || item.name === "png"
                                                        ? "31px"
                                                        : item.name === "pdf"
                                                        ? "30px"
                                                        : "27px"
                                                    : item.name === "xlsx" || item.name === "png"
                                                    ? "28px"
                                                    : item.name === "pdf"
                                                    ? "27px"
                                                    : "24px",
                                        }}
                                    >
                                        <Typography
                                            fontWeight={600}
                                            fontSize={window.innerWidth > 1360 ? "11px !important" : "9px !important"}
                                        >
                                            .{item.name}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                        <UploadFileRoundedIcon
                            fontSize={window.innerWidth > 1360 ? "large" : "medium"}
                            sx={{ color: formats.length === 0 ? "disabled" : "#4A22D4", mr: 1.5 }}
                        />
                    </Stack>
                </Grid>
                {((acceptedFiles.length > 0 && progress !== 0 && loadingFile) || isLoadingConv) && (
                    <Grid item xs={12} sm={11} sx={{ pt: "20px !important" }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="h2" fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                {t("converter.uploading")}
                            </Typography>
                            <CachedIcon color="primary" fontSize="small" />
                        </Stack>
                    </Grid>
                )}
                {((acceptedFiles.length > 0 && progress === 0 && !loadingFile && !isModal) || isLoadingConv) && (
                    <Grid item xs={12} sm={11} sx={{ paddingTop: isLoadingConv ? "0 !important" : "16px !important" }}>
                        {!isLoadingConv && (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="h4" fontSize={window.innerWidth > 1360 ? 13 : 11} gutterBottom>
                                    {t("converter.fileLoaded")}
                                </Typography>
                                <CheckIcon sx={{ color: "#0BEF57" }} fontSize="small" />
                            </Stack>
                        )}
                        {acceptedFiles.length === 1 && (
                            <Stack direction="row" spacing={0.7} alignItems="center">
                                <Typography color="primary" variant="h4" fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                    {acceptedFiles[0].path} ({formatBytes(acceptedFiles[0].size)})
                                </Typography>
                                <LightTooltip title={t("converter.previewFile")}>
                                    <IconButton size="small" color="primary" onClick={previewDoc}>
                                        <PreviewIcon fontSize="small" />
                                    </IconButton>
                                </LightTooltip>
                                <LightTooltip title={t("miscellaneous.clear")}>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        disabled={isLoadingConv}
                                        onClick={(e) => !isLoadingConv && deleteFile(e)}
                                        sx={{
                                            color: isLoadingConv ? "grey" : "#4A22D4",
                                            transition: "all .2s ease-in",
                                            "&:hover": {
                                                color: isLoadingConv ? "grey" : "red",
                                            },
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </LightTooltip>
                            </Stack>
                        )}
                        {acceptedFiles.length > 1 && (
                            <Stack direction="row" spacing={0.7} alignItems="center">
                                <Typography color="primary" variant="h4" fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                    {acceptedFiles.length}
                                    {t("converter.uploadedFiles")}
                                </Typography>
                                <LightTooltip title={t("miscellaneous.clear")} position="right">
                                    <DeleteIcon
                                        sx={{
                                            width: "18px",
                                            color: isLoadingConv ? "grey" : "#4A22D4",
                                            transition: "all .2s ease-in",
                                            "&:hover": {
                                                color: isLoadingConv ? "grey" : "red",
                                            },
                                            cursor: "pointer",
                                        }}
                                        disabled={isLoadingConv}
                                        onClick={(e) => !isLoadingConv && deleteFile(e)} // Evita el click si está deshabilitado
                                    />
                                </LightTooltip>
                            </Stack>
                        )}
                    </Grid>
                )}
                {(acceptedFiles.length === 0 || isModal) && (
                    <>
                        <Grid
                            item
                            xs={12}
                            sm={11}
                            sx={{
                                paddingTop: acceptedFiles.length === 0 || isModal ? "16px !important" : "0 !important",
                            }}
                        >
                            <Typography fontSize={window.innerWidth > 1360 ? 13 : 11} fontWeight={500}>
                                {type === "invoice" || type === "consolidated"
                                    ? conversionType === 1
                                        ? t("converter.needHelpInvoices")
                                        : t("converter.needHelpBills")
                                    : t("converter.needHelp")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={11} sx={{ paddingTop: "0 !important" }}>
                            <Typography fontWeight={500} fontSize={window.innerWidth > 1360 ? 13 : 11}>
                                {t("converter.instrucText")}{" "}
                                <Link
                                    {...getRootProps({ onClick: (e) => handleClick(e, 0) })}
                                    sx={{
                                        fontWeight: 600,
                                        //textDecoration: "none",
                                        color: formats.length === 0 ? "#bcbcbc" : "#4A22D4",
                                    }}
                                    href={
                                        language === "es"
                                            ? "/docs/Instructivo EC - ESP.pdf"
                                            : "/docs/Instructive EC - ENG.pdf"
                                    }
                                    target="_blank"
                                    download
                                >
                                    {t("converter.instrucText1")}
                                </Link>{" "}
                                {t("converter.instrucText2")}{" "}
                                <Link
                                    {...getRootProps({ onClick: (e) => handleClick(e, 1) })}
                                    sx={{
                                        fontWeight: 600,
                                        color: formats.length === 0 ? "#bcbcbc" : "#4A22D4",
                                    }}
                                >
                                    {t("converter.instrucText3")}
                                </Link>
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default UploadFile;
